import React from "react";

import padina from "../assets/img/maskomDisalicom/padina.jpg";
import padina_1 from "../assets/img/maskomDisalicom/padina_1.jpg";
import spužva_promjenjiva_sumporaca from "../assets/img/maskomDisalicom/promjenjiva_sumporaca.jpg";
import spužva_promjenjiva_sumporaca_1 from "../assets/img/maskomDisalicom/promjenjiva_sumporaca_1.jpg";
import spužva_promjenjiva_sumporaca_2 from "../assets/img/maskomDisalicom/promjenjiva_sumporaca_2.jpg";
import hridinski_jezinac from "../assets/img/maskomDisalicom/hridinski_ježinac.jpg";
import hridinski_jezinac_1 from "../assets/img/maskomDisalicom/hridinski_ježinac_1.jpg";
import crni_jezinac from "../assets/img/maskomDisalicom/crni_jezinac.jpg";
import crni_jezinac_1 from "../assets/img/maskomDisalicom/crni_jezinac_1.jpg";
import ljubicasti_jezinac from "../assets/img/maskomDisalicom/ljubicasti_jezinac.jpg";
import ljubicasti_jezinac_1 from "../assets/img/maskomDisalicom/ljubicasti_jezinac_1.jpg";
import crvena_zvjezdaca from "../assets/img/maskomDisalicom/crvena_zvjezdaca.jpg";
import crvena_zvjezdaca_2 from "../assets/img/maskomDisalicom/crvena_zvjezdaca_1.jpg";
import puz_tilodina from "../assets/img/maskomDisalicom/puz_tilodina.jpg";

import acetabularia from "../assets/img/maskomDisalicom/acetabularia.jpg";
import posidonija from "../assets/img/maskomDisalicom/posidonija.jpg";
import posidonija_1 from "../assets/img/maskomDisalicom/posidonija_1.jpg";
import posidonija_2 from "../assets/img/maskomDisalicom/posidonija_2.jpg";
import cymodocea from "../assets/img/maskomDisalicom/cymodocea.jpg";
import cymodocea_1 from "../assets/img/maskomDisalicom/cymodocea_1.jpg";
import cymodocea_2 from "../assets/img/maskomDisalicom/cymodocea_2.jpg";
import cymodocea_3 from "../assets/img/maskomDisalicom/cymodocea_3.jpg";
import trp from "../assets/img/maskomDisalicom/trp.jpg";
import trp_1 from "../assets/img/maskomDisalicom/trlja_1.jpg";
import vlasulja from "../assets/img/maskomDisalicom/vlasulja.jpg";
import vlasulja_1 from "../assets/img/maskomDisalicom/vlasulja_1.jpg";
import crvenaMoruzgva from "../assets/img/maskomDisalicom/crvena_moruzgva.jpg";
import crvenaMoruzgva_1 from "../assets/img/maskomDisalicom/crvena_moruzgva_1.jpg";
import cladocora from "../assets/img/maskomDisalicom/cladocora.jpg";
import cladocora_1 from "../assets/img/maskomDisalicom/cladocora_1.jpg";
import cladocora_2 from "../assets/img/maskomDisalicom/cladocora_2.jpg";
import nanar from "../assets/img/maskomDisalicom/nanar.jpg";
import volak from "../assets/img/maskomDisalicom/volak.jpg";
import volak_1 from "../assets/img/maskomDisalicom/volak_1.jpg";
import volak_2 from "../assets/img/maskomDisalicom/volak_2.jpg";
import kokon from "../assets/img/maskomDisalicom/kokon.jpg";
import petrovo_uho from "../assets/img/maskomDisalicom/petrovo_uho.jpg";
import petrovo_uho_1 from "../assets/img/maskomDisalicom/petrovo_uho_1.jpg";
import petrovo_uho_2 from "../assets/img/maskomDisalicom/petrovo_uho_2.jpg";
import petrovo_uho_3 from "../assets/img/maskomDisalicom/petrovo_uho_3.jpg";
import brbavica from "../assets/img/maskomDisalicom/brbavica.jpg";
import kozica from "../assets/img/maskomDisalicom/kozica.jpg";
import kozica_1 from "../assets/img/maskomDisalicom/kozica_1.jpg";
import kozica_2 from "../assets/img/maskomDisalicom/kozica_2.jpg";
import kosmej from "../assets/img/maskomDisalicom/kosmej.jpg";
import kosmej_1 from "../assets/img/maskomDisalicom/kosmej_1.jpg";
import kosmej_2 from "../assets/img/maskomDisalicom/kosmej_2.jpg";
import rak_samac from "../assets/img/maskomDisalicom/rak_samac.jpg";
import crnej_odrasli from "../assets/img/maskomDisalicom/crnej_odrasli.jpg";
import crnej_odrasli_1 from "../assets/img/maskomDisalicom/crnej_odrasli_1.jpg";
import crnej_mladi from "../assets/img/maskomDisalicom/crnej_mladi.jpg";
import crnej_mladi_1 from "../assets/img/maskomDisalicom/crnej_mladi_1.jpg";
import fratar from "../assets/img/maskomDisalicom/fratar.jpg";
import fratar_1 from "../assets/img/maskomDisalicom/fratar_1.jpg";
import fratar_2 from "../assets/img/maskomDisalicom/fratar_2.jpg";
import fratar_3 from "../assets/img/maskomDisalicom/fratar_3.jpg";
import fratar_4 from "../assets/img/maskomDisalicom/fratar_4.jpg";
import pirak from "../assets/img/maskomDisalicom/pirak.jpg";
import pirak_1 from "../assets/img/maskomDisalicom/pirak_1.jpg";
import pirak_2 from "../assets/img/maskomDisalicom/pirak_2.jpg";
import pirak_3 from "../assets/img/maskomDisalicom/pirak_3.jpg";
import knez_muzjak from "../assets/img/maskomDisalicom/knez_muzjak.jpg";
import knez_muzjak_1 from "../assets/img/maskomDisalicom/knez_muzjak_1.jpg";
import knez_muzjak_2 from "../assets/img/maskomDisalicom/knez_muzjak_2.jpg";
import knez_zenka from "../assets/img/maskomDisalicom/knez_zenka.jpg";
import knez_zenka_1 from "../assets/img/maskomDisalicom/knez_zenka_1.jpg";
import usata from "../assets/img/maskomDisalicom/usata.jpg";
import usata_1 from "../assets/img/maskomDisalicom/usata_1.jpg";
import pjevcici from "../assets/img/maskomDisalicom/pjevcici.jpg";
import pjevcici_1 from "../assets/img/maskomDisalicom/pjevcici_1.jpg";
import pjevcici_2 from "../assets/img/maskomDisalicom/pjevcici_2.jpg";
import pjevcici_3 from "../assets/img/maskomDisalicom/pjevcici_3.jpg";
import pjevcici_4 from "../assets/img/maskomDisalicom/pjevcici_4.jpg";
import pjevcici_5 from "../assets/img/maskomDisalicom/pjevcici_5.jpg";
import salpa from "../assets/img/maskomDisalicom/salpa.jpg";
import salpa_1 from "../assets/img/maskomDisalicom/salpa_1.jpg";
import martinka from "../assets/img/maskomDisalicom/martinka.jpg";
import martinka_1 from "../assets/img/maskomDisalicom/martinka_1.jpg";
import komarca from "../assets/img/maskomDisalicom/komarca.jpg";
import komarca_1 from "../assets/img/maskomDisalicom/komarca_1.jpg";
import trlja from "../assets/img/maskomDisalicom/trlja.jpg";
import trlja_1 from "../assets/img/maskomDisalicom/trlja_1.jpg";
import cipal from "../assets/img/maskomDisalicom/cipal.jpg";
import cipal_1 from "../assets/img/maskomDisalicom/cipal_1.jpg";
import slingurice from "../assets/img/maskomDisalicom/slingurice.jpg";
import slingurice_1 from "../assets/img/maskomDisalicom/slingurice_1.jpg";
import slingurice_2 from "../assets/img/maskomDisalicom/slingurice_2.jpg";
import slingurice_3 from "../assets/img/maskomDisalicom/slingurice_3.jpg";
import slingurice_4 from "../assets/img/maskomDisalicom/slingurice_4.jpg";
import slingurice_5 from "../assets/img/maskomDisalicom/slingurice_5.jpg";

import { Translation } from "react-i18next";

/* PADINA */
const objPadina = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Smeđa alga padina")}</span>}</Translation> (<i>Padina pavonica</i>)
        </>
    ),
    path: padina,
};
const objPadina_1 = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Smeđa alga padina")}</span>}</Translation> (<i>Padina pavonica</i>)
        </>
    ),
    path: padina_1,
};
export const mid_padina = [objPadina, objPadina_1];

/* KLOBUCIC */
const objKlobucic = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Zelena alga klobučić")}</span>}</Translation> (<i>Acetabularia acetabulum</i>
            )
        </>
    ),
    path: acetabularia,
};
export const mid_klobucic = [objKlobucic];

/* POSIDONIJA */
const objPosidonija = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Morska cvjetnica posidonija")}</span>}</Translation> (
            <i>Posidonia oceanica</i>)
        </>
    ),
    path: posidonija,
};
const objPosidonija_1 = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Morska cvjetnica posidonija")}</span>}</Translation> (
            <i>Posidonia oceanica</i>)
        </>
    ),
    path: posidonija_1,
};
const objPosidonija_2 = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Morska cvjetnica posidonija")}</span>}</Translation> (
            <i>Posidonia oceanica</i>)
        </>
    ),
    path: posidonija_2,
};
export const mid_posidonija = [objPosidonija, objPosidonija_1, objPosidonija_2];

/* CVORASTA MORSKA RESA */
const objCvorastaMorskaResa = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Morska cvjetnica čvorasta morska resa")}</span>}</Translation> (
            <i>Cymodocea nodosa</i>)
        </>
    ),
    path: cymodocea,
};
const objCvorastaMorskaResa_1 = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Morska cvjetnica čvorasta morska resa")}</span>}</Translation> (
            <i>Cymodocea nodosa</i>)
        </>
    ),
    path: cymodocea_1,
};
const objCvorastaMorskaResa_2 = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Morska cvjetnica čvorasta morska resa")}</span>}</Translation> (
            <i>Cymodocea nodosa</i>)
        </>
    ),
    path: cymodocea_2,
};
const objCvorastaMorskaResa_3 = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Morska cvjetnica čvorasta morska resa")}</span>}</Translation> (
            <i>Cymodocea nodosa</i>)
        </>
    ),
    path: cymodocea_3,
};
export const mid_cvorastaMorskaResa = [
    objCvorastaMorskaResa,
    objCvorastaMorskaResa_1,
    objCvorastaMorskaResa_2,
    objCvorastaMorskaResa_3,
];

/* PROMJENJIVA USMPORACA */
const objSumporaca = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Spužva promjenjiva sumporača")}</span>}</Translation> (
            <i>Aplysina aerophoba</i>)
        </>
    ),
    path: spužva_promjenjiva_sumporaca,
};
const objSumporaca_1 = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Spužva promjenjiva sumporača")}</span>}</Translation> (
            <i>Aplysina aerophoba</i>)
        </>
    ),
    path: spužva_promjenjiva_sumporaca_1,
};
const objSumporaca_2 = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Spužva promjenjiva sumporača")}</span>}</Translation> (
            <i>Aplysina aerophoba</i>)
        </>
    ),
    path: spužva_promjenjiva_sumporaca_2,
};
const objPuzTilodina = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Puž tilodina")}</span>}</Translation> (<i>Aplysina aerophoba</i>)
        </>
    ),
    path: puz_tilodina,
};
export const mid_promjenjivaSumporaca = [objSumporaca, objSumporaca_1, objSumporaca_2, objPuzTilodina];

/* HRIDINSKI JEZINAC */
const objHridinskiJezinac = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Hridinski ježinac")}</span>}</Translation> (<i>Paracentrotus lividus</i>)
        </>
    ),
    path: hridinski_jezinac,
};
const objHridinskiJezinac_1 = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Hridinski ježinac")}</span>}</Translation> (<i>Paracentrotus lividus</i>)
        </>
    ),
    path: hridinski_jezinac_1,
};
export const mid_hridinskiJezinac = [objHridinskiJezinac, objHridinskiJezinac_1];

/* CRNI JEZINAC */
const objCrniJezinac = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Crni ježinac")}</span>}</Translation> (<i>Arbacia lixula</i>)
        </>
    ),
    path: crni_jezinac,
};
const objCrniJezinac_1 = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Crni ježinac")}</span>}</Translation> (<i>Arbacia lixula</i>)
        </>
    ),
    path: crni_jezinac_1,
};
export const mid_crniJezinac = [objCrniJezinac, objCrniJezinac_1];

/* LJUBICASTI JEZINAC */
const objLjubicastiJezinac = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Ljubičasti ježinac")}</span>}</Translation> (<i>Sphaerechinus granularis</i>)
        </>
    ),
    path: ljubicasti_jezinac,
};
const objLjubicastiJezinac_1 = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Ljubičasti ježinac")}</span>}</Translation> (<i>Sphaerechinus granularis</i>)
        </>
    ),
    path: ljubicasti_jezinac_1,
};
export const mid_ljubicastiJezinac = [objLjubicastiJezinac, objLjubicastiJezinac_1];

/* CRVENA ZVJEZDACA */
const objCrvenaZvjezdaca = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Crvena zvjezdača")}</span>}</Translation> (<i>Echinaster sepositus</i>)
        </>
    ),
    path: crvena_zvjezdaca,
};
const objCrvenaZvjezdaca_1 = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Crvena zvjezdača")}</span>}</Translation> (<i>Echinaster sepositus</i>)
        </>
    ),
    path: crvena_zvjezdaca_2,
};
export const mid_crvenaZvjezdaca = [objCrvenaZvjezdaca, objCrvenaZvjezdaca_1];

/* TRP */
const objTrp = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Trp")}</span>}</Translation> (<i>Hothuria tubulosa</i>)
        </>
    ),
    path: trp,
};
const objTrp_1 = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Trp")}</span>}</Translation> (<i>Holothuria sanctori</i>)
        </>
    ),
    path: trp_1,
};
export const mid_trpovi = [objTrp, objTrp_1];

/* VLASULJA */
const objVlasulja = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Vlasulja")}</span>}</Translation> (<i>Anemonia viridis</i>)
        </>
    ),
    path: vlasulja,
};
const objVlasulja_1 = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Vlasulja")}</span>}</Translation> (<i>Anemonia viridis</i>)
        </>
    ),
    path: vlasulja_1,
};
export const mid_vlasulja = [objVlasulja, objVlasulja_1];

/* CRVENA MORUZGVA */
const objCrvenaMoruzgva = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Crvena moruzgva")}</span>}</Translation> (<i>Actinia equina</i>)
        </>
    ),
    path: crvenaMoruzgva,
};
const objCrvenaMoruzgva_1 = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Crvena moruzgva")}</span>}</Translation> (<i>Actinia equina</i>)
        </>
    ),
    path: crvenaMoruzgva_1,
};
export const mid_crvenaMoruzgva = [objCrvenaMoruzgva, objCrvenaMoruzgva_1];

/* BUSENASTI KORALJ */
const objBusenastiKoralj = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Busenasti koralj")}</span>}</Translation> (<i>Cladocora caespitosa</i>)
        </>
    ),
    path: cladocora,
};
const objBusenastiKoralj_1 = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Busenasti koralj")}</span>}</Translation> (<i>Cladocora caespitosa</i>)
        </>
    ),
    path: cladocora_1,
};
const objBusenastiKoralj_2 = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Busenasti koralj")}</span>}</Translation> (<i>Cladocora caespitosa</i>)
        </>
    ),
    path: cladocora_2,
};
export const mid_busenastiKoralj = [objBusenastiKoralj, objBusenastiKoralj_1, objBusenastiKoralj_2];

/* NANAR */
const objNanar = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Nanar")}</span>}</Translation> (<i>Phorcus turbinatus</i>)
        </>
    ),
    path: nanar,
};
export const mid_nanar = [objNanar];

/* VOLAK */
const objVolak = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Volak")}</span>}</Translation> (<i>Hexaplex trunculus</i>)
        </>
    ),
    path: volak,
};
const objVolak_1 = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Volak")}</span>}</Translation> (<i>Hexaplex trunculus</i>)
        </>
    ),
    path: volak_1,
};
const objVolak_2 = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Volak")}</span>}</Translation> (<i>Hexaplex trunculus</i>)
        </>
    ),
    path: volak_2,
};
const objKokon = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Jaja volka")}</span>}</Translation> (<i>Hexaplex trunculus</i>)
        </>
    ),
    path: kokon,
};
export const mid_volak = [objVolak, objVolak_1, objVolak_2, objKokon];

/* PETROVO UHO */
const objPetrovoUho = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Petrovo uho")}</span>}</Translation> (<i>Haliotis tuberculata</i>)
        </>
    ),
    path: petrovo_uho,
};
const objPetrovoUho_1 = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Petrovo uho")}</span>}</Translation> (<i>Haliotis tuberculata</i>)
        </>
    ),
    path: petrovo_uho_1,
};
const objPetrovoUho_2 = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Petrovo uho")}</span>}</Translation> (<i>Haliotis tuberculata</i>)
        </>
    ),
    path: petrovo_uho_2,
};
const objPetrovoUho_3 = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Petrovo uho")}</span>}</Translation> (<i>Haliotis tuberculata</i>)
        </>
    ),
    path: petrovo_uho_3,
};
export const mid_petrovoUho = [objPetrovoUho, objPetrovoUho_1, objPetrovoUho_2, objPetrovoUho_3];

/* BRBAVICA */
const objBrbavica = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Brbavica")}</span>}</Translation> (<i>Venus verrucosa</i>)
        </>
    ),
    path: brbavica,
};
export const mid_brbavica = [objBrbavica];

/* KOZICA */
const objKozica = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Kozica")}</span>}</Translation> (<i>Palaemon serratus</i>)
        </>
    ),
    path: kozica,
};
const objKozica_1 = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Kozica")}</span>}</Translation> (<i>Palaemon serratus</i>)
        </>
    ),
    path: kozica_1,
};
const objKozica_2 = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Kozica")}</span>}</Translation> (<i>Palaemon serratus</i>)
        </>
    ),
    path: kozica_2,
};
export const mid_kozica = [objKozica, objKozica_1, objKozica_2];

/* KOSMEJ */
const objKosmej = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Kosmej")}</span>}</Translation> (<i>Eriphia verrucosa</i>)
        </>
    ),
    path: kosmej,
};
const objKosmej_1 = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Kosmej")}</span>}</Translation> (<i>Eriphia verrucosa</i>)
        </>
    ),
    path: kosmej_1,
};
const objKosmej_2 = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Kosmej")}</span>}</Translation> (<i>Eriphia verrucosa</i>)
        </>
    ),
    path: kosmej_2,
};
export const mid_kosmej = [objKosmej, objKosmej_1, objKosmej_2];

/* RAK SAMAC */
const objRakSamanc = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Rak samanc")}</span>}</Translation> (<i>Pagurus anachoretus</i>)
        </>
    ),
    path: rak_samac,
};
export const mid_rakSamac = [objRakSamanc];

/* CRNEJ */
const objCrnejOdrasli = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Odrasli crnej")}</span>}</Translation> (<i>Chromis chromis</i>)
        </>
    ),
    path: crnej_odrasli,
};
const objCrnejOdrasli_1 = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Odrasli crnej")}</span>}</Translation> (<i>Chromis chromis</i>)
        </>
    ),
    path: crnej_odrasli_1,
};
const objCrnejMladi = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Mladi crneji")}</span>}</Translation> (<i>Chromis chromis</i>)
        </>
    ),
    path: crnej_mladi,
};
const objCrnejMladi_1 = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Mladi crneji")}</span>}</Translation> (<i>Chromis chromis</i>)
        </>
    ),
    path: crnej_mladi_1,
};
export const mid_crnej = [objCrnejOdrasli, objCrnejOdrasli_1, objCrnejMladi, objCrnejMladi_1];

/* FRATAR */
const objFratar = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Fratar")}</span>}</Translation> (<i>Diplodus vulgaris</i>)
        </>
    ),
    path: fratar,
};
const objFratar_1 = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Fratar")}</span>}</Translation> (<i>Diplodus vulgaris</i>)
        </>
    ),
    path: fratar_1,
};
const objFratar_2 = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Fratar")}</span>}</Translation> (<i>Diplodus vulgaris</i>)
        </>
    ),
    path: fratar_2,
};
const objFratar_3 = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Fratar")}</span>}</Translation> (<i>Diplodus vulgaris</i>)
        </>
    ),
    path: fratar_3,
};
const objFratar_4 = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Fratar")}</span>}</Translation> (<i>Diplodus vulgaris</i>)
        </>
    ),
    path: fratar_4,
};
export const mid_fratar = [objFratar, objFratar_1, objFratar_2, objFratar_3, objFratar_4];

/* PIRKA */
const objPirka = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Pirka")}</span>}</Translation> (<i>Serranus scriba</i>)
        </>
    ),
    path: pirak,
};
const objPirka_1 = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Pirka")}</span>}</Translation> (<i>Serranus scriba</i>)
        </>
    ),
    path: pirak_1,
};
const objPirka_2 = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Pirka")}</span>}</Translation> (<i>Serranus scriba</i>)
        </>
    ),
    path: pirak_2,
};
const objPirka_3 = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Pirka")}</span>}</Translation> (<i>Serranus scriba</i>)
        </>
    ),
    path: pirak_3,
};
export const mid_pirka = [objPirka, objPirka_1, objPirka_2, objPirka_3];

/* knez */
const objKnezMuzjak = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Knez mužjak")}</span>}</Translation> (<i>Coris julis</i>)
        </>
    ),
    path: knez_muzjak,
};
const objKnezMuzjak_1 = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Knez mužjak")}</span>}</Translation> (<i>Coris julis</i>)
        </>
    ),
    path: knez_muzjak_1,
};
const objKnezMuzjak_2 = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Knez mužjak")}</span>}</Translation> (<i>Coris julis</i>)
        </>
    ),
    path: knez_muzjak_2,
};
const objKnezZenka = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Knez ženka")}</span>}</Translation> (<i>Coris julis</i>)
        </>
    ),
    path: knez_zenka,
};
const objKnezZenka_1 = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Knez ženka")}</span>}</Translation> (<i>Coris julis</i>)
        </>
    ),
    path: knez_zenka_1,
};
export const mid_knez = [objKnezMuzjak, objKnezMuzjak_1, objKnezMuzjak_2, objKnezZenka, objKnezZenka_1];

/* usata */
const objUsata = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Ušata")}</span>}</Translation> (<i>Oblada melanura</i>)
        </>
    ),
    path: usata,
};
const objUsata_1 = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Ušata")}</span>}</Translation> (<i>Oblada melanura</i>)
        </>
    ),
    path: usata_1,
};
export const mid_usata = [objUsata, objUsata_1];

/* pjevcic */
const objPjevcic = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Pjevčić")}</span>}</Translation>
        </>
    ),
    path: pjevcici,
};
const objPjevcic_1 = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Pjevčić")}</span>}</Translation>
        </>
    ),
    path: pjevcici_1,
};
const objPjevcic_2 = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Pjevčić")}</span>}</Translation>
        </>
    ),
    path: pjevcici_2,
};
const objPjevcic_3 = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Pjevčić")}</span>}</Translation>
        </>
    ),
    path: pjevcici_3,
};
const objPjevcic_4 = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Pjevčić")}</span>}</Translation>
        </>
    ),
    path: pjevcici_4,
};
const objPjevcic_5 = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Pjevčić")}</span>}</Translation>
        </>
    ),
    path: pjevcici_5,
};
export const mid_pjevcic = [objPjevcic, objPjevcic_1, objPjevcic_2, objPjevcic_3, objPjevcic_4, objPjevcic_5];

/* salpa */
const objSalpa = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Salpa")}</span>}</Translation> (<i>Sarpa salpa</i>)
        </>
    ),
    path: salpa,
};
const objSalpa_1 = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Salpa")}</span>}</Translation> (<i>Sarpa salpa</i>)
        </>
    ),
    path: salpa_1,
};
export const mid_salpa = [objSalpa, objSalpa_1];

/* martinka */
const objMartinka = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Martinka")}</span>}</Translation> (<i>Symphodus ocellatus</i>)
        </>
    ),
    path: martinka,
};
const objMartinka_1 = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Martinka")}</span>}</Translation> (<i>Symphodus ocellatus</i>)
        </>
    ),
    path: martinka_1,
};
export const mid_martinka = [objMartinka, objMartinka_1];

/* komarca */
const objKomarca = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Komarča")}</span>}</Translation> (<i>Sparus aurata</i>)
        </>
    ),
    path: komarca,
};
const objKomarca_1 = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Komarča")}</span>}</Translation> (<i>Sparus aurata</i>)
        </>
    ),
    path: komarca_1,
};
export const mid_komarca = [objKomarca, objKomarca_1];

/* trlja */
const objTrlja = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Trlja")}</span>}</Translation>
        </>
    ),
    path: trlja,
};
const objTrlja_1 = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Trlja")}</span>}</Translation>
        </>
    ),
    path: trlja_1,
};
export const mid_trlja = [objTrlja, objTrlja_1];

/* cipli */
const objCipli = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Cipal")}</span>}</Translation> (<i>Chelon</i> sp.)
        </>
    ),
    path: cipal,
};
const objCipli_2 = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Cipal")}</span>}</Translation> (<i>Chelon</i> sp.)
        </>
    ),
    path: cipal_1,
};
export const mid_cipli = [objCipli, objCipli_2];

/* slingurice */
const objSlingurice = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Slingurice")}</span>}</Translation>
        </>
    ),
    path: slingurice,
};
const objSlingurice_1 = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Slingurice")}</span>}</Translation>
        </>
    ),
    path: slingurice_1,
};
const objSlingurice_2 = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Slingurice")}</span>}</Translation>
        </>
    ),
    path: slingurice_2,
};
const objSlingurice_3 = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Slingurice")}</span>}</Translation>
        </>
    ),
    path: slingurice_3,
};
const objSlingurice_4 = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Slingurice")}</span>}</Translation>
        </>
    ),
    path: slingurice_4,
};
const objSlingurice_5 = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Slingurice")}</span>}</Translation>
        </>
    ),
    path: slingurice_5,
};
export const mid_slingurice = [
    objSlingurice,
    objSlingurice_1,
    objSlingurice_2,
    objSlingurice_3,
    objSlingurice_4,
    objSlingurice_5,
];
