import React from "react";

import {
    mid_brbavica,
    mid_busenastiKoralj,
    mid_cipli,
    mid_crnej,
    mid_crniJezinac,
    mid_crvenaMoruzgva,
    mid_crvenaZvjezdaca,
    mid_cvorastaMorskaResa,
    mid_fratar,
    mid_hridinskiJezinac,
    mid_klobucic,
    mid_knez,
    mid_komarca,
    mid_kosmej,
    mid_kozica,
    mid_ljubicastiJezinac,
    mid_martinka,
    mid_nanar,
    mid_padina,
    mid_petrovoUho,
    mid_pirka,
    mid_pjevcic,
    mid_posidonija,
    mid_promjenjivaSumporaca,
    mid_rakSamac,
    mid_salpa,
    mid_slingurice,
    mid_trlja,
    mid_trpovi,
    mid_usata,
    mid_vlasulja,
    mid_volak,
} from "./maskomDisalicomImages";

const MaskomDisalicomContentHR = {
    padina: {
        headingTitle: (
            <p>
                Padina (<i>Padina pavonica</i>)
            </p>
        ),
        headingDescription: (
            <p>
                Padina jedna je od najčešćih algi plitkog dna. Smeđa je alga ali bijele do svjetlo smeđe boje. Oblika je
                lijevka s tamnosmeđim prugama u kojima su reproduktivni organi. Bijela boja dolazi od kalcijevog
                karbonata kojeg alga ugrađuje u tijelo. On joj pomaže u obrani od herbivornih organizama.
            </p>
        ),
        video: null,
        images: mid_padina,
    },
    klobucic: {
        headingTitle: (
            <p>
                Klobučić (<i>Acetabularia acetabulum</i>)
            </p>
        ),
        headingDescription: (
            <p>
                Klobučić je zelena alga visoka oko 5 cm, građena od tankog drška na čijem vrhu je kišobranu nalik
                proširenje. Cijela alga građena je od jedne stanice s jednom velikom jezgrom smještenom u bazalnom
                dijelu drška, a često je korištena kao modelni organizma u molekularnim istraživanjima. Ponekad u
                potpunosti prekriva plitka stjenovita dna.
            </p>
        ),
        video: null,
        images: mid_klobucic,
    },
    posidonija: {
        headingTitle: (
            <p>
                Posidonija (<i>Posidonia oceanica</i>)
            </p>
        ),
        headingDescription: (
            <p>
                Posidonija je najvažnija morska cvjetnica Sredozemnog mora. Ujedno je i njen endemični organizam.
                Građena je od stabalca, korijenja i tamno zelenih listova. Stabalce raste vrlo sporo, često samo 1 cm
                godišnje. Zbog toga je svako uništavanje njenih naselja gotovo ireverzibilan proces za trajanja
                ljudskoga vijeka. Posidonija je i najdugovječniji organizam plitkoga dna. Pojedine bilje mogu biti stare
                stotine godina, a cijelo naselje možda je poteklo od samo jedne biljke koja se tu naselila prije tisuće
                godina. Morske cvjetnice su trave, pa se kao i trave na kopnu razmnožavaju cvjetanjem. Posidonija cvate
                u jesen, u nepravilnim razmacima. Oprašivanje se odvija prijenosom peluda morskom vodom nakon čega se
                razvija plod nalik maslini. Plod sazrijeva u proljeće kada se mogu vidjeti kako plutaju površinom.
            </p>
        ),
        video: null,
        images: mid_posidonija,
    },
    cvorastaMorskaResa: {
        headingTitle: (
            <p>
                Čvorasta morska resa (<i>Cymodocea nodosa</i>)
            </p>
        ),
        headingDescription: (
            <p>
                Čvorasta morska resa je mala morska cvjetnica, nježnih zelenih listova dugačkih oko 15 cm koje se
                razvija iz puzajućeg stabalca. Naseljava pjeskovita plitka dna, obično u zaklonjenim uvalama, od same
                površine do desetak metara dubine. Cvate u proljeće kada razvija neugledne cvjetove posebno na muškim i
                posebno na ženskim biljkama. Vrsta je zakonom zaštićena kao i sve morske cvjetnice. Na Rtu Kabal je
                nalazimo u dnu uvala unutar Starogradskog zaljeva.
            </p>
        ),
        video: null,
        images: mid_cvorastaMorskaResa,
    },
    promjenjivaSumporaca: {
        headingTitle: (
            <p>
                Promjenjiva sumporača (<i>Aplysina aerophoba</i>)
            </p>
        ),
        headingDescription: (
            <p>
                Spužva promjenjiva sumporača, česta je vrsta plitkog stjenovitog dna. Izražene je žute boje, nalik
                prstima s rupicom na vrhu. A naziv &quot;promjenjiva sumporača&quot; dobila je zbog promjene boje u
                tamno-crnu kada se izvadi iz mora. Spužva se hrani filtriranjem morske vode iz koje izvlači planktonske
                organizme i organske čestice. Rupica na vrhu, tzv. oskulum, mjesto na kojem profiltrirana morska voda
                izlazi iz tijela spužve. Živi na svijetlim mjestima jer u svom tijelu udomljuje cijanobakterije, koje
                trebaju svjetlo za fotosintezu. Na njoj se često pronalazi puž Tylodina perversa. koji se isključivo
                hrani ovom spužvom. Od spužvinih kemijskih spojeva dobiv boju ali i toksičnost, zbog čega ga nitko ne
                jede.
            </p>
        ),
        video: "media/sumporaca-video.mp4",
        images: mid_promjenjivaSumporaca,
    },
    hridinskiJezinac: {
        headingTitle: (
            <p>
                Hridinski ježinac (<i>Paracentrotus lividus</i>)
            </p>
        ),
        headingDescription: (
            <p>
                Za razliku od crnog ježinca, hridinski ježinac <i>(lat. Paracentrotus lividus)</i> nikad nije crne boje
                već je tamnozelene, smeđe ili ljubičaste obojenosti.
            </p>
        ),
        video: null,
        images: mid_hridinskiJezinac,
    },
    crniJezinac: {
        headingTitle: (
            <p>
                Crni ježinac (<i>Arbacia lixula</i>)
            </p>
        ),
        headingDescription: (
            <p>
                Crni je ježinac, za razliku od hridinskog, uvijek crne boje. Dolazi do same površine, a obično ne dublje
                od 3 m. Ne sakuplja se za konzumaciju. Na nekim je područjima njegova brojnost toliko velika da je doveo
                do potpunog golobrsta algi.
            </p>
        ),
        video: null,
        images: mid_crniJezinac,
    },
    ljubicastiJezinac: {
        headingTitle: (
            <p>
                Ljubičasti ježinac (<i>Sphaerechinus granularis</i>)
            </p>
        ),
        headingDescription: (
            <p>
                Ljubičasti ježinac (<i>Sphaerechinus granularis</i>) naseljava cijelo Sredozemno more i istočni dio
                Atlantika od Britanskih otoka do Gvinejskog zaljeva. Nalazimo ga od površine do dubina od oko 100 m,
                najčešće na pjeskovito šljunkovitim dnima gdje se hrani koralinskim algama i detritusom. Bodlje su mu
                kratke i tupe, ljubičaste ili smeđe s bijelim vrhom, ili potpuno bijele. Čahura je svjetloljubičasta,
                promjera do 13 cm. U nekim područjima Italije, Francuske i Španjolske, gdje gonade pjegavog ježinca
                smatraju posebnom delicijom. Prežive li sakupljanje i napade zvjezdača, mogu doživjeti starost od
                desetak godina.
            </p>
        ),
        video: null,
        images: mid_ljubicastiJezinac,
    },
    crvenaZvjezdaca: {
        headingTitle: (
            <p>
                Crvena zvjezdača (<i>Echinaster sepositus</i>)
            </p>
        ),
        headingDescription: (
            <p>
                Crvena zvjezdača je najčešća zvjezdača oko rta Kabal. Crvene je boje, promjera do 20 cm i obično s pet
                krakova. Naseljava stjenovita, a rjeđe i pjeskovita dna. Na površini tijela su mala udubljenja iz kojih
                proviruju sitne tamnocrvene škrge. Hrani školjkama i puževima.
            </p>
        ),
        video: null,
        images: mid_crvenaZvjezdaca,
    },
    trpovi: {
        headingTitle: <p>Trpovi</p>,
        headingDescription: (
            <p>
                U Jadranu je zabilježeno oko 30 vrsta trpova. U plitkom podmorju rta Kabal najčešći je{" "}
                <i>Hothuria tubulosa</i>. Obično leži na sedimentnim dnima. U vrijeme razmnožavanja se uspinju na
                povišena mjesta kako bi ispustili reproduktivne strukture.
            </p>
        ),
        video: null,
        images: mid_trpovi,
    },
    vlasulja: {
        headingTitle: (
            <p>
                Vlasulja (<i>Anemonia viridis</i>)
            </p>
        ),
        headingDescription: (
            <p>
                Vlasulja je jedna od najčešćih moruzgvi plitkoga dna. Lovke su joj žućkasto zelenkaste boje s
                ljubičastim vrhovima. Ovakva obojenost potječe iz simbiotskih algi, zooksantela, koje žive u njenom
                tijelu. Zbog toga što ove alge trebaju svjetlo, vlasulje žive na dobro osvijetljenim i plitkim dnima.
                Ponekad unutar njenih lovki često žive simbiontski organizmi poput kozica, račića ili glavoča.
            </p>
        ),
        video: null,
        images: mid_vlasulja,
    },
    crvenaMoruzgva: {
        headingTitle: (
            <p>
                Crvena moruzgva (<i>Actinia equina</i>)
            </p>
        ),
        headingDescription: (
            <p>
                Crvena moruzgva živi neposredno uz samu površinu mora na stjenovitom dnu. Lako je uočljiva zbog svoje
                grimiznocrvene boje. Lovim male beskralješnjake i planktonske organizme s 192 kratke lovke. Vjerojatno
                može doživjeti više desetaka godina starosti.
            </p>
        ),
        video: null,
        images: mid_crvenaMoruzgva,
    },
    busenastiKoralj: {
        headingTitle: (
            <p>
                Busenasti koralj (<i>Cladocora caespitosa</i>)
            </p>
        ),
        headingDescription: (
            <p>
                Busenasti koralj spada u kamene koralje. Raste u kolonijama kuglastog oblika. U Nacionalnom parku Mljet
                ova je vrsta izgradila koraljni greben dugačak koje se proteže između 4 i 18 m dubine, površine oko 650
                kvadratnih metara. Oko rta Kabal je česta ali samo u obliku pojedinačnih manjih kolonija.
            </p>
        ),
        video: null,
        images: mid_busenastiKoralj,
    },
    nanar: {
        headingTitle: (
            <p>
                Nanar (<i>Phorcus turbinatus</i>)
            </p>
        ),
        headingDescription: (
            <p>
                Nanar ili orgc, uobičajan je puž najplićeg stjenovitog dna. Obično nikad ne zalazi dublje od 0,5 m.
                Okruglaste je kućice zelenskaste boje s brojnim pjegicama, prosječnog promjera oko 1,5 cm. Kada puž
                ugine, praznu će kućicu naseliti rakovi samci. Tradicionalno ali rijetko, nanari su se sakupljali i jeli
                kuhani, pri čemu bi se puž iz kućice izvlačio pomoću iglice.
            </p>
        ),
        video: null,
        images: mid_nanar,
    },
    volak: {
        headingTitle: (
            <p>
                Volak (<i>Hexaplex trunculus</i>)
            </p>
        ),
        headingDescription: (
            <p>
                Volak je puž s neuglednom kućicom kvrgavih izraslina i zaobljenja. Živi na mješovitim dnima od površine
                do preko 50 m dubine, a podnosi i onečišćena područja. Na kućici su često naseljene drugi organizmi,
                posebno alge. Hrani se školjkašima ali i strvinom koju će locirati iz velike udaljenosti. Kod
                razmnožavanja zajedno grade nepravilni bijelo-žuti kokon s brojnim jajašcima. Svaka ženka položi oko
                stotinjak nepravilnih jajnih kapsula dužine oko 5 mm, a u svakoj kapsuli može biti preko 800 jajašaca. U
                kapsuli će se ličinke razvijati oko mjesec dana u kojoj će se metamorfozirati te iz nje izaći kao mali
                puzajući puževi. Još od prije 3500 godina, ovaj se puž upotrebljavao za dobivanje iznimno cijenjene boje
                poznate kao kraljevski purpur zbog čega je u povijesti bio sakupljan u cijelom Sredozemlju.
            </p>
        ),
        video: null,
        images: mid_volak,
    },
    petrovoUho: {
        headingTitle: (
            <p>
                Petrovo uho (<i>Haliotis tuberculata</i>)
            </p>
        ),
        headingDescription: (
            <p>
                Petrovo uho rijetko viđamo kao živog puža. Preko dana je skriven ispod kamenja, a tek noću izlazi i
                hrani se algama. Međutim kada ugine, lako ga uočavamo zbog prekrasne sedefaste unutrašnjosti kućice.
            </p>
        ),
        video: null,
        images: mid_petrovoUho,
    },
    brbavica: {
        headingTitle: (
            <p>
                Brbavica (<i>Venus verrucosa</i>)
            </p>
        ),
        headingDescription: (
            <p>
                Ova vrsta ima puno narodnih naziva. To nam pokazuje kako je školjkaš bitan u prehrani lokalnog
                stanovništva. Komercijalno je vrlo zanimljiva ali se ne uzgaja već jedino sakuplja iz prirode. Živi
                ukopana u sediment. Može doživjeti starost od oko 15 godina.
            </p>
        ),
        video: null,
        images: mid_brbavica,
    },
    kozica: {
        headingTitle: (
            <p>
                Kozica (<i>Palaemon serratus</i>)
            </p>
        ),
        headingDescription: (
            <p>
                Kozica najčešće naseljava najplića stjenovita dna. Prozirna je sa smeđe crvenim linijama po tijelu. S
                izduženim klještima skuplja alge. Međutim, rado će doći do mirnog kupača i pokušati iščupali malo kože,
                što može bit pomalo bolno. U nekim se krajevima love i jedu frigane.
            </p>
        ),
        video: null,
        images: mid_kozica,
    },
    kosmej: {
        headingTitle: (
            <p>
                Kosmej (<i>Eriphia verrucosa</i>)
            </p>
        ),
        headingDescription: (
            <p>
                Kosmej je veliki rak prepoznatljiv po jakim kliještama nejednake veličine. Ima puno narodnih naziva, pa
                ga tako u Splitu zovu žbirac. Živi od same površine do nekoliko metara dubine među algama. Najčešće ima
                svoju rupu iz koje izlazi noću u potrazi za hranom kao što su školjkaši i puževi. Ova vrsta je
                poslastica u mnogim primorskim mjestima, ali zbog male brojnosti, nije komercijalno zanimljiv.
            </p>
        ),
        video: null,
        images: mid_kosmej,
    },
    rakSamac: {
        headingTitle: (
            <p>
                Rak samac (<i>Pagurus anachoretus</i>)
            </p>
        ),
        headingDescription: (
            <p>
                Rak samac živi u praznoj kućici puža. Kada veličinom preraste kućicu, mora potražiti novu. Hrani se
                algama ili ostacima životinja. Obično su vrlo brojni na plitkom stjenovitom dnu.
            </p>
        ),
        video: null,
        images: mid_rakSamac,
    },
    crnej: {
        headingTitle: (
            <p>
                Crnej (<i>Chromis chromis</i>)
            </p>
        ),
        headingDescription: (
            <>
                <p>
                    Crnej je jedna od najčešćih riba Jadranskog mora. Sivo-crne je boje i obično boravi u plovama
                    neposredno iznad morskoga dna premda ponekad dolazi do same površine. Mladi crneji su potpuno
                    drugačiji od odraslih, tirkizne su boje, borave u manjim plovama neposredno uz dno, često u sjeni i
                    blizini rupa.
                </p>
                <p>
                    Komercijalno ova vrsta nije zanimljiva premda se na nekim otocima soli i kao takva predstavlja
                    vrhunsku delikatesu.
                </p>
            </>
        ),
        video: null,
        images: mid_crnej,
    },
    fratar: {
        headingTitle: (
            <p>
                Fratar (<i>Diplodus vulgaris</i>)
            </p>
        ),
        headingDescription: (
            <>
                <p>
                    Fratar je lako prepoznatljiv po crnim prugama iza glave i na repu. Često živ u brojnim plovama od
                    površine pa do 150 m dubine. Može narasti do 45 cm dužine i težiti preko 1,5 kg. Svejed je, premda
                    se najčešće hrani beskralješnjacima kao što su mnogočetinaši, rakovi, školjke i mali ježinci. Kao i
                    mnoge ribe, tijekom odrastanja mijenja spol. Fratar je s dvije godine spolno zreo i tada je mužjak,
                    a kasnije se pretvara u ženku. Jaja i ličinke su u planktonu gdje borave oko mjesec dana.
                    Komercijalno je jako zanimljiv, a najčešće se lovi mrežama i udicom.
                </p>
            </>
        ),
        video: null,
        images: mid_fratar,
    },
    pirka: {
        headingTitle: (
            <p>
                Pirka (<i>Serranus scriba</i>)
            </p>
        ),
        headingDescription: (
            <>
                <p>
                    Pirka je riba izduženog tijela s jedinstvenim uzorkom od pet do sedam okomitih šara. Šare su smeđe
                    crvene boje. U sredini tijela s trbušne strane nalazi se, za tu vrstu svojstvena, svijetloplava
                    mrlja. Često živi u paru, neposredno uz morsko dno od 0 do 30 m dubine. Grabežljivac je i hrani se
                    malim beskralješnjacima i manjim ribama. Pirka je hermafrodit koja u isto vrijeme ima razvijene i
                    muške i ženske reproduktivne organe. Pojavi li se hobotnica u njihovom području, pratit će je dok ne
                    ode iz njihovog teritorija.
                </p>
                <p>Komercijalno nije zanimljiva, premda je ukusna i lovi se na udicu.</p>
            </>
        ),
        video: null,
        images: mid_pirka,
    },
    knez: {
        headingTitle: (
            <p>
                Knez (<i>Coris julis</i>)
            </p>
        ),
        headingDescription: (
            <p>
                Knez je dugoljasta ribica koja uz cijelu bočnu stranu ima narančastu cik-cak prugu. Vrlo je brza i
                radoznala. Manji primjerci i ženke često su u manjim plovama, te zajedno traže beskralješnjake na dnu
                kojima se hrane. Mužjaci i ženke se značajno razlikuju po obojenosti i obliku tijela, pa su ih ranije
                smatrali različitim vrstama. Vrlo su česti do desetak metara dubine, posebno na stjenovitom dnu obraslom
                algama. Kad sunce zađe, svi se zakopaju u pijesak.
            </p>
        ),
        video: null,
        images: mid_knez,
    },
    usata: {
        headingTitle: (
            <p>
                Ušata (<i>Oblada melanura</i>)
            </p>
        ),
        headingDescription: (
            <p>
                Ušata je plosnata srebrna riba s crnom mrljom ispred repa. Obično naraste oko 25 cm dužine i težine oko
                300 g. Može doživjeti deset godina starosti. Iznimno dobro vidi i brzo pliva. Predator je i hrani se
                manjim ribama. Često je u brojnim plovama, posebno uz stjenovite obale otvorenog mora. Komercijalno je
                zanimljiva i lovi se mrežama, udicom ili vršama.
            </p>
        ),
        video: null,
        images: mid_usata,
    },
    pjevcic: {
        headingTitle: <p>Pjevčić</p>,
        headingDescription: (
            <p>
                Pjevčića je više vrsta i često ih pronalazimo uz samu površinu mora na stjenovitom dnu među algama.
                Pjevčić oštronosić (<i>Tripterygion melanurus</i>) crvenog je tijela i tamne glave, a mužjaci su vrlo
                teritorijalni i brinu se za jajašca koje je položila ženka. Dužine su do 5 cm.
            </p>
        ),
        video: null,
        images: mid_pjevcic,
    },
    salpa: {
        headingTitle: (
            <p>
                Salpa (<i>Sarpa salpa</i>)
            </p>
        ),
        headingDescription: (
            <p>
                Salpa je riba srebrno zelenkaste boje. Spljoštena je, a uzduž cijelog tijela ima brojne zlatno-žute
                pruge. Vrsta je uvijek u plovama, u naseljima algi ili morskih cvjetnica. Biljojed je i jedan od glavnih
                makroherbivora Sredozemnog mora. Odrasli primjerci mogu dostići 50 cm dužine, ali takvi su danas vrlo
                rijetki. Vrsta je u prošlosti bila jedna od glavnih lovina mrežama migavicama na području rta Kabal, a u
                plovama je bilo stotine jedinki, od malih mužjaka do velikih ženki.
            </p>
        ),
        video: null,
        images: mid_salpa,
    },
    martinka: {
        headingTitle: (
            <p>
                Martinka (<i>Symphodus ocellatus</i>)
            </p>
        ),
        headingDescription: (
            <p>
                Mužjak martinke gradi gnijezdo od algi koje može doseći tridesetak centimetara. Ribica je obično manja
                od deset centimetara. Razmnožava se ljeti kada mužjak razvije tzv. svadbeno ruho i jedna je od
                najljepših ribica plitkoga dna. Ženke, manjih dimenzija i neuglednijeg izgleda. Dolazit će u provjeru
                kvaliteta mužjaka i njegovog gnijezda, a ako ih snubljenjem i gradnjom zadovolji, dat će mu da oplodi i
                čuva jajašca.
            </p>
        ),
        video: null,
        images: mid_martinka,
    },
    komarca: {
        headingTitle: (
            <p>
                Komarča (<i>Sparus aurata</i>)
            </p>
        ),
        headingDescription: (
            <p>
                Komarča je srebrnkasto siva, izdužena riba jake čeljusti. Iza škrga ima tamnu mrlju. Hrani se
                školjkašima, puževima i drugim beskralješnjacima. Može narasti do težine od 10 kg. Jedna je od najčešćih
                vrsta koje se uzgajaju u ribogojilištima.
            </p>
        ),
        video: null,
        images: mid_komarca,
    },
    trlja: {
        headingTitle: <p>Trlja</p>,
        headingDescription: (
            <p>
                Dvije vrste trlja žive u Jadranskom moru. Drže se dna na kojem pronalazi hranu pomoću dva dugačka pipaka
                ispod usta. Hrane se malim beskralješnjacima. Kreću se u plovama, a najčešća na pjeskovitim dnima. Imaju
                vretenasto tijelo sa crvenkasto smeđim šarama.
            </p>
        ),
        video: null,
        images: mid_trlja,
    },
    cipli: {
        headingTitle: <p>Cipli</p>,
        headingDescription: (
            <p>
                U Jadranu živi nekoliko vrsta cipala. Svi imaju vretenasto tijelo, srebrnkaste boje. Često je u plovama,
                pliva z površinu ili uz dno gdje se hrani algama ili sitnim beskralješnjacima. Neke vrste mogu narasti
                više od 50 cm, i doživjeti starost od preko 20 godina.
            </p>
        ),
        video: null,
        images: mid_cipli,
    },
    slingurice: {
        headingTitle: <p>Slingurice</p>,
        headingDescription: (
            <p>
                Slingurice su zmijolike riba dužine do oko deset centimetara s brojnim crnim i bijelim sitnim pjegama na
                zelenkastom tijelu. Postoji više vrsta koje nije jednostavno razlikovati. Naseljavaju najplića
                stjenovita dna. Vrlo su česte.
            </p>
        ),
        video: null,
        images: mid_slingurice,
    },
};

export default MaskomDisalicomContentHR;
