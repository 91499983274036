import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

i18n.on("languageChanged", function (lng) {
    localStorage.setItem("lng", lng);
});

i18n.use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: "hr",
        debug: false,
        whitelist: ["en", "hr"],
        interpolation: {
            escapeValue: false,
            formatSeparator: ",",
        },
        backend: {
            loadPath: "/locales/{{ns}}/{{lng}}.json",
        },
        ns: ["translate"],
        detection: {
            order: ["localStorage"],
            lookupLocalStorage: "lng",
            checkWhitelist: true,
        },
    });

export default i18n;
