import React, { useState } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { image } from "d3";

import KmlIcon from "./Media/Icons/KmlIcon";
// React Swiper
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

// import { LazyLoadComponent, LazyLoadImage } from "react-lazy-load-image-component";
// import ReactPlayer from "react-player";

const Place = ({ path, data, idx }) => {
    const { name, description, sections } = data;

    return (
        <div
            className=""
            key={`place-wrapper-${idx}`}
        >
            <div className="flex justify-between items-center">
                <h2
                    className={[
                        // path === "lozna" ? "-scroll-m-80 md:-scroll-m-20" : "",
                        // path === "glavna" ? "-scroll-m-20 md:-scroll-m-60" : "",
                        // path === "stupisce" ? "-scroll-m-40 md:-scroll-m-40" : "",
                        // path === "tiha" ? "-scroll-m-40 md:-scroll-m-10" : "",
                        "text-3xl mb-2",
                    ].join(" ")}
                    key={`main-title-${idx}`}
                    id={path}
                >
                    {name}
                </h2>
                <a href={`/data/${path}.kml`}>
                    <KmlIcon />
                </a>
            </div>
            <p>{description}</p>

            <Tabs
                defaultActiveKey="0"
                id="fill-tab-example"
                className="mb-3"
                fill
            >
                {sections.map((section, idxSec) => {
                    return !section.sectionTitle ? (
                        "" // This ternary is used to prevent rendering of empty sections and raising errors
                    ) : (
                        <Tab
                            key={`place-section-wrapper-${path}-${idxSec}`}
                            eventKey={idxSec}
                            title={section.sectionTitle}
                        >
                            <div className="text-justify">
                                {section.sectionDescription ? section.sectionDescription : ""}
                            </div>
                            {/* Section images */}
                            {section.sectionImg && (
                                <div
                                    key={`place-section-heading-${path}`}
                                    className={`${section.headings.length > 1 ? "px-8 md:px-5" : ""} mt-8 mb-2`}
                                >
                                    <Carousel
                                        className="[&>*]:text-justify"
                                        dynamicHeight={false}
                                        showThumbs={false}
                                        showStatus={false}
                                        infiniteLoop={true}
                                        autoPlay
                                        //key={`carousel-${path}-${idx}`}
                                        interval={5000}
                                    >
                                        {section.sectionImg.map((image, idximage) => {
                                            return (
                                                <div
                                                    key={`carousel${idxSec}-${path}-${idximage}`}
                                                    className="group"
                                                >
                                                    {/* <LazyLoadImage src={image.path} /> */}
                                                    <img
                                                        src={image.path}
                                                        loading="eager"
                                                    />
                                                    <div className="absolute bottom-0 w-full h-full background-gradient">
                                                        <h6 className="relative flex justify-center items-end pb-2 image-heading-style">
                                                            <span className="mb-2">{image.title}</span>
                                                        </h6>
                                                        {/* <div className="[&>*]:text-justify">
                                                                    {image.description ? image.description : ""}
                                                                </div> */}
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </Carousel>
                                    <div className="h-[20vh]"></div>
                                </div>
                            )}
                            <Swiper
                                navigation={true}
                                pagination={{
                                    dynamicBullets: true,
                                }}
                                modules={[Navigation]}
                                className="mySwiper"
                            >
                                {section.headings &&
                                    section.headings.map((heading, idxHead) => {
                                        return (
                                            <SwiperSlide key={`swiper-${path}-${idxHead}-${idxSec}`}>
                                                <div
                                                    key={`place-section-heading-${path}-${idxHead}`}
                                                    className={`${
                                                        section.headings.length > 1 ? "px-8 md:px-5" : ""
                                                    } mt-8 mb-2 `}
                                                >
                                                    {heading.headingTitle ? (
                                                        <h5 className="mb-2 font-medium text-2xl">
                                                            {heading.headingTitle}
                                                        </h5>
                                                    ) : (
                                                        ""
                                                    )}
                                                    {heading.headingDescription ? (
                                                        <div className="w-[99%] text-justify mb-2">
                                                            {heading.headingDescription}
                                                        </div>
                                                    ) : (
                                                        ""
                                                    )}
                                                    <Carousel
                                                        className="[&>*]:text-justify"
                                                        dynamicHeight={false}
                                                        showThumbs={false}
                                                        showStatus={false}
                                                        infiniteLoop={true}
                                                        autoPlay
                                                        //key={`carousel-${path}-${idx}`}
                                                        interval={5000}
                                                    >
                                                        {heading.images.map((image, idximage) => {
                                                            return (
                                                                <div
                                                                    key={`carousel${idxSec}-${path}-${idximage}`}
                                                                    className="group"
                                                                >
                                                                    {/* <LazyLoadImage src={image.path} /> */}
                                                                    <img
                                                                        src={image.path}
                                                                        loading="eager"
                                                                        className="object-contain"
                                                                    />
                                                                    <div className="absolute bottom-0 w-full h-full background-gradient">
                                                                        <h6 className="relative flex justify-center items-end pb-2 image-heading-style">
                                                                            <span className="mb-2">{image.title}</span>
                                                                        </h6>
                                                                        {/* <div className="[&>*]:text-justify">
                                                                    {image.description ? image.description : ""}
                                                                </div> */}
                                                                    </div>
                                                                </div>
                                                            );
                                                        })}
                                                    </Carousel>
                                                    {heading.video && (
                                                        <video
                                                            controls
                                                            src={heading.video}
                                                            autoPlay
                                                            loop
                                                            muted
                                                            className="mt-3 z-[10000] rounded-[10px]"
                                                        ></video>
                                                    )}
                                                </div>
                                            </SwiperSlide>
                                        );
                                    })}
                            </Swiper>
                        </Tab>
                    );
                })}
            </Tabs>

            {/* <Tabs>
                <Tab
                    eventKey="otvoreno-more"
                    title="Otvoreno more"
                >
                    123
                </Tab>
                <Tab
                    eventKey="duboka-dna"
                    title="Duboka dna"
                >
                    456
                </Tab>
                <Tab
                    eventKey="ribolov-na-rtu-kabal"
                    title="Ribolov na rtu Kabal"
                >
                    798
                </Tab>
                <Tab
                    eventKey="maskom-i-disalicom"
                    title="Maskom i disalicom"
                >
                    555
                </Tab>
            </Tabs> */}
        </div>
    );
};

export default Place;
