import React from "react";

const Arrow = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="68"
            height="68"
            fill="none"
        >
            <path
                fill="#FEFEFE"
                fillOpacity=".8"
                fillRule="evenodd"
                d="M4.594 33.875a29.53 29.53 0 1 0 59.061 0 29.53 29.53 0 0 0-59.061 0Zm63.281 0a33.75 33.75 0 1 1-67.5 0 33.75 33.75 0 0 1 67.5 0Zm-31.64-14.766a2.11 2.11 0 0 0-4.22 0v24.44l-9.053-9.058a2.112 2.112 0 0 0-2.987 2.987l12.657 12.656a2.11 2.11 0 0 0 2.986 0l12.657-12.656a2.112 2.112 0 1 0-2.987-2.987l-9.054 9.058v-24.44Z"
                clipRule="evenodd"
            />
        </svg>
    );
};

export default Arrow;