import React from "react";

const KmlIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="26"
            height="26"
            viewBox="0 0 512 512"
        >
            <path
                stroke="#ffffff"
                className="fill-current"
                d="M146.5 16.9c-10.9 2.7-21.2 11.1-26.6 21.9l-3.4 6.7-.3 65.2-.3 65.1-25.2.4-25.2.3-6.6 3.2c-8 3.9-15.9 12-19.7 20l-2.7 5.8-.3 85.5c-.2 60.3 0 87.3.8 91.5 2.9 15.5 14.6 30.7 29 37.7 10.1 4.9 15.4 5.8 34 5.8h15.8l.4 20.2c.3 20.1.4 20.4 3.3 26.3 3.4 7 10 14.4 15.9 17.9 10.1 5.9 1.5 5.6 160.6 5.6 130.9 0 146.2-.2 151.1-1.6 13.4-4 23.4-14 27.3-27.3 1.4-4.9 1.6-23.6 1.6-185.5V101.5l-42.8-42.7L390.5 16l-120.5.1c-66.3.1-121.8.4-123.5.8zM376 73.1v37.1l2.9 2.9 2.9 2.9H456v170.7c0 122-.3 171.9-1.1 174.8-1.5 5.2-8.2 11.9-13.4 13.4-5.7 1.5-285.3 1.5-291 0-5.2-1.5-11.9-8.2-13.4-13.4-.6-2.2-1.1-11.1-1.1-19.7V426l132.3-.2 132.2-.3 5.5-2.8c7.2-3.7 13.1-9.6 16.6-16.5l2.9-5.7v-139l-2.8-5.5c-3.7-7.2-9.6-13.1-16.5-16.6l-5.7-2.9-132.2-.3L136 236v-90.8c0-61.7.4-91.9 1.1-94.6 1.4-5 7.3-11.3 12.4-13.1 3.4-1.2 22-1.4 115.3-1.5H376v37.1zm43 .4L441.5 96H396V73.5c0-12.4.1-22.5.3-22.5.1 0 10.3 10.1 22.7 22.5zM116 216v20H95.3c-11.5 0-22.5-.5-24.6-1.1-4.3-1.1-10.1-5.9-12.2-9.8-3.8-7.5-2.5-16 3.3-22.3 5.8-6.1 8.7-6.7 33-6.8H116v20zm115.2 40c164.4.5 164.7.5 167.4 2.6 1.5 1.1 3.7 3.3 4.8 4.8 2.1 2.7 2.1 3.7 2.1 67.6 0 63.9 0 64.9-2.1 67.6-1.1 1.5-3.3 3.7-4.8 4.8l-2.7 2.1H82.5l-5.6-2.6c-7.9-3.7-13.3-9-17.1-16.7l-3.3-6.7-.3-64.1-.3-64.2 5.3 2.1 5.3 2.2 164.7.5z"
            />
            <path
                stroke="#ffffff"
                className="fill-current"
                d="M168.9 88.9c-2.3 2.3-2.9 3.8-2.9 7.1s.6 4.8 2.9 7.1l2.9 2.9h88.4l2.9-2.9c4.1-4.1 4.1-10.1 0-14.2l-2.9-2.9h-88.4l-2.9 2.9zM168.9 128.9c-2.3 2.3-2.9 3.8-2.9 7.1s.6 4.8 2.9 7.1l2.9 2.9h168.4l2.9-2.9c2.3-2.3 2.9-3.8 2.9-7.1s-.6-4.8-2.9-7.1l-2.9-2.9H171.8l-2.9 2.9zM168.9 168.9c-2.3 2.3-2.9 3.8-2.9 7.1s.6 4.8 2.9 7.1l2.9 2.9h168.4l2.9-2.9c2.3-2.3 2.9-3.8 2.9-7.1s-.6-4.8-2.9-7.1l-2.9-2.9H171.8l-2.9 2.9zM111.1 277.4c-5 2.8-5 2.8-5.1 53.3 0 50.7 0 51.2 5.1 53.9 3.5 1.8 6.3 1.8 9.8 0 4.3-2.3 5.1-5.4 5.1-20.9v-14.2l3.3-3.9 3.3-3.8 13.2 20.9c13.1 20.8 15.2 23.3 20.3 23.3 3.2 0 7.4-2.6 8.7-5.5 2.5-5.5 3.3-4-23.1-45.9-4.9-7.7-5.5-9.2-4.4-10.5 12.9-15.2 26.6-32.1 27.5-33.8 4.4-8.1-4.5-17.1-13-13.2-1.3.5-9.7 10-18.8 20.9l-16.5 19.9-.5-17.7c-.3-9.7-.9-18.5-1.4-19.5-1.1-2.2-5.8-4.7-8.8-4.7-1.3.1-3.4.7-4.7 1.4zM201.1 277.4c-5 2.8-5 2.8-5.1 53.3 0 50.7 0 51.2 5.1 53.9 3.5 1.8 6.3 1.8 9.8 0 4.9-2.6 5.1-4.1 5.1-40.3v-33.7l12.3 12.1c7.1 7.1 13.4 12.4 15 12.8 5.1 1.3 8-.5 20.4-12.8l12.3-12.1v33.8c0 36.6.2 38 5.5 40.4 5.1 2.4 11.2.4 13.3-4.3 1.9-4.1 1.7-95.7-.2-99.4-2.4-4.5-8.3-6.3-12.9-4-1.2.6-9.7 8.6-18.9 17.7L246 311.5l-16.7-16.7c-9.3-9.1-17.8-17.1-19-17.7-2.9-1.4-6.2-1.3-9.2.3zM321.1 277.4c-5 2.8-5 2.8-5.1 53.3 0 50.7 0 51.2 5.1 53.9 4.1 2.1 55.7 2.1 59.8 0 6.6-3.5 6.6-13.7 0-17.2-2.2-1.1-7.6-1.4-23.9-1.4h-21v-41c0-29.6-.3-41.6-1.2-43.5-2.2-4.8-8.8-6.8-13.7-4.1z"
            />
        </svg>
    );
};

export default KmlIcon;
