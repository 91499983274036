import Logo from "assets/Logo";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

const Footer = () => {
    const { t, i18n } = useTranslation();

    /* const reloadResources = async() => {
        await i18n.reloadResources();
    };

    useEffect(() => {
        reloadResources();
    }, [i18n.language]); */

    return (
        <footer className="relative footer z-30 flex w-full bg-blue-400 h-fit md:h-full flex-col md:pt-2">
            <div className="w-full grid grid-cols-3 justify-center pt-4 md:flex-col md:flex md:h-full md:px-4 md:items-center px-5 md:gap-3">
                <a href="https://ec.europa.eu/" className="md:w-1/3 w-full self-center flex justify-center">
                    <img
                        className="w-full max-w-[200px]"
                        src="/HR V Sufinancira EUROPSKA UNIJA_NEG_NEG.png"
                    />
                </a>
                <a href="https://euribarstvo.hr/" className="md:w-1/3 w-full self-center flex justify-center">
                    <img
                        className="w-full max-w-[200px]"
                        src="/OP za pomorstvo i ribarstvo_boja_bez pozadine_veci.png"
                    />
                </a>
                <a href="https://vlada.gov.hr/" className="md:w-1/4 w-full self-center flex justify-center">
                    <img
                        className="w-full pb-[40px] max-w-[200px] scale-75 md:scale-95"
                        src="/rh_400w.png"
                    />
                </a>
                <p className="text-white col-span-3 text-center"><i>&ldquo;{t("Morski prsti otoka Hvara - poluotok Kabal")}&ldquo;</i></p>
            </div>
            <div className="flex md:flex-col justify-between items-center">
                <div className="text-gray-100 text-[12px] ml-8 md:ml-2 md:text-[10px] md:mt-2px">
                    Map by Mapbox;
                    <a
                        className="no-underline text-gray-100 hover:text-[#00AEEF]"
                        href="https://wiki.osmfoundation.org/wiki/Main_Page"
                    >
                        &copy;OpenSreetMap contributors
                    </a>{" "}
                    <a
                        className="no-underline text-gray-100 hover:text-[#00AEEF]"
                        href="https://www.mapbox.com/"
                    >
                        &copy;Mapbox
                    </a>{" "}
                    <a
                        className="no-underline text-gray-100 hover:text-[#00AEEF]"
                        href="https://www.maxar.com/products/satellite-imagery"
                    >
                        &copy;Maxar
                    </a>
                </div>
                <div className="flex items-center">
                    <span className="text-gray-100 text-right leading-4 text-[12px] md:text-[9px] md:leading-3">
                        Copyright &copy; {new Date().getFullYear()}.{" "}
                        <a
                            className="no-underline text-gray-100 hover:text-[#00AEEF]"
                            href="https://www.listlabs.net"
                        >
                            LIST LABS
                        </a>{" "}
                        <br /> {t("All rights reserved")}
                    </span>
                    <a href="https://www.listlabs.net">
                        <div className="h-fit mr-12 ml-2.5 py-2 md:py-1 md:mr-1">
                            <Logo />
                        </div>
                    </a>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
