import React, { useState, useEffect, useRef, lazy } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Scrollama, Step } from "react-scrollama";

import Map from "components/Map";
import Place from "components/Place";

import KabalCover from "assets/kabal_cover.jpg";
import BaseMap from "assets/basemap.png"; // Moved here for faster loading
/* import BaseMap from "assets/basemap_tilted.jpeg"; */
import turtle from "assets/turtle.svg"; // Also moved here for faster loading
import Arrow from "assets/Arrow";
import { useTranslation } from "react-i18next";
import Logo from "assets/Logo";
import Footer from "components/Footer";
import Loader from "components/Loader";
import IsDesktopDetector from "components/isDesktopDetector";

const MainPage = () => {
    const effectRan = useRef(false); // Handle double rerender in strictmode
    const main = useRef();
    const mainCover = useRef();
    const mapContainer = useRef();
    let location = useLocation("root");
    const [usedContent, setUsedContent] = useState();
    const [isVideoLoading, setIsVideoLoading] = useState(true);
    const videoRef = useRef();

    const isDesktop = IsDesktopDetector();

    const [firstLoadHash, setFirstLoadHash] = useState(window.location.hash.replace("#", ""));

    const { t, i18n } = useTranslation();

    const [currentSection, setCurrentSection] = useState({ path: "root" });
    const [mapContainerSize, setMapContainerSize] = useState({
        width: mapContainer?.current?.clientWidth,
        height: mapContainer?.current?.clientHeight,
    });
    const navigate = useNavigate();

    const [showLoader, setShowLoader] = useState(true);

    const onScrollEnd = (hash) => {
        navigate(`#${hash}`);
        setCurrentSection({ path: hash });
    };

    const scrollToSection = (path) => {
        if (path) {
            const target = document.getElementById(path);
            setTimeout(() => {
                target?.scrollIntoView({
                    behavior: "instant",
                    block: isDesktop ? "start" : "center",
                    inline: isDesktop ? "start" : "center",
                });
                navigate(`#${path}`);
                setTimeout(() => {
                    setFirstLoadHash("");
                    setCurrentSection({ path: path });
                    setShowLoader(false);
                }, 1000);
            }, 500);
        }
        setTimeout(() => {
            setShowLoader(false);
        }, 1000);
    };

    // Initial load
    useEffect(() => {
        loadTranslatedContent();

        if (firstLoadHash) {
            window.addEventListener("load", () => {
                setTimeout(() => {
                    scrollToSection(firstLoadHash);
                }, 1000);
            });

            // Fallback if load doesn't fire which sometimes happens on mobile
            setTimeout(() => {
                setShowLoader(false);
                if (firstLoadHash) {
                    scrollToSection(firstLoadHash);
                }
            }, 7000);
        } else {
            setTimeout(() => {
                setShowLoader(false);
            }, 2000);
        }

        return () => {
            effectRan.current = true;
        };
    }, []);

    useEffect(() => {
        if (!isVideoLoading) {
            videoRef.current.currentTime = 2.6;
        }
    }, [isVideoLoading]);

    useEffect(() => {
        resizeHandler();

        window.addEventListener("resize", resizeHandler);
    }, [usedContent]);

    useEffect(() => {
        if (location.hash && firstLoadHash.length < 2) {
            let newLocation = location.hash.replace("#", "");
            setCurrentSection({ path: newLocation });
        }
    }, [location]);

    const resizeHandler = () => {
        setMapContainerSize((prev) => {
            return {
                ...prev,
                width: mapContainer.current.clientWidth,
                height: mapContainer.current.clientHeight,
            };
        });
    };

    const arrowStart = () => {
        document.querySelector(".place-section").scrollIntoView({ behavior: "smooth" });
    };

    const handleLanguageChange = async (e) => {
        i18n.changeLanguage(e.target.id);
        await i18n.reloadResources();
    };

    const loadTranslatedContent = async () => {
        if (i18n.language === "en") {
            const ContentEN = await import("components/ContentEN");

            const def = ContentEN.default;
            setUsedContent(ContentEN.default);
            await i18n.reloadResources();
        } else {
            const ContentHR = await import("components/ContentHR");
            const def = ContentHR.default;
            setUsedContent(ContentHR.default);
            await i18n.reloadResources();
        }
    };

    useEffect(() => {
        loadTranslatedContent();
    }, [i18n.language]);

    const onStepEnter = ({ data }) => {
        if (firstLoadHash.length > 2) return;
        navigate(`#${data}`);
        setCurrentSection({ path: data });
    };

    const onStepExit = ({ data, direction }) => {
        if (firstLoadHash.length > 2) return;
        if (data === "zavala" && direction === "up") {
            navigate("/");
            setCurrentSection({ path: "root" });
        }
    };

    return (
        <>
            {showLoader && (
                <div
                    className="fixed h-[100dvh] min-h-[100dvh] w-screen z-[70]"
                    onTouchMove={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                    }}
                    onTouchMoveCapture={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                    }}
                    onScroll={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                    }}
                    onScrollCapture={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                    }}
                >
                    <Loader />
                </div>
            )}
            <header className="relative tryout z-[60]">
                <div
                    ref={mainCover}
                    className="main-cover relative h-screen"
                    id="root"
                >
                    {isVideoLoading && (
                        <img
                            src={KabalCover}
                            alt="Kabal peninsula"
                            className="min-h-screen min-w-full object-cover z-10 overflow-hidden"
                        ></img>
                    )}
                    <video
                        ref={videoRef}
                        className="min-h-screen min-w-full object-cover overflow-hidden"
                        id="videoPlayer"
                        poster={KabalCover}
                        autoPlay
                        loop
                        muted
                        controls={false}
                        preload={"auto"}
                        src="/media/poluotok-kabal-video.mp4"
                        type="video/mp4"
                        onLoadedMetadataCapture={() => {
                            setIsVideoLoading(false);
                        }}
                        playsInline
                    ></video>
                    {/* <div className="header-gradient w-full relative block bottom-0 h-80 md:h-64"></div> */}

                    <div className="absolute w-full flex flex-row justify-between top-0 z-50 text-2xl items-center text-white">
                        <div className="px-4 md:text-sm flex flex-row">
                            <button
                                id="hr"
                                onClick={handleLanguageChange}
                                className="mx-1"
                            >
                                HR
                            </button>
                            <span>|</span>
                            <button
                                id="en"
                                onClick={handleLanguageChange}
                                className="mx-1"
                            >
                                EN
                            </button>
                        </div>
                        <div className="flex flex-row mt-2 w-4/12 md:w-full items-center justify-end pr-4 max-w-[350px]">
                            <a href="https://euribarstvo.hr/">
                                <img
                                    className="w-full md:scale-75"
                                    src="/element_vidljivosti.png"
                                    loading="lazy"
                                />
                            </a>
                        </div>
                    </div>

                    <div className="absolute ml-8 z-20 bottom-10 md:pr-2">
                        <h1 className=" text-white text-5xl md:text-4xl">
                            StoryMap: &rdquo;{t("Morski prsti otoka Hvara")}&rdquo; - {t("Poluotok Kabal")}
                        </h1>
                        <h4 className=" text-white text-xl max-w-[85%] md:max-w-[85%] md:text-sm">
                            {t("Title description")}
                        </h4>
                        <div
                            className="mt-8 cursor-pointer"
                            onClick={arrowStart}
                        >
                            <Arrow />
                            <img
                                src={BaseMap}
                                className="hidden"
                            />
                            <img
                                src={turtle}
                                className="hidden"
                            />
                        </div>
                    </div>
                </div>
            </header>
            <div
                ref={mapContainer}
                className={`${
                    showLoader ? "overflow-hidden" : "overflow-auto"
                } sticky top-0 z-20 md:z-50 w-full h-screen overflow-visible md:float-none md:w-full bg-blue-500 md:max-h-[41vh] md:pt-0 md:top-0 md:pr-0 md:pl-0`}
                id="main-map-container"
            >
                {mapContainerSize && (
                    <Map
                        mapContainerSize={mapContainerSize}
                        baseMap={BaseMap}
                        turtle={turtle}
                        currentSection={currentSection}
                    />
                )}
                <div className="mobile-gradient w-full relative top-[-1px] hidden md:block h-16 pointer-events-none"></div>
            </div>
            <div className="px-4 flex flex-row z-50 fixed top-4 left-4 text-white">
                <button
                    id="hr"
                    onClick={handleLanguageChange}
                    className="mx-1"
                >
                    HR
                </button>
                <span>|</span>
                <button
                    id="en"
                    onClick={handleLanguageChange}
                    className="mx-1"
                >
                    EN
                </button>
            </div>
            <main
                ref={main}
                className="main-scroll z-30 md:block relative py-10 pl-10 md:p-0 tryout"
            >
                {usedContent?.places && (
                    <div className="text-white md:text-gray md:bg-blue-500 rounded-md md:rounded-none mr-10 md:mx-0">
                        <Scrollama
                            offset={0.5}
                            onStepEnter={onStepEnter}
                            onStepExit={onStepExit}
                        >
                            {usedContent?.places
                                .sort((a, b) => a.id - b.id)
                                .map((place, idx) => {
                                    return (
                                        <Step
                                            key={`place-main-wrapper-${place.path}-${idx}`}
                                            data={place.path}
                                        >
                                            <div className="pb-20 md:pb-5">
                                                <section
                                                    //id={place.path}
                                                    className={`section-${place.path} place-section md:w-full md:max-w-none rounded-md px-6 py-9`}
                                                    key={`section-${place.path}-${idx}`}
                                                >
                                                    {usedContent && (
                                                        <Place
                                                            data={place}
                                                            path={place.path}
                                                            key={`place-${place.path}-${idx}`}
                                                            idx={idx}
                                                            setCurrentSection={setCurrentSection}
                                                        />
                                                    )}
                                                </section>
                                            </div>
                                        </Step>
                                    );
                                })}
                        </Scrollama>
                    </div>
                )}
            </main>
            <Footer />
        </>
    );
};

export default MainPage;
