import React from "react";
import { useTranslation } from "react-i18next";
import turtle from "assets/turtle.svg";
import { motion } from "framer-motion";

const variants = {
    initial: {
        opacity: 0,
    },
    animate: {
        opacity: 1,
    },
};

const buubleTransition = {
    duration: 1,
    ease: "easeInOut",
    repeat: Infinity,
};

const ContainerVariants = {
    initial: {},
    animate: {
        transition: {
            staggerChildren: 1,
            repeat: Infinity,
            duration: 3,
        },
    },
};

const Loader = () => {
    const { t } = useTranslation();

    return (
        <div className="h-[100svh] w-screen bg-blue-500 flex flex-col p-10">
            <img
                className="max-w-sm w-full self-end"
                src="/element_vidljivosti.png"
                loading="lazy"
            />
            <div className="flex flex-col gap-5 flex-grow justify-center">
                <div className="relative w-full flex justify-center">
                    <motion.div
                        aria-busy="true"
                        className="absolute flex flex-col-reverse -top-20 z-[70] gap-1 -ml-3"
                        variants={ContainerVariants}
                        initial="initial"
                        animate="animate"
                    >
                        <motion.span
                            variants={variants}
                            transition={{ ...buubleTransition, delay: 0.3 }}
                            className="w-4 h-4 border-4 border-blue-900 rounded-full"
                        ></motion.span>
                        <motion.span
                            variants={variants}
                            transition={{ ...buubleTransition, delay: 0.6 }}
                            className="w-5 h-5 border-4 border-blue-900 rounded-full"
                        ></motion.span>
                        <motion.span
                            variants={variants}
                            transition={{ ...buubleTransition, delay: 0.9 }}
                            className="w-6 h-6 border-4 border-blue-900 rounded-full"
                        ></motion.span>
                    </motion.div>
                    <img
                        src={turtle}
                        className="w-36"
                    />
                </div>
                <h1 className=" text-white text-3xl md:text-4xl self-center">
                    StoryMap: &rdquo;{t("Morski prsti otoka Hvara")}&rdquo; - {t("Poluotok Kabal")}
                </h1>
            </div>
        </div>
    );
};

export default Loader;
