import React, { Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import MainPage from "./pages/MainPage";
import ReactGA from "react-ga4";
import Loader from "components/Loader";

function App() {
    ReactGA.initialize("G-9J404ZWEFS");

    return (
        <BrowserRouter>
            <Suspense fallback={<Loader />}>
                <Routes>
                    <Route
                        path="/"
                        element={<MainPage />}
                    ></Route>
                </Routes>
            </Suspense>
        </BrowserRouter>
    );
}

export default App;
