import React from "react";

import {
    lozna_om_dupin,
    lozna_om_kornjaca,
    lozna_om_meduza,
    lozna_dd_koraligenska,
    lozna_r_mreze,
    glavna_bumo_rasetkastaMrizica,
    glavna_bumo_primorskaPepeljuga,
    glavna_bumo_motar,
    stupisce_p_prstac,
    stupisce_p_zastitaPrstaca,
    stupisce_p_razmnPrstaca,
    stupisce_trp_trp,
    stupisce_trp_razmnZastita,
    tiha_ubr_periska,
    tiha_ubr_pretjeraniRibolov,
    tiha_ubr_sidrenje,
    tiha_ubr_otpad,
    tiha_sv_taxifolia,
    tiha_sv_cylindracea,
    tiha_sv_melibe,
    tiha_sv_straniPuzGolac,
    zavala_np_stabalcaPosidonije,
    zavala_np_razmn,
    zavala_np_nakupineListova,
    zavala_np_mrijest,
    zavala_np_ugroženostPosidonije,
    zukova_kic_velikiMorskiCrv,
    zukova_kic_komarca,
    zukova_od_otisciDinosaura,
    travna_ss_supralitoral,
    travna_ss_obalniPuzic,
    travna_ss_rakViticar,
    travna_ss_racicBabura,
    travna_zims_zaloSpuzve,
    glavna_uvala_uvala,
    glavna_lazina_lazina,
    glavna_bumo_oman,
    travna_uvala_uvala,
} from "./contentImages";

import MaskomDisalicomContentHR from "./MaskomDisalicomContentHR";

const ContentHR = {
    places: [
        {
            id: 6,
            name: "Uvala Lozna",
            path: "lozna",
            pointId: 2043,
            description: null,
            sections: [
                {
                    sectionTitle: "Otvoreno more",
                    sectionDescription: (
                        <p>
                            Ispred rta Kabal i uvale Lozna je otvoreno more. Njega stalno naseljavaju vrste poput dobrog
                            dupina, glavate želve i raznih vrsta meduza. Potrebno je strpljenje da bi se u daljini
                            ugledala dupinova peraja. To je područje koje naseljavaju i srdele, a love se noću mrežama
                            plivaricama uz privlačenje jakim svjetlima.
                        </p>
                    ),
                    headings: [
                        {
                            headingTitle: "Dobri dupin",
                            headingDescription: (
                                <>
                                    <p>
                                        Dobri dupin <i>(lat. Tursiops truncatus)</i> stalni je stanovnik Jadranskoga
                                        mora i često prolazi oko rta Kabal. Ti inteligentni morski sisavci dobro pamte
                                        te se u svojim kretanjima vjerojatno vode sjećanjem na neko mjesto s lako
                                        dostupnom hranom. A njihova hrana oko rta Kabal mogle bi biti ribe koje se
                                        zadržavaju uz morsko dno, kao što su tragalj i trlja. Te su ribe ovdje česte na
                                        dubinama od 30 do 60 m, gdje dupini lako zaranjaju.
                                    </p>
                                    <p>
                                        U prošlosti su se dupini smatrali štetočinama i bili su ubijani, da ne bi bili
                                        konkurencija ribarima. Danas su zaštićeni zakonom, a procjenjuje se da ih je u
                                        Jadranu oko deset tisuća.
                                    </p>
                                </>
                            ),
                            video: null,
                            images: lozna_om_dupin,
                        },
                        {
                            headingTitle: "Kornjača glavata želva",
                            headingDescription: (
                                <>
                                    <p>
                                        Glavate želve <i>(lat. Caretta caretta)</i> koje dolaze u Jadran većinom su se
                                        izlegle na plažama u Grčkoj. Premda se u Jadranu ne razmnožavaju, u njemu su
                                        vrlo brojne i koriste se njime kao životnim prostorom veći dio godine. Zapravo,
                                        smatra se da je Jadran jedno od najvažnijih područja ishrane i zimovališta
                                        glavatih želvi u Sredozemlju. One se hrane organizmima s morskoga dna, kao što
                                        su spužve, ježinci, rakovi samci, mješčićnice i školjkaši. Odrasle ženke mogu
                                        imati do 110 cm dugačak oklop i težiti 115 kg, a rone do dubine od oko sto
                                        metara.
                                    </p>
                                </>
                            ),
                            video: null,
                            images: lozna_om_kornjaca,
                        },
                        {
                            headingTitle: "Mediteranska meduza",
                            headingDescription: (
                                <>
                                    <p>
                                        Mediteranska meduza <i>(lat. Cotylorhiza tuberculata)</i>, česta je meduza
                                        Jadranskoga mora, posebno potkraj ljeta i tijekom jeseni. Lako je prepoznatljiva
                                        po klobuku sličnom prženom jajetu, pa je tako često i nazivaju. Lovke su joj
                                        kratke i ako je čovjek dodirne, obično ne peckaju. Hrani se planktonskim
                                        organizmima, ali su joj u ishrani iznimno bitni simbiotski mikroorganizmi koji
                                        žive u njezinu tijelu, a oni fotosintezom dopunjavaju potrebnu hranu za razvoj
                                        meduze. Zbog njih se meduza drži površine, gdje ima najviše svjetla. Među
                                        njezinim lovkama često se skrivaju mladi šnjuri.
                                    </p>
                                </>
                            ),
                            video: null,
                            images: lozna_om_meduza,
                        },
                    ],
                },
                {
                    sectionTitle: "Duboka dna",
                    sectionDescription: (
                        <p>
                            Dno oko rta Kabal strmo se spušta i na više od 60 m dubine. Na stijenama dubljim od 25 m
                            razvijena je koraligenska zajednica, skupina organizama svojstvena samo Sredozemnome moru.
                            Osnovu koraligenske zajednice čine koralinske alge, crvene alge koje u svoje tijelo ugrađuju
                            kalcijev karbonat pa su čvrste poput kamena. Mnoge rastu tek koji milimetar godišnje. U
                            koraligenu rta Kabal među ostalima žive i žute gorgonije, jastozi i škarpine. Do
                            koraligenske biocenoze moguće je doći samo uz SCUBA opremu. More je tu vrlo prozirno pa je s
                            površine maskom i disalicom moguće vidjeti dno do dvadesetak metara u plavetnilu dubine.
                        </p>
                    ),
                    headings: [
                        {
                            headingTitle: "Koraligenska zajednica",
                            headingDescription: (
                                <p>
                                    Koraligenska zajednica jedinstvena je i endemična biološka formacija koja se razvija
                                    samo u Sredozemnome moru. Razvija se u Jadranu obično između 30 i 100 metara dubine.
                                    Počinje na dubinama na kojima prestaju s rastom morske cvjetnice, a završava ondje
                                    gdje ima još dovoljno svjetla za rast scijafilnih algi, vrste koja treba malo
                                    svjetla za svoj razvoj. To je područje rasta i razvoja gorgonija, a na rtu Kabal
                                    razvija se žuta gorgonija <i>(lat. Eunicella cavolini)</i>. Od komercijalnih vrsta,
                                    u koraligenskoj zajednici posebno su važni rak jastog i riba škarpina.
                                </p>
                            ),
                            video: null,
                            images: lozna_dd_koraligenska,
                        },
                    ],
                },
                {
                    sectionTitle: "Ribolov",
                    sectionDescription: (
                        <>
                            <p>
                                Poluotok Kabal važno je područje za lokalne ribare koji love mrežama stajaćicama.
                                Međutim, mreže često zapinju i zaostaju na podmorskim strmcima te stvaraju tzv.{" "}
                                <i>Ghost Fishing</i> problem: premda ih se ne može izvaditi, one nastavljaju „loviti“
                                morske organizme. Lov mrežama u koraligenu često uništava spororastuće koralinske alge,
                                kao i prekrasne mekane gorgonije.
                            </p>
                        </>
                    ),
                    headings: [
                        {
                            headingTitle: null,
                            headingDescription: null,
                            video: null,
                            images: lozna_r_mreze,
                        },
                    ],
                },
                {
                    sectionTitle: "Maskom i disalicom",
                    sectionDescription: null,
                    headings: [
                        MaskomDisalicomContentHR.padina,
                        MaskomDisalicomContentHR.klobucic,
                        MaskomDisalicomContentHR.vlasulja,
                        MaskomDisalicomContentHR.crvenaMoruzgva,
                        MaskomDisalicomContentHR.nanar,
                        MaskomDisalicomContentHR.petrovoUho,
                        MaskomDisalicomContentHR.kozica,
                        MaskomDisalicomContentHR.kosmej,
                        MaskomDisalicomContentHR.rakSamac,
                        MaskomDisalicomContentHR.crnej,
                        MaskomDisalicomContentHR.fratar,
                        MaskomDisalicomContentHR.pirka,
                        MaskomDisalicomContentHR.promjenjivaSumporaca,
                        MaskomDisalicomContentHR.hridinskiJezinac,
                        MaskomDisalicomContentHR.crniJezinac,
                        MaskomDisalicomContentHR.ljubicastiJezinac,
                        MaskomDisalicomContentHR.crvenaZvjezdaca,
                        MaskomDisalicomContentHR.knez,
                        MaskomDisalicomContentHR.usata,
                        MaskomDisalicomContentHR.pjevcic,
                        MaskomDisalicomContentHR.slingurice,
                    ],
                },
            ],
        },
        {
            id: 5,
            name: "Uvala Glavna",
            path: "glavna",
            pointId: 1511,
            description: null,
            sections: [
                {
                    sectionTitle: "Uvala",
                    sectionDescription: null,
                    headings: [],
                    sectionImg: glavna_uvala_uvala,
                },
                {
                    sectionTitle: "Biljke uz morsku obalu",
                    sectionDescription: (
                        <>
                            <p>
                                Neposredno iznad morske razine je zona s rijetkom vegetacijom. Gledana iz daljine, vidi
                                se kao bijeli pojas visine jednog do tri metra. To stanište ipak naseljava mali broj
                                biljaka, tzv. halofita, vrsta koje mogu rasti na slanim područjima. Na poluotoku Kabal
                                moguće je pronaći četiri vrste halofita: motar <i>(lat. Crithmum maritimum)</i>,
                                rešetkastu mrižicu <i>(lat. Limonium cancellatum)</i>, primorski oman{" "}
                                <i>(lat. Inula crithmoides) </i> i primorsku pepeljugu{" "}
                                <i>(lat. Halimione portulacoides)</i>.
                            </p>
                            <p>
                                Motar (lokalni naziv je mator) tradicionalno se skupljao i upotrebljavao za kiseljenje
                                ili se pripremao lešo s jajima (za neke je to bolje od šparoga). Danas je skupa delicija
                                koja se rijetko može kupiti. Primorska pepeljuga i primorski oman također su jestivi,
                                ali se tradicionalno na otoku Hvaru ne konzumiraju.
                            </p>
                        </>
                    ),
                    headings: [
                        {
                            headingTitle: "Primorski oman",
                            headingDescription: (
                                <p>
                                    Primorski oman <i>(lat. Inula crithmoides)</i> grmolika je trajnica mesnatih
                                    listova, visoka do 1 m, a na vrhovima grana razvija žuto-narančasti cvijet promjera
                                    do 3 cm. Cvate tijekom ljeta i jeseni. Na poluotoku Kabal često je pronalazimo na
                                    najvišim područjima šljunčanih plaža. Mladi listovi i stabljike su jestivi (mogu se
                                    kuhati ili se od njih radi salata), ali se na području otoka Hvara ta biljka
                                    tradicionalno ne koristi.
                                </p>
                            ),
                            video: null,
                            images: glavna_bumo_oman,
                        },
                        {
                            headingTitle: "Rešetkasta mrižica",
                            headingDescription: (
                                <p>
                                    Rešetkasta mrižica <i>(lat. Limonium cancellatum)</i>, građena je od prizemnih, 2 cm
                                    kratkih listova i uspravnih tankih stabalaca koja se granaju u mrežasto raspoređene
                                    ogranke na kojima su sitni, 5 mm dugački ljubičasti cvjetovi. Cijela biljka visoka
                                    je dvadesetak centimetara. Cvate tijekom proljeća, ljeta i jeseni. Naseljava
                                    stjenovite obale, a raste iz pukotina u stijenama uz samu obalu. Endemični je
                                    organizam jadranskoga područja.
                                </p>
                            ),
                            video: null,
                            images: glavna_bumo_rasetkastaMrizica,
                        },
                        {
                            headingTitle: "Primorska pepeljuga",
                            headingDescription: (
                                <p>
                                    Primorska pepeljuga <i>(lat. Halimione portulacoides)</i> je bjelkastozeleni grm
                                    visok dvadesetak centimetara, razgranatog stabalca. Mesnati listovi su bjelkasti,
                                    elipsasti ili duguljasto jajolikih oblika i kratkih peteljki. Cvjetići su
                                    žućkastozeleni i neugledni. Cvate tijekom proljeća i ljeta. Raste uz samu obalu, na
                                    mjestima koje more može dijelom poplaviti u vrijeme plime. Rasprostranjena je u
                                    umjerenim područjima Azije i Europe. Mladi proljetni listovi mogu se jesti kuhani
                                    ili u salati. Mnoge vrste toga roda su jestive, a podaci o njihovu konzumiranju sežu
                                    čak do deset tisuća godina u povijest. Ipak, tradicionalno se ova vrsta ne konzumira
                                    na otoku Hvaru. Primorska pepeljuga na poluotoku Kabal zabilježena je u uvali
                                    Žukova, ali je možda ima i u drugim uvalama.
                                </p>
                            ),
                            video: null,
                            images: glavna_bumo_primorskaPepeljuga,
                        },
                        {
                            headingTitle: "Motar",
                            headingDescription: (
                                <p>
                                    Motar <i>(lat. Crithmum maritimum)</i> je niska trajnica mesnatog, zelenog i
                                    razgranatog stabalca s uspravnim, mesnatim, do 5 cm dugačkim listićima. Razvija
                                    cvjetiće žućkastozelene boje skupljene u gustim cvatovima. Cvate tijekom ljeta i
                                    jeseni. Raste iz procijepa stijena uz samo more, a često i na okomitim stranama
                                    starih obalnih građevina. Mesnati listovi su aromatični pa se od davnina
                                    upotrebljavaju kao hrana. U antici su ih ukiseljavali i koristili na plovidbama,
                                    premda pogrešno, protiv skorbuta. Motar i nema antiskorbutivna svojstva jer ne
                                    obiluje vitaminima. Na otoku Hvaru tradicionalno se, ali rijetko u kulinarstvu
                                    upotrebljavaju ukiseljeni ili prokuhani mladi listovi. Nekima je to posebno ukusna
                                    delicija.
                                </p>
                            ),
                            video: null,
                            images: glavna_bumo_motar,
                        },
                    ],
                },
                {
                    sectionTitle: "Lažina",
                    sectionDescription: (
                        <p>
                            Jedna od najpoznatijih dalmatinskih pjesama, „Galeb i ja“, počinje spominjanjem lažine:
                            „Lipo mi je, lipo mi je, na lažini suvoj ležat&apos;...“ Ali što je lažina? Na šljunkovitim
                            i pješčanim plažama, na mjestima do kojih dopiru valovi mora, tijekom jeseni se nakupljaju
                            listovi morske cvjetnice posidonije <i>(lat. Posidonia oceanica)</i>, a takve nakupine
                            zovemo lažina. Kad se osuše, smeđe su boje, a manje nakupine mogu se vidjeti na žalu u uvali
                            Glavna. Na poluotoku Kabal, ali i na cijelom Hvaru, nema uvala u kojima se lažina nakuplja u
                            velikim količinama. Na primjer, na otoku Silbi nakupine lažine mogu dosegnuti više od metra
                            visine. Na nekim se otocima lažina skupljala i upotrebljavala za izradu madraca i jastuka
                            ili za izolaciju krovova. Nakupine lažine posebno su važne na pjeskovitim plažama jer služe
                            kao zaštita od erozije obale tijekom jakih jesenskih i zimskih oluja.{" "}
                        </p>
                    ),
                    sectionImg: glavna_lazina_lazina,
                    headings: [
                        {
                            headingTitle: null,
                            headingDescription: null,
                            video: null,
                            images: [],
                        },
                    ],
                },
                {
                    sectionTitle: "Maskom i disalicom",
                    sectionDescription: null,
                    headings: [
                        MaskomDisalicomContentHR.padina,
                        MaskomDisalicomContentHR.klobucic,
                        MaskomDisalicomContentHR.posidonija,
                        MaskomDisalicomContentHR.promjenjivaSumporaca,
                        MaskomDisalicomContentHR.hridinskiJezinac,
                        MaskomDisalicomContentHR.crniJezinac,
                        MaskomDisalicomContentHR.ljubicastiJezinac,
                        MaskomDisalicomContentHR.crvenaZvjezdaca,
                        MaskomDisalicomContentHR.trpovi,
                        MaskomDisalicomContentHR.vlasulja,
                        MaskomDisalicomContentHR.crvenaMoruzgva,
                        MaskomDisalicomContentHR.busenastiKoralj,
                        MaskomDisalicomContentHR.nanar,
                        MaskomDisalicomContentHR.petrovoUho,
                        MaskomDisalicomContentHR.brbavica,
                        MaskomDisalicomContentHR.kozica,
                        MaskomDisalicomContentHR.kosmej,
                        MaskomDisalicomContentHR.rakSamac,
                        MaskomDisalicomContentHR.crnej,
                        MaskomDisalicomContentHR.fratar,
                        MaskomDisalicomContentHR.pirka,
                        MaskomDisalicomContentHR.knez,
                        MaskomDisalicomContentHR.usata,
                        MaskomDisalicomContentHR.pjevcic,
                        MaskomDisalicomContentHR.slingurice,
                    ],
                },
            ],
        },
        {
            id: 4,
            name: "Uvala Stupišće",
            path: "stupisce",
            pointId: 1383,
            description: null,
            sections: [
                {
                    sectionTitle: "Prstaci",
                    sectionDescription: null,
                    headings: [
                        {
                            headingTitle: null,
                            headingDescription: (
                                <p>
                                    Prstac <i>(lat. Lithophaga lithophaga)</i> je jedan od najčešćih školjkaša plitkoga
                                    stjenovitog dna. Vjerojatno je i najčešći oko rta Kabal. Premda može narasti do
                                    desetak centimetara dužine, u moru se rijetko može vidjeti, zato što živi u kamenu.
                                    Na površini od četvornog metra može ih biti više od tisuću. Smatra se da do
                                    maksimalnih dimenzija prstacu za rast treba više od 50 godina.
                                </p>
                            ),
                            video: null,
                            images: stupisce_p_prstac,
                        },
                        {
                            headingTitle: "Zaštita prstaca",
                            headingDescription: (
                                <p>
                                    Prstac je zakonom zaštićena vrsta, ali u povijesti je bio vrhunska delicija.
                                    Zaštićen je zato što se njegovim vađenjem uništava stjenovita obala, a ne zato što
                                    je ugrožena sama vrsta. Gotovo da ne postoji četvorni metar plitkog dna na kojem se
                                    prstac nije izlovljavao. To je posebno bilo izraženo na plitkom dnu poluotoka Kabal.
                                    Razbijanjem stijene uništeni su i svi organizmi koji su stijenu naseljavali: alge,
                                    spužve i žarnjaci, a s njima su nestale i brojne pokretne vrste, poput ribica,
                                    račića i mnogočetinaša. Umjesto njih naselili su se brojni ježinci koji ne dopuštaju
                                    ponovno naseljavanje algi, a s njima i obnovu biološke raznolikosti. Danas je oko 30
                                    % obale do tri metra dubine gotovo potpuno obrašteno ježincima, a njihovoj
                                    ekspanziji znatno je pridonijelo skupljanje prstaca. Ožiljci na stijenama bit će
                                    vidljivi još desetljećima, a dno ispod njih često je prekriveno oštrim kamenim
                                    krhotinama.
                                </p>
                            ),
                            video: null,
                            images: stupisce_p_zastitaPrstaca,
                        },
                        {
                            headingTitle: "Razmnožavanje prstaca",
                            headingDescription: (
                                <p>
                                    Prstaci se razmnožavaju potkraj ljeta. Svi prstaci nekog manjeg područja
                                    razmnožavaju se u isto vrijeme, pa more, zbog brojnosti ispuštenih reproduktivnih
                                    stanica, postane gotovo neprozirno. Biologija iznimno sinkroniziranog razmnožavanja
                                    prstaca otkrivena je baš na poluotoku Kabal.
                                </p>
                            ),
                            video: null,
                            images: stupisce_p_razmnPrstaca,
                        },
                    ],
                },
                {
                    sectionTitle: "Trpovi",
                    sectionDescription: null,
                    headings: [
                        {
                            headingTitle: null,
                            headingDescription: (
                                <p>
                                    U Jadranu je zabilježeno oko 30 vrsta trpova. Neki su sitni, tek oko 1 cm dugački,
                                    ali ih je većina između deset i trideset centimetara. Većina trpova guta sediment i
                                    iz njega izvlači organsku tvar. Smatra se da time imaju iznimno važnu ulogu u
                                    prozračivanju sedimenta. Neke vrste imaju i lovke, pa organske čestice skupljaju iz
                                    stupca mora.
                                </p>
                            ),
                            video: null,
                            images: stupisce_trp_trp,
                        },
                        {
                            headingTitle: "Razmnožavanje i zaštita trpova",
                            headingDescription: (
                                <p>
                                    Trpovi veći dio života provode ležeći na morskom dnu, osim kad se razmnožavaju. Tada
                                    svi potraže neko izdignuto mjesto i osove se na stražnji dio tijela kako bi što više
                                    u stupcu mora ispustili svoje reproduktivne strukture. U Hrvatskoj se trpovi ne
                                    konzumiraju, ali postoji velika potražnja za njima na azijskom tržištu. Zbog toga je
                                    devedesetih godina prošloga stoljeća došlo do njihova drastičnog prelova, pa je
                                    danas njihov lov zabranjen. Uvale poluotoka Kabal, pogotovo unutar Starogradskog
                                    zaljeva, bogate su trpovima. Nemojte ih dirati jer neke vrste kad su uznemirene
                                    ispuštaju dugačke bijele niti. Nisu opasne, ali su nevjerojatno ljepljive!
                                </p>
                            ),
                            video: null,
                            images: stupisce_trp_razmnZastita,
                        },
                    ],
                },
                {
                    sectionTitle: "Maskom i disalicom",
                    sectionDescription: null,
                    headings: [
                        MaskomDisalicomContentHR.padina,
                        MaskomDisalicomContentHR.klobucic,
                        MaskomDisalicomContentHR.posidonija,
                        MaskomDisalicomContentHR.promjenjivaSumporaca,
                        MaskomDisalicomContentHR.hridinskiJezinac,
                        MaskomDisalicomContentHR.crniJezinac,
                        MaskomDisalicomContentHR.trpovi,
                        MaskomDisalicomContentHR.vlasulja,
                        MaskomDisalicomContentHR.nanar,
                        MaskomDisalicomContentHR.volak,
                        MaskomDisalicomContentHR.kozica,
                        MaskomDisalicomContentHR.rakSamac,
                        MaskomDisalicomContentHR.knez,
                        MaskomDisalicomContentHR.pjevcic,
                        MaskomDisalicomContentHR.salpa,
                        MaskomDisalicomContentHR.martinka,
                        MaskomDisalicomContentHR.komarca,
                        MaskomDisalicomContentHR.trlja,
                        MaskomDisalicomContentHR.cipli,
                        MaskomDisalicomContentHR.slingurice,
                    ],
                },
            ],
        },
        {
            id: 3,
            name: "Uvala Tiha",
            path: "tiha",
            pointId: 972,
            description: null,
            sections: [
                {
                    sectionTitle: "Ugroženost biološke raznolikosti",
                    sectionDescription: null,
                    headings: [
                        {
                            headingTitle: null,
                            headingDescription: (
                                <>
                                    <p>
                                        Danas postoje brojne smetnje za biološku raznolikost podmorja. To se očituje u
                                        podmorju rta Kabal gdje najveća ugroza dolazi od nautičkog turizma, pretjeranog
                                        ribolova, skupljanja morskih organizama, morskog otpada i širenja stranih vrsta.
                                        Različite bolesti također drastično utječu na podmorski svijet, a za njihovo je
                                        širenje vjerojatno kriv i čovjek. Npr. školjkaš plemenita periska{" "}
                                        <i>(lat. Pinna nobilis)</i> doživio je drastičan pomor koji je zahvatio cijelo
                                        Sredozemlje, vjerojatno kao posljedica napada parazita koji se proširio morskim
                                        strujama. Do kraja 2023. u Hrvatskoj je preostalo tek desetak živih jedinki!
                                    </p>
                                </>
                            ),
                            video: null,
                            images: tiha_ubr_periska,
                        },
                        {
                            headingTitle: "Sidrenje",
                            headingDescription: (
                                <>
                                    <p>
                                        Danas je sidrenje sigurno najveća opasnost za naselja posidonije. Posidonija,
                                        zakonom zaštićena morska cvjetnica, raste iznimno sporo, tek oko 1 cm godišnje,
                                        a pojedine biljke počele su rasti prije više stotina godina. Svako sidrenje u
                                        posidoniji sigurno će počupati desetke, a možda i stotine biljaka, napraviti
                                        ožiljak u naselju posidonije koji se neće obnoviti sljedećih nekoliko desetaka
                                        godina. Tijekom nautičke sezone tisuće se brodova sidri u naseljima posidonije i
                                        svakodnevno uzrokuje dramatičnu štetu biološke raznolikosti podmorja.
                                    </p>
                                </>
                            ),
                            video: "/media/ugrozenost-video.mp4",
                            images: tiha_ubr_sidrenje,
                        },
                        {
                            headingTitle: "Pretjerani ribolov i sakupljanje morskih organizama",
                            headingDescription: (
                                <>
                                    <p>
                                        Prije pedeset godina podmorje rta Kabal izgledalo je potpuno drugačije. Bilo je
                                        bogato ribama, školjkašima, glavonošcima i algama. Riba kavala bila je česta
                                        vrsta. Danas je gotovo istrijebljena, kao i veliki primjerci kirnji.
                                        Desetljećima su komercijalni, sportski i ilegalni ribolovi prebrzo smanjivali
                                        biološka bogatstva podmorja. Skupljanje prstaca promijenilo je izgled obale,
                                        uništilo alge i uzrokovalo ekspanziju ježinaca. Turisti su iz mora vadili
                                        školjke, rakove i zvjezdače te ih kao suvenire odnosili kućama. Stotine i tisuće
                                        školjaka, zvjezdača i rakova izvađene su kao suveniri na poluotoku Kabal.
                                    </p>
                                </>
                            ),
                            video: null,
                            images: tiha_ubr_pretjeraniRibolov,
                        },
                        {
                            headingTitle: "Morski otpad",
                            headingDescription: (
                                <p>
                                    Slučajno ili namjerno, goleme količine otpada završavaju u moru. Najviše zabrinjava
                                    plastični otpad, koji se u moru raspada u manje fragmente, mikroplastiku, te se
                                    ugrađuje u planktonske organizme, a hranidbenim mrežama, konzumiranjem organizama iz
                                    mora, dospijeva i u čovjeka. Smatra se da oko 80 % morskog otpada dolazi s kopna,
                                    npr. rijekama, vjetrom ili kanalizacijom. Ostali dio posljedica je najviše
                                    ribolovnih aktivnosti i brodarstva, uključujući nautički turizam. Budući da
                                    plastični otpad često pluta, velik postotak otpada koji dospijeva do naših otoka
                                    inozemnog je porijekla, najčešće iz Albanije, Italije, Crne Gore i Grčke.
                                </p>
                            ),
                            video: null,
                            images: tiha_ubr_otpad,
                        },
                    ],
                },
                {
                    sectionTitle: "Strane vrste",
                    sectionDescription: (
                        <p>
                            Brojne strane vrste šire se Sredozemnim morem, pa tako i Jadranom. Unesene su balastnim
                            vodama, obraštajem brodova, ispuštanjem iz akvarija, bijegom iz marikulturnih uzgajališta
                            ili prolaskom kroz Sueski kanal iz Crvenoga mora. Mnoge su vrste otkrivene i oko rta Kabal.{" "}
                        </p>
                    ),
                    headings: [
                        {
                            headingTitle: (
                                <p>
                                    <i>Caulerpa taxifolia</i>, zelena alga
                                </p>
                            ),
                            headingDescription: (
                                <p>
                                    Vjerojatno najpoznatija strana vrsta, tzv. alga ubojica, porijeklom je iz
                                    Australije, a u Sredozemlje je dospjela ispuštanjem iz akvarija Oceanografskog
                                    muzeja u Monaku. Sredozemljem se proširila na sidrima brodova, a vjerojatno je tako
                                    dospjela i u Starogradski zaljev. Oko 2000. godine alga je prekrivala desetke
                                    hektara dna, razvijajući se na stjenovitoj i pjeskovitoj podlozi. Prijenos
                                    fragmenata ribarskim mrežama proširio ju je na više lokacija na poluotoku Kabal.
                                    Ipak, 2007. godine alga se drastično povukla, vjerojatno kao posljedica biljne
                                    bolesti koja se proširila na svim lokalitetima u Sredozemlju. Posljednji primjerci u
                                    Starogradskom zaljevu zabilježeni su 2020. godine.
                                </p>
                            ),
                            video: null,
                            images: tiha_sv_taxifolia,
                        },
                        {
                            headingTitle: (
                                <p>
                                    <i>Caulerpa cylindracea</i>, zelena alga
                                </p>
                            ),
                            headingDescription: (
                                <p>
                                    Alga je porijeklom iz Australije, ali način unosa u Sredozemno more nije poznat.
                                    Širi se morskim strujama pa je tako dospjela i u Jadran, gdje je prvi put
                                    zabilježena 2000. godine na Paklenim otocima. Povremeno razvija iznimno gusta
                                    naselja, pri čemu drastično smanjuje biološku raznolikost. Gusta naselja posebno su
                                    česta na dubinama između 30 i 50 metara. U Starogradskom zaljevu zabilježena je na
                                    više mjesta, ali nije uzrokovala veće probleme.
                                </p>
                            ),
                            video: null,
                            images: tiha_sv_cylindracea,
                        },
                        {
                            headingTitle: (
                                <p>
                                    <i>Melibe viridis</i>, puž golać
                                </p>
                            ),
                            headingDescription: (
                                <p>
                                    Prvi je put u Jadranu taj petnaestak centimetara dugački puž golać nađen u
                                    Starogradskom zaljevu. Otkriven je 2001. godine i od tada se redovito pronalazi, ali
                                    uvijek u malom broju primjeraka. Puž potječe iz Crvenoga mora, a kao ličinka se
                                    raznosi morskim strujama. Danju miruje, ali je aktivan noću, kad se hrani malim
                                    beskralježnjacima koje lovi golemim ustima na kojima su brojne dlačice za skupljanje
                                    sitnog plijena s algi i morskih cvjetnica. Sličan je algi pa ga se teško uočava.
                                </p>
                            ),
                            video: null,
                            images: tiha_sv_melibe,
                        },
                        {
                            headingTitle: (
                                <p>
                                    <i>Aplysia dactilomella</i>, puž golać
                                </p>
                            ),
                            headingDescription: (
                                <p>
                                    <i>Aplysia dactylomela</i> je biljojedni puž golać naziva crnokrugi zekan.
                                    „Crnokrugi“ je zato što na tijelu ima jasno vidljive crne krugove, a „zekan“ jer
                                    pripada skupini puževa golaća koji se zovu zekani. Najveće jedinke dugačke su oko 40
                                    cm. Boja tijela varira od svjetlozelene do tamnosmeđe, ali najčešće su svjetlosmeđi.
                                    Vrsta je najvjerojatnije porijeklom iz Atlantika, a prvi put su nađeni u Sredozemlju
                                    2002. godine u podmorju Lampeduze. U Hrvatskoj je crnokrugi zekan prvi put
                                    zabilježen 2006. godine pokraj otoka Sušca, a potom na Mljetu. Od 2012. godine toga
                                    se puža pronalazi sve češće na području srednjeg i južnog Jadrana, a zabilježen je i
                                    na rtu Kabal. Najčešće se zadržava između površine i dubine od dva metra, gdje se
                                    hrani algama. Puž među algama ostavlja nakupine jajašaca u obliku zapetljanog
                                    konopčića iz kojih izlazi planktonska ličinka. Ličinka u planktonu, nošena morskim
                                    strujama, može provesti oko mjesec dana prije nego što se spusti na dno i preobrazi
                                    u odraslog puža.
                                </p>
                            ),
                            video: null,
                            images: tiha_sv_straniPuzGolac,
                        },
                    ],
                },
                {
                    sectionTitle: "Maskom i disalicom",
                    sectionDescription: null,
                    headings: [
                        MaskomDisalicomContentHR.padina,
                        MaskomDisalicomContentHR.cvorastaMorskaResa,
                        MaskomDisalicomContentHR.trpovi,
                        MaskomDisalicomContentHR.vlasulja,
                        MaskomDisalicomContentHR.nanar,
                        MaskomDisalicomContentHR.volak,
                        MaskomDisalicomContentHR.brbavica,
                        MaskomDisalicomContentHR.kozica,
                        MaskomDisalicomContentHR.rakSamac,
                        MaskomDisalicomContentHR.knez,
                        MaskomDisalicomContentHR.pjevcic,
                        MaskomDisalicomContentHR.salpa,
                        MaskomDisalicomContentHR.martinka,
                        MaskomDisalicomContentHR.komarca,
                        MaskomDisalicomContentHR.trlja,
                        MaskomDisalicomContentHR.cipli,
                        MaskomDisalicomContentHR.slingurice,
                    ],
                },
            ],
        },
        {
            id: 0,
            name: "Uvala Zavala",
            path: "zavala",
            pointId: 0,
            description: null,
            sections: [
                {
                    sectionTitle: "Naselja posidonije",
                    sectionDescription: null,
                    headings: [
                        {
                            headingTitle: null,
                            headingDescription: (
                                <p>
                                    Naselja posidonije gradi morska cvjetnica <i>Posidonia oceanica</i>. Često je cijelo
                                    naselje koje se proteže stotinama metara duž obale nastalo rastom samo jedne biljke
                                    koja je tu dospjela prije više tisuća godina. Rast njezina stabalca bio je iznimno
                                    spor, tek centimetar godišnje ako je rasla okomito, te možda 5 cm godišnje ako je
                                    rasla vodoravno. Zbog toga što sporo rastu, stabalca stalno zatrpava sediment pa su
                                    veći dio svoje dužine ukopana. Mjestimice se na podmorskim odronima vide stabalca
                                    koja su dugačka nekoliko metara, no takva su stara više stotina godina. Posidonija
                                    je zapravo najdugovječniji plitkovodni organizam u Sredozemnome moru. Ujedno je i
                                    sredozemni endem.
                                </p>
                            ),
                            video: "/media/posidonija-video.mp4",
                            images: zavala_np_stabalcaPosidonije,
                        },
                        {
                            headingTitle: null,
                            headingDescription: (
                                <p>
                                    Posidonija se ubraja u trave, a kao i trave, građena je od stabalca, listova i
                                    korijenja. Kao i trave na kopnu, razmnožava se cvatom. Pelud prenose morske struje,
                                    a nakon oplodnje razvija se plodić sličan maslini. Posidonija cvate ujesen, a
                                    plodići sazrijevaju u proljeće. Tada se otkidaju od stapke, isplivaju na površinu te
                                    plutaju nekoliko dana. Potom se raspuknu, a iz njih ispadne sjemenka koja tone na
                                    dno. Potone li na pogodno dno, razvit će se u novu posidoniju. Za deset godina
                                    njezino stabalce bit će dugačko tek pola metra. Nastavi li rast, u više stotina
                                    godina iz nje će se razviti malo naselje posidonije koje će se protezati od površine
                                    do oko 30 m dubine, gdje ima dovoljno svjetla za rast.{" "}
                                </p>
                            ),
                            video: null,
                            images: zavala_np_razmn,
                        },
                        {
                            headingTitle: null,
                            headingDescription: (
                                <p>
                                    Naselja posidonije su vrlo važna. To su područja najveće biološke raznolikosti u
                                    Sredozemnome moru. Njezini listovi pročišćuju vodeni stupac i smanjuju energiju
                                    valova koji udaraju o obalu. Naselja sprječavaju eroziju dna, a otpali listovi koje
                                    more izbaci na obalu ujesen, sprječavaju eroziju pješčanih plaža. Posidonija
                                    proizvodi veliku količinu organske tvari, a njezina se naselja smatraju plućima
                                    plitkoga dna, jer bitno pridonosi proizvodnji kisika. U dugoživućim stabalcima
                                    pohranjuje se atmosferski ugljik te se tako djeluje na smanjenje globalnog
                                    zagrijavanja.
                                </p>
                            ),
                            video: null,
                            images: zavala_np_nakupineListova,
                        },
                        {
                            headingTitle: null,
                            headingDescription: (
                                <p>
                                    Naselja posidonije mjesta su zadržavanja i mrijesta brojnih komercijalnih vrsta
                                    riba. Riba modrak ili lokalno tragalj <i>(lat. Spicara maena)</i>, mrijesti se u
                                    posidoniji. Tisuće primjeraka mogu se skupiti u jednoj uvali kako bi se
                                    razmnožavale. Mužjaci će iskopati rupe između čuperaka posidonije, u koje će ženke
                                    položiti jaja.
                                </p>
                            ),
                            video: null,
                            images: zavala_np_mrijest,
                        },
                    ],
                },
                {
                    sectionTitle: "Ugroženost posidonije",
                    sectionDescription: null,
                    headings: [
                        {
                            headingTitle: null,
                            headingDescription: (
                                <p>
                                    Posidoniju danas ugrožavaju mnoge ljudske aktivnosti. Smatra se da je u posljednjih
                                    50 godina došlo do smanjenja naselja posidonije za više od 30 % na razini cijeloga
                                    Sredozemlja. Posidonija je vrlo osjetljiva na svako uništavanje zbog iznimno sporog
                                    rasta i nemogućnosti brze regeneracije oštećenih površina. Onečišćenje mora dovodi
                                    do smanjenja prozirnosti, pa posidonija u dubljim dijelovima naselja nema dovoljno
                                    svjetla za fotosintezu. Nasipanja obale i stanogradnja izravno uništavaju njezine
                                    livade, a pojačana sedimentacija može zatrpati spororastuća stabalca. Koćarenje je
                                    desetkovalo njezina naselja u nekim područjima Sredozemlja. U Hrvatskoj je sidrenje
                                    svakako najveća prijetnja za posidoniju, jer će sigurno uništiti desetke ili stotine
                                    biljaka te uzrokovati štetu za koju je potrebno desetljeće da se ukloni. Da, tako,
                                    tisuće brodova svakodnevno se sidre tijekom nautičke sezone, oštećujući livade. Na
                                    mnogim mjestima intenzivnog sidrenja posidonija je potpuno uništena.
                                </p>
                            ),
                            video: null,
                            images: zavala_np_ugroženostPosidonije,
                        },
                    ],
                },
                {
                    sectionTitle: "Ribolov u naseljima posidonije ",
                    sectionDescription: null,
                    headings: [
                        {
                            headingTitle: null,
                            headingDescription: (
                                <>
                                    <p>
                                        Na Hvaru se u naseljima posidonije riba lovi različitim mrežama, ali najčešće
                                        povlačnom mrežom migavicom, koja je tako nazvana jer se njezinim potezanjem
                                        mrežna oka stežu i otvaraju, što nalikuje na namigivanje. Ciljana vrsta ribe
                                        koja se lovi je gira oblica, ali se ulove i mnoge druge komercijalno zanimljive
                                        vrste, poput komarče, fratra, kovača ili zubatca. Migavicom se zahvati cijela
                                        uvala te se poteže do njezina najplićeg dijela.
                                    </p>
                                    <p>
                                        Gdje ima gira, ima i liganja koje se njima hrane. Zato je lov liganja
                                        varalicama, tradicionalno uz pomoć tzv. peškafonda, uobičajen na naseljima
                                        posidonije.
                                    </p>
                                </>
                            ),
                            video: null,
                            images: [],
                        },
                    ],
                },
                {
                    sectionTitle: "Maskom i disalicom",
                    sectionDescription: null,
                    headings: [
                        MaskomDisalicomContentHR.padina,
                        MaskomDisalicomContentHR.posidonija,
                        MaskomDisalicomContentHR.cvorastaMorskaResa,
                        MaskomDisalicomContentHR.promjenjivaSumporaca,
                        MaskomDisalicomContentHR.hridinskiJezinac,
                        MaskomDisalicomContentHR.crniJezinac,
                        MaskomDisalicomContentHR.crvenaZvjezdaca,
                        MaskomDisalicomContentHR.trpovi,
                        MaskomDisalicomContentHR.nanar,
                        MaskomDisalicomContentHR.petrovoUho,
                        MaskomDisalicomContentHR.kozica,
                        MaskomDisalicomContentHR.rakSamac,
                        MaskomDisalicomContentHR.knez,
                        MaskomDisalicomContentHR.pjevcic,
                        MaskomDisalicomContentHR.salpa,
                        MaskomDisalicomContentHR.martinka,
                        MaskomDisalicomContentHR.komarca,
                        MaskomDisalicomContentHR.trlja,
                        MaskomDisalicomContentHR.cipli,
                        MaskomDisalicomContentHR.slingurice,
                    ],
                },
            ],
        },
        {
            id: 1,
            name: "Uvala Žukova",
            path: "zukova",
            pointId: 70,
            description: null,
            sections: [
                {
                    sectionTitle: "Komarča i crv",
                    sectionDescription: null,
                    headings: [
                        {
                            headingTitle: "Veliki morski crv",
                            headingDescription: (
                                <>
                                    <p>
                                        Starogradski ribari oduvijek su lovili komarče (orade) na udicu, a glavni im je
                                        mamac bio veliki morski crv. Znanstvenog naziva <i>Eunice roussaei</i>, veliki
                                        morski crv najveći je među „crvima“ (stručan naziv za njih je mnogočetinaši)
                                        koji žive u Sredozemnome moru. Zabilježeno je da je jedan primjerak ulovljen u
                                        Francuskoj bio dugačak šest metara. Ta vrsta živi ukopana u sediment i nikad
                                        posve ne napušta rupu. Vrlo je plah i čim osjeti opasnost, povući će se u svoju
                                        rupu. O njegovoj biologiji zna se vrlo malo. Živi na pjeskovito-stjenovitim
                                        dnima, najčešće od površine do desetak metara dubine. Vjerojatno je strvinar i
                                        svejed. U uvalama rta Kabal je vrlo čest, a lokalni ga ribolovci dobro poznaju
                                        jer je najbolji mamac za lov ribe na udicu.
                                    </p>
                                    <p>
                                        Danas se lovi trapulama, lovkama koje rade kao mišolovke. Trapule se na rupe
                                        crva postavljaju ronjenjem na dah. U povijesti se lovilo ostima, noću „pod
                                        sviću“ (umjetno svjetlo, obično plinsko). Ribar bi u more bacio neki mirisni
                                        mamac, tzv. abrum (obično usitnjene slane srdele) i pod svjetlom bi gledao gdje
                                        će crv izviriti iz rupe. Crva bi se pogodilo malim, posebno napravljenim ostima.
                                    </p>
                                    <p>
                                        Da bi se crv izvukao iz rupe, mora se na trapulu zakvačiti mala boca sa zrakom,
                                        koja crva polako izvlači iz sedimenta. Najteže je pronaći rupu s crvom. Ako se
                                        roni na dah, potrebno je dugotrajno iskustvo da bi se prema čudno položenoj
                                        školjci, kamenčiću ili komadiću morske trave prepoznala takva rupa.{" "}
                                    </p>
                                </>
                            ),
                            video: null,
                            images: zukova_kic_velikiMorskiCrv,
                        },
                        {
                            headingTitle: "Komarča",
                            headingDescription: (
                                <p>
                                    Komarča <i>(lat. Sparus aurata)</i> je samo jedan od tridesetak naziva za tu ribu na
                                    hrvatskoj obali. Najčešće se naziva orada. To je srebrnkasto-siva izdužena riba, s
                                    tamnom mrljom iza glave. Ima jake čeljusti kojima može razbiti školjku, kućicu puža
                                    ili ježinca. Dvospolac je; prvi dio života je mužjak, a kad prijeđe težinu od 0,5
                                    kg, mijenja spol i postaje ženka. U uvalama rta Kabal je česta, a tradicionalno se
                                    lovi na udicu, uz pomoć crva kao mamca. Gastronomska je delicija, posebno one koje
                                    su ulovljene u prirodi. Naime, danas je komarča jedna od glavnih vrsta koje se
                                    uzgajaju u ribogojilištima. Brojnost u prirodi znatno joj je porasla zbog
                                    prebjegavanja iz uzgajališta, kao i zbog uspješnog mrijesta u uzgajalištima, odakle
                                    se ličinke dalje raznose morskim strujama.
                                </p>
                            ),
                            video: null,
                            images: zukova_kic_komarca,
                        },
                    ],
                },
                {
                    sectionTitle: "Otisci dinosaura",
                    sectionDescription: null,
                    headings: [
                        {
                            headingTitle: null,
                            headingDescription: (
                                <p>
                                    Na zapadnom dijelu uvale Žukova, između mora i zone s vegetacijom, nalazi se deset
                                    elipsastih otisaka dinosaura, dugačkih gotovo metar. Riječ je o sauropodima,
                                    dinosaurima biljojedima. Procjenjuje se da su bili dugački između 19 i 28 m te su
                                    nastanjivali ovo područje prije 90 milijuna godina, kad je tu bila slatka voda i
                                    bujna vegetacija.
                                </p>
                            ),
                            video: null,
                            images: zukova_od_otisciDinosaura,
                        },
                    ],
                },
                {
                    sectionTitle: "Maskom i disalicom",
                    sectionDescription: null,
                    headings: [
                        MaskomDisalicomContentHR.padina,
                        MaskomDisalicomContentHR.klobucic,
                        MaskomDisalicomContentHR.posidonija,
                        MaskomDisalicomContentHR.promjenjivaSumporaca,
                        MaskomDisalicomContentHR.hridinskiJezinac,
                        MaskomDisalicomContentHR.crniJezinac,
                        MaskomDisalicomContentHR.crvenaZvjezdaca,
                        MaskomDisalicomContentHR.trpovi,
                        MaskomDisalicomContentHR.nanar,
                        MaskomDisalicomContentHR.volak,
                        MaskomDisalicomContentHR.kozica,
                        MaskomDisalicomContentHR.kosmej,
                        MaskomDisalicomContentHR.rakSamac,
                        MaskomDisalicomContentHR.crnej,
                        MaskomDisalicomContentHR.pirka,
                        MaskomDisalicomContentHR.knez,
                        MaskomDisalicomContentHR.pjevcic,
                        MaskomDisalicomContentHR.salpa,
                        MaskomDisalicomContentHR.martinka,
                        MaskomDisalicomContentHR.komarca,
                        MaskomDisalicomContentHR.trlja,
                        MaskomDisalicomContentHR.cipli,
                        MaskomDisalicomContentHR.slingurice,
                    ],
                },
            ],
        },
        {
            id: 2,
            name: "Uvala Vela Travna",
            path: "vela_travna",
            pointId: 662,
            description: null,
            sections: [
                {
                    sectionTitle: "Uvala",
                    sectionDescription: null,
                    headings: [],
                    sectionImg: travna_uvala_uvala,
                },
                {
                    sectionTitle: "Supralitoralne stijene",
                    sectionDescription: null,
                    headings: [
                        {
                            headingTitle: null,
                            headingDescription: (
                                <>
                                    <p>
                                        Supralitoral je područje obale neposredno iznad mora, na koji more djeluje samo
                                        prskanjem i razlijevanjem valova. Supralitoralnu zonu na stjenovitoj obali
                                        prepoznajemo po sivkasto-zelenkastoj obojenosti. Na mjestima pod utjecajem jačih
                                        valova, na primjer na rtu Kabal, ta zona može biti visoka dva do četiri metra. U
                                        uvali Travna doseže oko jedan metar iznad mora.
                                    </p>
                                    <p>
                                        Supralitoral je područje ekstremnih uvjeta, visokih temperatura, dugotrajne suše
                                        te utjecaja i mora i slatke vode. Zato ga naseljava mali broj vrsta.
                                        Sivkasto-zelenkasta obojenost nastaje od mikroskopski sitnih modrozelenih alga
                                        koje žive u kamenu. Od životinja, tu možemo pronaći samo četiri vrste.
                                    </p>
                                </>
                            ),
                            video: null,
                            images: travna_ss_supralitoral,
                        },
                        {
                            headingTitle: "Mali obalni pužić",
                            headingDescription: (
                                <p>
                                    Obalni pužić <i>(lat. Melarhaphe neritoides)</i> evolucijski je prilagođen
                                    ekstremnim uvjetima. Hrani se stružući modrozelene alge tijekom noći ili pri
                                    prskanju i zalijevanju valova. Za sušnoga vremena i visokih temperatura sakrije se u
                                    pukotine stijena.{" "}
                                </p>
                            ),
                            video: null,
                            images: travna_ss_obalniPuzic,
                        },
                        {
                            headingTitle: "Rak vitičar",
                            headingDescription: (
                                <p>
                                    Rak vitičar <i>(lat. Euraphia depressa)</i> sesilni je organizam. Čvrsto je zatvoren
                                    ljuskama i poklopcem i tako odolijeva sušnim uvjetima. Kad val zapljusne obalu,
                                    račić ispruži noge s dlačicama kojima lovi planktonske organizme u moru koje se
                                    slijeva preko kamena.
                                </p>
                            ),
                            video: null,
                            images: travna_ss_rakViticar,
                        },
                        {
                            headingTitle: "Izopodni račić babura",
                            headingDescription: (
                                <p>
                                    Izopodni račić babura <i>(lat. Ligia italica)</i> dugačak je oko 1 cm i živi iznad
                                    razine mora. Sivo-zelenkaste je boje i velikih očiju. Ima sedam pari nogu za
                                    hodanje. Vrlo je brz i često brojan. Hrani se ostacima algi i cijanobakterija. Noću
                                    se skriva u pukotinama kamenja.
                                </p>
                            ),
                            video: null,
                            images: travna_ss_racicBabura,
                        },
                    ],
                },
                {
                    sectionTitle: "Žalo i morske spužve",
                    sectionDescription: (
                        <p>
                            Žalo i oblutci na plažama nastaju kad se valjanjem morskih valova oblikuje kamenje. Međutim,
                            pogledamo li detaljnije oblutke na plaži, na mnogima ćemo primijetiti brojne pravilo
                            raspoređene kanale i rupice. U većini slučajeva, njih su napravile tzv. kamotočne morske
                            spužve. Dok je kamen bio u moru, u njega su se naselile kamotočne spužve i razvile mrežu
                            svojega tkiva unutar kamena. Kad kamen valovima dospije na obalu, spužve uginu, ali ostaju
                            kanalići i rupice, uspomene na spužve koje su u njima živjele.
                        </p>
                    ),
                    headings: [
                        {
                            headingTitle: null,
                            headingDescription: null,
                            video: null,
                            images: travna_zims_zaloSpuzve,
                        },
                    ],
                },
                {
                    sectionTitle: "Maskom i disalicom",
                    sectionDescription: null,
                    headings: [
                        MaskomDisalicomContentHR.padina,
                        MaskomDisalicomContentHR.posidonija,
                        MaskomDisalicomContentHR.hridinskiJezinac,
                        MaskomDisalicomContentHR.crniJezinac,
                        MaskomDisalicomContentHR.ljubicastiJezinac,
                        MaskomDisalicomContentHR.crvenaZvjezdaca,
                        MaskomDisalicomContentHR.trpovi,
                        MaskomDisalicomContentHR.busenastiKoralj,
                        MaskomDisalicomContentHR.nanar,
                        MaskomDisalicomContentHR.kozica,
                        MaskomDisalicomContentHR.rakSamac,
                        MaskomDisalicomContentHR.knez,
                        MaskomDisalicomContentHR.pjevcic,
                        MaskomDisalicomContentHR.salpa,
                        MaskomDisalicomContentHR.slingurice,
                    ],
                },
            ],
        },
    ],
};

export default ContentHR;
