/* eslint-disable indent */
import React, { useState, useEffect, useRef } from "react";
import * as d3 from "d3";
import { geoIdentity } from "d3";
import { useTranslation } from "react-i18next";

import dinosaur_icon from "assets/dinosaur_icon.svg";
import sun_icon from "assets/sun_icon.svg";
import fish_icon from "assets/fish_icon.svg";
import shell_icon from "assets/shell_icon.svg";
import crab_icon from "assets/crab_icon.svg";
import dolphin_icon from "assets/dolphin_icon.svg";
import sea_star_icon from "assets/sea_star_icon.svg";
import urchin_icon from "assets/urchin_icon.svg";
/* import foot_access from "assets/foot_access.svg";
import boat_access from "assets/boat_access.svg"; */
import ContentHR from "./ContentHR";

// Simplify places for filtering
const places = ContentHR.places.map((place) => {
    return {
        id: place.id,
        path: place.path,
        pointId: place.pointId,
    };
});

const Map = ({ mapContainerSize, baseMap, turtle, currentSection }) => {
    const effectRan = useRef(false); // Handle double rerender in strictmode

    const { t } = useTranslation();

    const dimensions = mapContainerSize;
    const [svgRef, setSvgRef] = useState();
    const [data, setData] = useState();
    const [previousSection, setPreviousSection] = useState("");
    const [turtlePosition, setTurtlePosition] = useState();

    const diagramRef = useRef();

    // Zoom not currently used!
    function zoomed(event) {
        const { transform } = event;
        d3.select(".map-svg").attr("transform", transform);
    }

    const zoom = d3.zoom().scaleExtent([0.5, 10]).on("zoom", zoomed);

    let projection;

    const loadData = async () => {
        const route = await d3.json("./data/ruta.geojson");
        const points = await d3.json("./data/uvale.geojson");
        const bounds = await d3.json("./data/bounds.geojson");
        const glavna = await d3.json("./data/rute/glavna.geojson");
        const lozna = await d3.json("./data/rute/lozna.geojson");
        const stupisce = await d3.json("./data/rute/stupisce.geojson");
        const tiha = await d3.json("./data/rute/tiha.geojson");
        const vela_travna = await d3.json("./data/rute/vela_travna.geojson");
        const zavala = await d3.json("./data/rute/zavala.geojson");
        const zukova = await d3.json("./data/rute/zukova.geojson");
        setData({
            route: route,
            points: points,
            bounds: bounds,
            finalRoutes: {
                glavna: glavna,
                lozna: lozna,
                stupisce: stupisce,
                tiha: tiha,
                vela_travna: vela_travna,
                zavala: zavala,
                zukova: zukova,
            },
        });
    };

    const loadMap = async () => {
        let { route, points, bounds } = data;

        let svg = d3
            .select(diagramRef.current)
            .append("svg")
            .attr("id", "map-svg")
            .attr("width", dimensions.width)
            .attr("height", dimensions.height)
            .attr("class", "main-svg");

        projection = geoIdentity().reflectY(true).fitSize([dimensions.width, dimensions.height], bounds);

        const g = svg.append("g").attr("class", "wrapper").attr("id", "map-container").style("overflow", "visible");

        g.append("image")
            .attr("xlink:href", baseMap)
            .attr("class", "raster")
            .attr("width", "100%")
            .attr("height", "100%");

        const generatePath = d3
            .line()
            .x(function (d) {
                return projection(d.geometry.coordinates)[0];
            })
            .y(function (d) {
                return projection(d.geometry.coordinates)[1];
            })
            .curve(d3.curveCardinal);

        // scroll into view on point click
        const clickedPoint = (e) => {
            let target = e.target?.__data__.properties.path;
            if (target) {
                //console.log(window.innerWidth);
                if (window.innerWidth <= 840) {
                    const element = document.getElementById(target);
                    const elementPosition = element.getBoundingClientRect().top;
                    const mapElementHeight = document
                        .getElementById("main-map-container")
                        .getBoundingClientRect().height;
                    const offsetPosition = elementPosition + window.pageYOffset - mapElementHeight;

                    window.scrollTo({
                        top: offsetPosition,
                        behavior: "smooth",
                    });
                } else {
                    document.getElementById(target).scrollIntoView({ behavior: "smooth" });
                }
            }
        };

        // Main route - always shown!
        let routeInstance = g
            .append("g")
            .append("g")
            .attr("class", "route-points")
            .selectAll("g")
            .attr("class", "route")
            .data(route.features)
            .enter()
            .append("g");

        routeInstance
            .append("circle")
            .attr("class", "route-circle")
            .attr("cx", function (d) {
                return projection(d.geometry.coordinates)[0];
            })
            .attr("cy", function (d) {
                return projection(d.geometry.coordinates)[1];
            })
            .attr("r", mapContainerSize.width <= 600 ? 1 : 2)
            .style("fill", "#FEFEFE")
            .style("opacity", 0.8);

        // Final path - shown based on scroll
        const gPathFinal = g
            .append("g")
            .attr("class", "path-final")
            .attr("width", dimensions.width)
            .attr("height", dimensions.height)
            .append("path")
            .datum(route.features)
            .style("stroke", "#3BAD95")
            .style("stroke-width", 5);

        const gPoints = g.append("g").attr("class", "points");
        // Point objects (uvale)
        let pointInstance = gPoints
            .append("g")
            .attr("class", "map-label map-label--road")
            .selectAll("g")
            .data(points.features)
            .enter()
            .append("g")
            .on("click", clickedPoint)
            .style("cursor", "pointer");

        let pointSymbol = pointInstance.append("g").attr("class", "point-symbol-group");

        // Cove Icon outer circle
        pointSymbol
            .append("circle")
            .attr("class", "outer-circle")
            .attr("cx", function (d) {
                return projection(d.geometry.coordinates)[0];
            })
            .attr("cy", function (d) {
                return projection(d.geometry.coordinates)[1];
            })
            .attr("r", mapContainerSize.width <= 600 ? 10 : 15) // 15
            .style("fill", "#FEFEFE")
            .style("opacity", 0.5);

        // Cove Icon outer circle
        pointSymbol
            .append("circle")
            .attr("class", "inner-circle")
            .attr("cx", function (d) {
                return projection(d.geometry.coordinates)[0];
            })
            .attr("cy", function (d) {
                return projection(d.geometry.coordinates)[1];
            })
            .attr("r", mapContainerSize.width <= 600 ? 8 : 12)
            .style("fill", "#FFFFFF");

        // Cove Icon infill svg icon
        pointSymbol
            .append("svg:image")
            .attr("class", "image-symbol")
            .attr("xlink:href", function (d) {
                switch (d.properties.icon) {
                    case "dinosaur_icon":
                        return dinosaur_icon;

                    case "shell_icon":
                        return shell_icon;

                    case "sun_icon":
                        return sun_icon;

                    case "fish_icon":
                        return fish_icon;

                    case "crab_icon":
                        return crab_icon;

                    case "dolphin_icon":
                        return dolphin_icon;

                    case "sea_star_icon":
                        return sea_star_icon;

                    case "urchin_icon":
                        return urchin_icon;

                    default:
                        return sun_icon;
                }
            })
            .attr("width", mapContainerSize.width <= 600 ? "13px" : "20px")
            .attr("height", mapContainerSize.width <= 600 ? "13px" : "20px")
            .attr("x", function (d) {
                let coord = projection(d.geometry.coordinates)[0];
                return mapContainerSize.width <= 600 ? coord - 6.5 : coord - 10;
            })
            .attr("y", function (d) {
                let coord = projection(d.geometry.coordinates)[1];
                return mapContainerSize.width <= 600 ? coord - 6.5 : coord - 10;
            });

        // Add foot access icon
        /* pointSymbol
            .append("svg:image")
            .attr("class", "foot-access-symbol")
            .attr("xlink:href", function (d) {
                return d.properties.footAccess ? foot_access : null;
            })
            .attr("width", mapContainerSize.width <= 600 ? "9px" : "14px")
            .attr("height", mapContainerSize.width <= 600 ? "9px" : "14px")
            .attr("x", function (d) {
                let coord = projection(d.geometry.coordinates)[0];
                return mapContainerSize.width <= 600 ? coord + 10 : coord + 18;
            })
            .attr("y", function (d) {
                let coord = projection(d.geometry.coordinates)[1];
                return mapContainerSize.width <= 600 ? coord - 10 : coord - 18;
            });

        // Add boat access icon
        pointSymbol
            .append("svg:image")
            .attr("class", "boat-access-symbol")
            .attr("xlink:href", function (d) {
                return d.properties.boatAccess ? boat_access : null;
            })
            .attr("width", mapContainerSize.width <= 600 ? "9px" : "14px")
            .attr("height", mapContainerSize.width <= 600 ? "9px" : "14px")
            .attr("x", function (d) {
                // Check if point contains both access methods
                let coord = projection(d.geometry.coordinates)[0];
                if (d.properties.footAccess && d.properties.boatAccess) {
                    return mapContainerSize.width <= 600 ? coord + 10 : coord + 18;
                }
                return mapContainerSize.width <= 600 ? coord + 10 : coord + 18;
            })
            .attr("y", function (d) {
                let coord = projection(d.geometry.coordinates)[1];
                if (d.properties.footAccess && d.properties.boatAccess) {
                    return projection(d.geometry.coordinates)[1] - 0;
                }
                return projection(d.geometry.coordinates)[1] - 18;
            });
        */

        // Point text labels (names)
        pointInstance
            .append("text")
            .attr("x", function (d) {
                let coord = projection([d.geometry.coordinates[0], d.geometry.coordinates[1]])[0];
                // Adjust custom position of text labels
                // Left and right
                let path = d.properties.path;
                let screenW = mapContainerSize.width;
                switch (true) {
                    // Zavala
                    case path === "zavala":
                        coord -= 40;
                        break;

                    // Zukova
                    case path === "zukova" && screenW <= 450:
                        coord -= 60;
                        break;
                    case path === "zukova" && screenW <= 600:
                        coord -= 60;
                        break;
                    case path === "zukova":
                        coord -= 80;
                        break;

                    // Tiha
                    case path === "tiha" && screenW <= 450:
                        coord -= 10;
                        break;
                    case path === "tiha":
                        coord -= 40;
                        break;

                    // Stupisce
                    case path === "stupisce" && screenW <= 450:
                        coord -= 50;
                        break;
                    case path === "stupisce":
                        coord -= 40;
                        break;

                    // Glavna
                    case path === "glavna" && screenW <= 450:
                        coord -= 50;
                        break;
                    case path === "glavna":
                        coord -= 40;
                        break;

                    // Lozna
                    case path === "lozna" && screenW <= 600:
                        coord -= 60;
                        break;
                    case path === "lozna":
                        coord -= 40;
                        break;

                    default:
                        break;
                }
                return coord;
            })
            .attr("dy", function (d) {
                // Up and down
                let coord = projection([d.geometry.coordinates[0], d.geometry.coordinates[1]])[1];
                let path = d.properties.path;
                let screenW = mapContainerSize.width;
                switch (true) {
                    // zavala
                    case path === "zavala":
                        coord += 28;
                        break;

                    // Zukova
                    case path === "zukova" && screenW <= 600:
                        coord -= 20;
                        break;
                    case path === "zukova":
                        coord -= 20;
                        break;

                    // Tiha
                    case path === "tiha" && screenW <= 450:
                        coord += 20;
                        break;
                    case path === "tiha" && screenW <= 1200:
                        coord += 30;
                        break;
                    case path === "tiha":
                        coord -= 20;
                        break;

                    // Stupisce
                    case path === "stupisce" && screenW <= 450:
                        coord += 25;
                        break;
                    case path === "stupisce" && screenW <= 1200:
                        coord += 30;
                        break;
                    case path === "stupisce":
                        coord -= 20;
                        break;

                    // Vela travna
                    case path === "vela_travna":
                        coord -= 20;
                        break;

                    // Glavna
                    case path === "glavna":
                        coord -= 20;
                        break;

                    // Lozna
                    case path === "lozna" && screenW <= 600:
                        coord -= 20;
                        break;
                    case path === "lozna" && screenW <= 1200:
                        coord -= 20;
                        break;
                    case path === "lozna":
                        coord -= 20;
                        break;

                    default:
                        break;
                }
                return coord;
            })
            .text(function (d) {
                return t(d.properties.Name);
            })
            .style("fill", "#FFFFFF")
            .on("click", clickedPoint)
            .style("cursor", "pointer");

        const gTurtle = g.append("g").attr("class", "turtle");

        gTurtle
            .append("svg:image")
            .attr("xlink:href", turtle)
            .attr("width", 32)
            .attr("height", 32)
            .attr("class", "z-20");

        //d3.select("#map-svg").call(zoom);

        let screenW = mapContainerSize.width;

        // Set translate and scale of whole map
        let scale, xT, yT;

        switch (true) {
            case screenW <= 620:
                scale = 0.9;
                xT = 70;
                yT = 0;
                break;

            case screenW <= 840:
                scale = 1;
                xT = 70;
                yT = 0;
                break;

            case screenW <= 1450:
                scale = 0.9;
                xT = 25;
                yT = 0;
                break;

            case screenW <= 1800:
                scale = 1;
                xT = 10;
                yT = 25;
                break;

            default:
                scale = 1.14;
                xT = -8;
                yT = -50;
                break;
        }

        const translate = {
            x: xT,
            y: yT,
        };

        d3.select("#map-svg").attr("transform", `scale(${scale}) translate(${translate.x}, ${translate.y})`);

        setSvgRef(svg);
    };

    useEffect(() => {
        // Strict mode handler
        if (effectRan.current && mapContainerSize) {
            if (!data) {
                loadData();
            } else if (!document.getElementById("map-container")) {
                loadMap();
                const gTurtle = d3.select(".turtle");
                const [x, y] = projection(data.route.features[0].geometry.coordinates);
                gTurtle.attr("transform", `translate(${x - 16},${y - 16})`);
            } else {
                loadMap();
            }
        }

        return () => {
            effectRan.current = true;
            try {
                let container = document.querySelector(".route-diagram");
                container.removeChild(document.getElementById("map-svg"));
            } catch {
                undefined;
            }
        };
    }, [data, mapContainerSize]);

    useEffect(() => {
        if (data && currentSection.path) {
            let { bounds, points, finalRoutes } = data;
            projection = geoIdentity().reflectY(true).fitSize([dimensions.width, dimensions.height], bounds);

            const gTurtle = d3.select(".turtle");

            const [currentCove] = data.points.features.filter((cove) => cove.properties.path === currentSection.path);

            if (currentCove) {
                const [x, y] = projection(currentCove.geometry.coordinates);
                let xOff = 16;
                let yOff = -16;
                let mirror = false;
                if (["zavala", "zukova"].includes(currentSection.path)) {
                    xOff = -16;
                    mirror = true;
                }
                gTurtle.attr("transform", `translate(${x + xOff},${y + yOff}) ${mirror ? "scale(-1,1)" : ""}`);
            }

            let fullLine = d3.select(".path-final path");

            const generatePath = d3
                .line()
                .x(function (d) {
                    return projection(d.geometry.coordinates)[0];
                })
                .y(function (d) {
                    return projection(d.geometry.coordinates)[1];
                })
                .curve(d3.curveCardinal);

            if (finalRoutes && currentSection.path !== "root") {
                fullLine
                    .attr("width", dimensions.width - 50)
                    .attr("height", dimensions.height - 50)
                    .attr("d", generatePath(finalRoutes[`${currentSection.path}`].features))
                    .style("fill", "none");

                let totalLength = fullLine.node().getTotalLength();

                fullLine
                    .attr("stroke-dasharray", totalLength + " " + totalLength)
                    .attr("stroke-dashoffset", totalLength)
                    .transition() // Call Transition Method
                    .duration(1000) // Set Duration timing (ms)
                    .ease(d3.easeLinear) // Set Easing option
                    .attr("stroke-dashoffset", 0);
            }

            d3.selectAll(".map-label circle.outer-circle").style("fill", function (d) {
                // Get id of current route
                let [currentPath] = points.features.filter((ele) => {
                    return ele.properties.path === currentSection.path;
                });
                let subSet = points.features.filter((ele) => {
                    return ele.properties.id <= currentPath?.properties.id;
                });
                let color = "#FEFEFE";
                color = subSet.includes(d) ? "#52C0A9" : color;
                return color;
            });
        }

        // Set this last to render it properly in next rerender
        setPreviousSection(currentSection.path);

        return () => {};
    }, [currentSection]);

    return (
        <>
            <div
                className="route-diagram overflow-hidden"
                ref={diagramRef}
            ></div>
        </>
    );
};

export default Map;
