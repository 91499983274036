import React from "react";

/* UVALA LOZNA */
// Otvoreno more
import dupin from "../assets/img/lozna/dupin.jpg";
import dupin_1 from "../assets/img/lozna/dupin_2.jpg";
import kornjaca from "../assets/img/lozna/kornjaca.jpg";
import kornjaca_1 from "../assets/img/lozna/kornjaca_1.jpg";
import meduza from "../assets/img/lozna/meduza.jpg";
import meduza_1 from "../assets/img/lozna/meduza_1.jpg";
import meduza_2 from "../assets/img/lozna/meduza_2.jpg";
// Duboka dna
import zuta_gorgonija from "../assets/img/lozna/zuta_gorgonija.jpg";
import zuta_gorgonija_1 from "../assets/img/lozna/zuta_gorgonija_1.jpg";
import jastog from "../assets/img/lozna/jastog.jpg";
import jastog_1 from "../assets/img/lozna/jastog_1.jpg";
import skarpina from "../assets/img/lozna/skarpina.jpg";
import skarpina_1 from "../assets/img/lozna/skarpina_1.jpg";
import koralinske_alge from "../assets/img/lozna/koralinske_alge.jpg";
import koralinske_alge_1 from "../assets/img/lozna/koralinske_alge_1.jpg";
import koralinske_alge_2 from "../assets/img/lozna/koralinske_alge_2.jpg";
// Ribolov
import zaostale_mreze from "../assets/img/lozna/zaostale_mreze.jpg";

/* UVALA GLAVNA */
// Uvala
import glavna from "../assets/img/glavna/glavna.jpg";
import glavna_1 from "../assets/img/glavna/glavna_1.jpg";
import glavna_2 from "../assets/img/glavna/glavna_2.jpg";
import glavna_3 from "../assets/img/glavna/glavna_3.jpg";

// Biljke uz morsku obalu
import mrizica from "../assets/img/glavna/mrizica.jpg";
import mrizica_1 from "../assets/img/glavna/mrizica_1.jpg";
import mrizica_2 from "../assets/img/glavna/mrizica_2.jpg";
import mrizica_3 from "../assets/img/glavna/mrizica_3.jpg";
import pepeljuga from "../assets/img/glavna/pepeljuga.jpg";
import pepeljuga_1 from "../assets/img/glavna/pepeljuga_1.jpg";
import pepeljuga_2 from "../assets/img/glavna/pepeljuga_2.jpg";
import motar from "../assets/img/glavna/motar.jpg";
import motar_1 from "../assets/img/glavna/motar_1.jpg";
// Lažina
import lazina from "../assets/img/glavna/lazina.jpg";
// Oman
import oman_2 from "../assets/img/glavna/oman_2.jpg";
import oman_3 from "../assets/img/glavna/oman_3.jpg";

/* UVALA STUPIŠĆE */
// Prstaci
import prstaci from "../assets/img/stupisce/prstaci/prstac.jpg";
import prstaci_1 from "../assets/img/stupisce/prstaci/prstac_1.jpg";
import obala from "../assets/img/stupisce/prstaci/obala.jpg";
import obala_1 from "../assets/img/stupisce/prstaci/obala_1.jpg";
import obala_2 from "../assets/img/stupisce/prstaci/obala_2.jpg";
import razmnozavanje_prstac from "../assets/img/stupisce/prstaci/razmnozavanje.jpg";
import razmnozavanje_prstac_1 from "../assets/img/stupisce/prstaci/razmnozavanje_1.jpg";
// Trpovi
import trpovi from "../assets/img/stupisce/trpovi/trpovi.jpg";
import trpovi_1 from "../assets/img/stupisce/trpovi/trpovi_1.jpg";
import trpovi_2 from "../assets/img/stupisce/trpovi/trpovi_2.jpg";
import trpovi_3 from "../assets/img/stupisce/trpovi/trpovi_3.jpg";
import trpovi_4 from "../assets/img/stupisce/trpovi/trpovi_4.jpg";
import trpovi_5 from "../assets/img/stupisce/trpovi/trpovi_5.jpg";
import trp_razmnožavanje from "../assets/img/stupisce/trpovi/razmnozavanje.jpg";
import trp_razmnožavanje_1 from "../assets/img/stupisce/trpovi/razmnozavanje_1.jpg";
import trp_razmnožavanje_2 from "../assets/img/stupisce/trpovi/razmnozavanje_2.jpg";
import trp_razmnožavanje_3 from "../assets/img/stupisce/trpovi/razmnozavanje_3.jpg";

/* UVALA TIHA */
// Ugroženost biološke raznolikosti
import periska from "../assets/img/tiha/periska.JPG";
import periska_1 from "../assets/img/tiha/periska_1.JPG";
import sidrenje_tiha from "../assets/img/tiha/sidrenje.JPG";
import sidrenje_tiha_1 from "../assets/img/tiha/sidrenje_1.JPG";
import sidrenje_tiha_2 from "../assets/img/tiha/sidrenje_2.JPG";
import kavala from "../assets/img/tiha/kavala.JPG";
import kovac from "../assets/img/tiha/kovac.JPG";
import kirnja from "../assets/img/tiha/kirnja.JPG";
import zvjezdaca_tiha from "../assets/img/tiha/zvjezdaca.JPG";
import otpad from "../assets/img/tiha/otpad.JPG";
import otpad_1 from "../assets/img/tiha/otpad_1.JPG";
import taxifolia from "../assets/img/tiha/texifolia.JPG";
import taxifolia_1 from "../assets/img/tiha/texifolia_1.JPG";
import cylindracea from "../assets/img/tiha/cylindracea.JPG";
import cylindracea_1 from "../assets/img/tiha/cylindracea_1.JPG";
import cylindracea_2 from "../assets/img/tiha/cylindracea_2.JPG";
import melibe from "../assets/img/tiha/melibe.JPG";
import melibe_1 from "../assets/img/tiha/melibe_1.JPG";
import zekan from "../assets/img/tiha/zekan.JPG";

/* UVALA ZAVALA */
// Naselja posidonije
import stabalca from "../assets/img/zavala/stabalca.jpg";
import cvat from "../assets/img/zavala/cvat.jpg";
import plodic from "../assets/img/zavala/plodic.jpg";
import nakupine from "../assets/img/zavala/nakupine.jpg";
import tragalj_muzjak from "../assets/img/zavala/tragalj_muzjak.jpg";
import mrijest from "../assets/img/zavala/mrijest.jpg";
import sidrenje_zavala from "../assets/img/zavala/sidrenje.jpg";
import osteceno from "../assets/img/zavala/osteceno.jpg";
import zdravo from "../assets/img/zavala/zdravo.jpg";
import posidonija from "../assets/img/zavala/posidonija.jpg";

/* UVALA ŽUKOVA */
// Komarča i crv
import crv from "../assets/img/zukova/crv.JPG";
import crv_1 from "../assets/img/zukova/crv_1.JPG";
import trapula from "../assets/img/zukova/trapula.JPG";
import komarca from "../assets/img/zukova/komarca.JPG";
import komarca_1 from "../assets/img/zukova/komarca_1.JPG";
// Otisci dinosaura
//import otisci_dinosaura from "../assets/img/zukova/otisci_dinosaura.jpg";
import otisci_dinosaura from "../assets/img/zukova/otisci_dinosaura.jpg";
//import otisci_dinosaura_1 from "../assets/img/zukova/otisci_dinosaura_1.jpg";
import otisci_dinosaura_1 from "../assets/img/zukova/otisci_dinosaura_1.png";
//import otisci_dinosaura_2 from "../assets/img/zukova/otisci_dinosaura_2.jpg";
import otisci_dinosaura_2 from "../assets/img/zukova/otisci_dinosaura_2.png";
import otisci_shema from "../assets/img/zukova/otisci_shema.jpg";

/* UVALA VELA TRAVNA */
// Supralitoralne stijene
import supralitoral from "../assets/img/travna/supralitoral.jpg";
import supralitoral_1 from "../assets/img/travna/supralitoral_1.jpg";
import supralitoral_2 from "../assets/img/travna/supralitoral_2.jpg";
import supralitoral_3 from "../assets/img/travna/supralitoral_3.jpg";
import obalni_puzic from "../assets/img/travna/obalni_puzic.jpg";
import obalni_puzic_1 from "../assets/img/travna/obalni_puzic_1.jpg";
import obalni_puzic_2 from "../assets/img/travna/obalni_puzic_2.jpg";
import viticari from "../assets/img/travna/viticari.jpg";
import viticari_1 from "../assets/img/travna/viticari_1.jpg";
import viticari_2 from "../assets/img/travna/viticari_2.jpg";
import babura from "../assets/img/travna/babura.jpg";
import babura_1 from "../assets/img/travna/babura_1.jpg";
import babura_2 from "../assets/img/travna/babura_2.jpg";
// Žalo i morske spužve
import zalo from "../assets/img/travna/zalo.jpg";
import zalo_1 from "../assets/img/travna/zalo_1.jpg";
import kamotocne_spuzve from "../assets/img/travna/kamotocne_spuzve.jpg";
import kamotocne_spuzve_1 from "../assets/img/travna/kamotocne_spuzve_1.jpg";
import kamotocne_spuzve_2 from "../assets/img/travna/kamotocne_spuzve_2.jpg";
// Uvala
import travna from "../assets/img/travna/travna.jpg";
import travna_1 from "../assets/img/travna/travna_1.jpg";
import travna_2 from "../assets/img/travna/travna_2.jpg";

import { Translation } from "react-i18next";

// OBJECT CREATION FOR EACH IMAGE
/* LOZNA */
// Lozna, Otvoreno more
const objDupin = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Dobri dupin")}</span>}</Translation> <i>(Tursiops truncatus)</i>
        </>
    ),
    path: dupin,
};
const objDupin_1 = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Dobri dupin")}</span>}</Translation> <i>(Tursiops truncatus)</i>
        </>
    ),
    path: dupin_1,
};

const objKornjaca = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Glavata želva")}</span>}</Translation> <i>(Caretta caretta)</i>
        </>
    ),
    path: kornjaca,
};
const objKornjaca_1 = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Glavata želva")}</span>}</Translation> <i>(Caretta caretta)</i>
        </>
    ),
    path: kornjaca_1,
};

const objMeduza = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Mediteranska meduza")}</span>}</Translation> <i>(Cotylorhiza tuberculata)</i>
        </>
    ),
    path: meduza,
};
const objMeduza_1 = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Mediteranska meduza")}</span>}</Translation> <i>(Cotylorhiza tuberculata)</i>
        </>
    ),
    path: meduza_1,
};
const objMeduza_2 = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Mediteranska meduza")}</span>}</Translation> <i>(Cotylorhiza tuberculata)</i>
        </>
    ),
    path: meduza_2,
};

// Lozna, Duboka dna
const objZutaGorgonija = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Žuta gorgonija")}</span>}</Translation> (<i>Eunicella cavolini</i>){" "}
            <Translation>{(t) => <span>{t("u koraligenskoj")}</span>}</Translation> <br />
            <Translation>{(t) => <span>{t("zajednici rta Kabal")}</span>}</Translation>
        </>
    ),
    path: zuta_gorgonija,
};
const objZutaGorgonija_1 = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Žuta gorgonija")}</span>}</Translation> (<i>Eunicella cavolini</i>){" "}
            <Translation>{(t) => <span>{t("u koraligenskoj")}</span>}</Translation> <br />
            <Translation>{(t) => <span>{t("zajednici rta Kabal")}</span>}</Translation>
        </>
    ),
    path: zuta_gorgonija_1,
};
const objJastog = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Jastog")}</span>}</Translation> <i>(Palinurus elephas)</i>
        </>
    ),
    path: jastog,
};
const objJastog_1 = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Jastog")}</span>}</Translation> <i>(Palinurus elephas)</i>
        </>
    ),
    path: jastog_1,
};
const objSkarpina = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Škarpina")}</span>}</Translation> <i>(Scorpaena scrofa)</i>
        </>
    ),
    path: skarpina,
};
const objSkarpina_1 = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Škarpina")}</span>}</Translation> <i>(Scorpaena scrofa)</i>
        </>
    ),
    path: skarpina_1,
};
const objKoralinske_alge = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Koralinske alge")}</span>}</Translation>
        </>
    ),
    path: koralinske_alge,
};
const objKoralinske_alge_1 = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Koralinske alge")}</span>}</Translation>
        </>
    ),
    path: koralinske_alge_1,
};
const objKoralinske_alge_2 = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Koralinske alge")}</span>}</Translation>
        </>
    ),
    path: koralinske_alge_2,
};

// Lozna, Ribolov
const objZaostaleMreze = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Zaostale ribarske mreže na rtu Kabal")}</span>}</Translation>
        </>
    ),
    path: zaostale_mreze,
};

/* LOZNA */
// Uvala Lozna, otvoreno more, dobri dupin
export const lozna_om_dupin = [objDupin, objDupin_1];
// Uvala Lozna, otvoreno more, kornjaca glavata zelva
export const lozna_om_kornjaca = [objKornjaca, objKornjaca_1];
// Lozna, otvoreno more, mediteranska meduza
export const lozna_om_meduza = [objMeduza, objMeduza_1, objMeduza_2];
// Lozna, duboka dna, koraligenska zajednica
export const lozna_dd_koraligenska = [
    objZutaGorgonija,
    objZutaGorgonija_1,
    objJastog,
    objJastog_1,
    objSkarpina,
    objSkarpina_1,
    objKoralinske_alge,
    objKoralinske_alge_1,
    objKoralinske_alge_2,
];
// Lozna, ribolov, zaostale mreže
export const lozna_r_mreze = [objZaostaleMreze];

// UVALA GLAVNA
// Glavna, biljke uz mosku obalu
const objRasetkastaMrizica = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Rešetkasta mrižica")}</span>}</Translation> <i>(Limonium cancellatum)</i>
        </>
    ),
    path: mrizica,
};
const objRasetkastaMrizica_1 = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Rešetkasta mrižica")}</span>}</Translation> <i>(Limonium cancellatum)</i>
        </>
    ),
    path: mrizica_1,
};
const objRasetkastaMrizica_2 = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Rešetkasta mrižica")}</span>}</Translation> <i>(Limonium cancellatum)</i>
        </>
    ),
    path: mrizica_2,
};
const objRasetkastaMrizica_3 = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Rešetkasta mrižica")}</span>}</Translation> <i>(Limonium cancellatum)</i>
        </>
    ),
    path: mrizica_3,
};
const objPrimorskaPepeljuga = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Primorska pepeljuga")}</span>}</Translation> <i>(Halimione portulacoides)</i>
        </>
    ),
    path: pepeljuga,
};
const objPrimorskaPepeljuga_1 = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Primorska pepeljuga")}</span>}</Translation> <i>(Halimione portulacoides)</i>
        </>
    ),
    path: pepeljuga_1,
};
const objPrimorskaPepeljuga_2 = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Primorska pepeljuga")}</span>}</Translation> <i>(Halimione portulacoides)</i>
        </>
    ),
    path: pepeljuga_2,
};
const objMotar = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Motar")}</span>}</Translation> <i>(Crithmum maritimum)</i>
        </>
    ),
    path: motar,
};
const objMotar1 = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Motar")}</span>}</Translation> <i>(Crithmum maritimum)</i>
        </>
    ),
    path: motar_1,
};
const objOman2 = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Primorski oman")}</span>}</Translation> <i>(Inula crithmoides)</i>
        </>
    ),
    path: oman_2,
};
const objOman3 = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Primorski oman")}</span>}</Translation> <i>(Inula crithmoides)</i>
        </>
    ),
    path: oman_3,
};

// glavna, biljke uz morsku obalu
export const glavna_bumo_rasetkastaMrizica = [
    objRasetkastaMrizica,
    objRasetkastaMrizica_1,
    objRasetkastaMrizica_2,
    objRasetkastaMrizica_3,
];
export const glavna_bumo_primorskaPepeljuga = [objPrimorskaPepeljuga, objPrimorskaPepeljuga_1, objPrimorskaPepeljuga_2];
export const glavna_bumo_motar = [objMotar, objMotar1];
export const glavna_bumo_oman = [objOman2, objOman3];

// Uvala
const objUvalaGlavna = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Uvala Glavna")}</span>}</Translation>
        </>
    ),
    path: glavna,
};
const objUvalaGlavna1 = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Uvala Glavna")}</span>}</Translation>
        </>
    ),
    path: glavna_1,
};
const objUvalaGlavna2 = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Uvala Glavna")}</span>}</Translation>
        </>
    ),
    path: glavna_2,
};
const objUvalaGlavna3 = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Uvala Glavna")}</span>}</Translation>
        </>
    ),
    path: glavna_3,
};
export const glavna_uvala_uvala = [objUvalaGlavna, objUvalaGlavna1, objUvalaGlavna2, objUvalaGlavna3];
// Lažina
const objLazina = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Lažina nakupljena na plaži u uvali Glavna")}</span>}</Translation>
        </>
    ),
    path: lazina,
};
export const glavna_lazina_lazina = [objLazina];

/* STUPIŠĆE */
// Stupišće, Prstaci
const objPrstac = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Školjkaš prstac")}</span>}</Translation> <i>(Lithophaga lithophaga)</i>
        </>
    ),
    path: prstaci,
};
const objPrstac_1 = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Školjkaš prstac")}</span>}</Translation> <i>(Lithophaga lithophaga)</i>
        </>
    ),
    path: prstaci_1,
};
const objZastitaPrstaca = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Područja obale otučena sakupljanja prstaca")}</span>}</Translation>
        </>
    ),
    path: obala,
};
const objZastitaPrstaca_1 = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Područja obale otučena sakupljanja prstaca")}</span>}</Translation>
        </>
    ),
    path: obala_1,
};
const objZastitaPrstaca_2 = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Područja obale otučena sakupljanja prstaca")}</span>}</Translation>
        </>
    ),
    path: obala_2,
};
const objRazmnozavanjePrstaca = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Masovno razmnožavanje prstaca")}</span>}</Translation>
        </>
    ),
    path: razmnozavanje_prstac,
};
const objRazmnozavanjePrstaca_1 = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Masovno razmnožavanje prstaca")}</span>}</Translation>
        </>
    ),
    path: razmnozavanje_prstac_1,
};
// Stupisce, Trpovi
const objTrp = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Trp")}</span>}</Translation>
        </>
    ),
    path: trpovi,
};
const objTrp_1 = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Trp")}</span>}</Translation>
        </>
    ),
    path: trpovi_1,
};
const objTrp_2 = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Trp")}</span>}</Translation>
        </>
    ),
    path: trpovi_2,
};
const objTrp_3 = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Trp")}</span>}</Translation>
        </>
    ),
    path: trpovi_3,
};
const objTrp_4 = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Trp")}</span>}</Translation>
        </>
    ),
    path: trpovi_4,
};
const objTrp_5 = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Trp")}</span>}</Translation>
        </>
    ),
    path: trpovi_5,
};
const objRazmnZastitaTrp = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Trpovi u razmnožavanju")}</span>}</Translation>
        </>
    ),
    path: trp_razmnožavanje,
};
const objRazmnZastitaTrp_1 = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Trpovi u razmnožavanju")}</span>}</Translation>
        </>
    ),
    path: trp_razmnožavanje_1,
};
const objRazmnZastitaTrp_2 = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Trpovi u razmnožavanju")}</span>}</Translation>
        </>
    ),
    path: trp_razmnožavanje_2,
};
const objRazmnZastitaTrp_3 = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Trpovi u razmnožavanju")}</span>}</Translation>
        </>
    ),
    path: trp_razmnožavanje_3,
};

// Stupisce, prstaci
export const stupisce_p_prstac = [objPrstac, objPrstac_1];
export const stupisce_p_zastitaPrstaca = [objZastitaPrstaca, objZastitaPrstaca_1, objZastitaPrstaca_2];
export const stupisce_p_razmnPrstaca = [objRazmnozavanjePrstaca, objRazmnozavanjePrstaca_1];
// Stupisce, trpovi
export const stupisce_trp_trp = [objTrp, objTrp_1, objTrp_2, objTrp_3, objTrp_4, objTrp_5];
export const stupisce_trp_razmnZastita = [
    objRazmnZastitaTrp,
    objRazmnZastitaTrp_1,
    objRazmnZastitaTrp_2,
    objRazmnZastitaTrp_3,
];

/* TIHA */
// Tiha, ugroženost biološke raznolikosti
const objPeriska = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Periska")}</span>}</Translation> <i>(Pinna nobilis)</i>
        </>
    ),
    path: periska,
};
const objPeriska_1 = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Periska")}</span>}</Translation> <i>(Pinna nobilis)</i>
        </>
    ),
    path: periska_1,
};

const objSidrenjeTiha = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Utjecaj sidrenja na naselja posidonije")}</span>}</Translation>
        </>
    ),
    path: sidrenje_tiha,
};
const objSidrenjeTiha_1 = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Utjecaj sidrenja na naselja posidonije")}</span>}</Translation>
        </>
    ),
    path: sidrenje_tiha_1,
};
const objSidrenjeTiha_2 = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Utjecaj sidrenja na naselja posidonije")}</span>}</Translation>
        </>
    ),
    path: sidrenje_tiha_2,
};

const objKavala = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Kaval")}</span>}</Translation> <i>(Sciena umbra)</i>
        </>
    ),
    path: kavala,
};
const objKovac = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Kovač")}</span>}</Translation> <i>(Zeus faber)</i>
        </>
    ),
    path: kovac,
};
const objKirnja = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Kirnja")}</span>}</Translation> <i>(Epinephelus marginatus)</i>
        </>
    ),
    path: kirnja,
};
const objZvjezdaca = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Zvjezdača")}</span>}</Translation> <i>(Ophidiaster ophidianus)</i>
        </>
    ),
    path: zvjezdaca_tiha,
};

const objOtpad = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Morski otpad")}</span>}</Translation>
        </>
    ),
    path: otpad,
};
const objOtpad_1 = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Morski otpad")}</span>}</Translation>
        </>
    ),
    path: otpad_1,
};
// Tiha. strane vrste
const objTaxifolia = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Strana alga")}</span>}</Translation> <i>(Caulerpa taxifolia)</i>
        </>
    ),
    path: taxifolia,
};
const objTaxifolia_1 = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Strana alga")}</span>}</Translation> <i>(Caulerpa taxifolia)</i>
        </>
    ),
    path: taxifolia_1,
};
const objCylindracea = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Strana alga")}</span>}</Translation> <i>(Caulerpa cylindracea)</i>
        </>
    ),
    path: cylindracea,
};
const objCylindracea_1 = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Strana alga")}</span>}</Translation> <i>(Caulerpa cylindracea)</i>
        </>
    ),
    path: cylindracea_1,
};
const objCylindracea_2 = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Strana alga")}</span>}</Translation> <i>(Caulerpa cylindracea)</i>
        </>
    ),
    path: cylindracea_2,
};
const objMelibe = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Strana alga")}</span>}</Translation> <i>(Melibe viridis)</i>
        </>
    ),
    path: melibe,
};
const objMelibe_1 = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Strani puž golać")}</span>}</Translation> <i>(Melibe viridis)</i>
        </>
    ),
    path: melibe_1,
};
const objStraniPuzGolac = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Strani puž golać")}</span>}</Translation> <i>(Aplysia dactilomella)</i>
        </>
    ),
    path: zekan,
};

//ubr - ugroženost biološke raznoliokosti
export const tiha_ubr_periska = [objPeriska, objPeriska_1];
export const tiha_ubr_sidrenje = [objSidrenjeTiha, objSidrenjeTiha_1, objSidrenjeTiha_2];
export const tiha_ubr_pretjeraniRibolov = [objKavala, objKovac, objKirnja, objZvjezdaca];
export const tiha_ubr_otpad = [objOtpad, objOtpad_1];
//sv - strane vrste
export const tiha_sv_taxifolia = [objTaxifolia, objTaxifolia_1];
export const tiha_sv_cylindracea = [objCylindracea, objCylindracea_1, objCylindracea_2];
export const tiha_sv_melibe = [objMelibe, objMelibe_1];
export const tiha_sv_straniPuzGolac = [objStraniPuzGolac];

/* ZAVALA */
// Naselja posidonije
const objStabalcaPosidonije = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Vertikalna stabalca posidonije rastu brzinom")}</span>}</Translation> <br />
            <Translation>{(t) => <span>{t("od samo 1 cm godišnje")}</span>}</Translation>
        </>
    ),
    path: stabalca,
};
const objPosidonijaCvat = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Cvat morske cvjetnice posidonije")}</span>}</Translation>
        </>
    ),
    path: cvat,
};
const objPosidonijaPlodic = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Plodić morske cvjetnice posidonije")}</span>}</Translation>
        </>
    ),
    path: plodic,
};
const objNakupineListova = {
    title: (
        <>
            <Translation>
                {(t) => <span>{t("Nakupine listova posidonije na obali sprječavaju eroziju")}</span>}
            </Translation>
        </>
    ),
    path: nakupine,
};
const objTragalj = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Mužjak modraka iznad gnijezda u naselju posidonije")}</span>}</Translation>
        </>
    ),
    path: tragalj_muzjak,
};
const objMrijest = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Masovni mrijest modraka u naselju posidonije")}</span>}</Translation>
        </>
    ),
    path: mrijest,
};
const objSidrenjeZavala = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Izguljene sidrima stoljetne biljke posidonije")}</span>}</Translation>
        </>
    ),
    path: sidrenje_zavala,
};
const objOstecenePosidonije = {
    title: (
        <>
            <Translation>
                {(t) => <span>{t("Oštećeno naselje posidonije raznim ljudskim aktivnostima")}</span>}
            </Translation>
        </>
    ),
    path: osteceno,
};
const objZdravePosidonije = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Zdravo naselje posidonije")}</span>}</Translation>
        </>
    ),
    path: zdravo,
};
const objPosidonija = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Naselje morske cvjetnice posidonije")}</span>}</Translation>
        </>
    ),
    path: posidonija,
};

// naselja posidonije
export const zavala_np_stabalcaPosidonije = [objPosidonija, objStabalcaPosidonije];
export const zavala_np_razmn = [objPosidonijaCvat, objPosidonijaPlodic];
export const zavala_np_nakupineListova = [objNakupineListova];
export const zavala_np_mrijest = [objMrijest, objTragalj];
export const zavala_np_ugroženostPosidonije = [objSidrenjeZavala, objOstecenePosidonije, objZdravePosidonije];
// ribolov u naseljima posidonije
/* nema slike */

/* ŽUKOVA */
// Komarča i crv
const objVelikiMorskiCrv = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Veliki morski crv")}</span>}</Translation> <i>(Eunice roussaei)</i>
        </>
    ),
    path: crv,
};
const objVelikiMorskiCrv_1 = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Veliki morski crv")}</span>}</Translation> <i>(Eunice roussaei)</i>
        </>
    ),
    path: crv_1,
};
const objTrapula = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Veliki morski crv")}</span>}</Translation>
            <Translation>{(t) => <span>{t("ulovljen u trapulu")}</span>}</Translation>
        </>
    ),
    path: trapula,
};
const objKomarca = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Komarča")}</span>}</Translation> <i>(Sparus aurata)</i>
        </>
    ),
    path: komarca,
};
const objKomarca_1 = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Komarča")}</span>}</Translation> <i>(Sparus aurata)</i>
        </>
    ),
    path: komarca_1,
};

// Otisci dinosaura
const objOtisciDinosaura = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Otisci dinosaura u uvali Žukova")}</span>}</Translation>
        </>
    ),
    path: otisci_dinosaura,
};
const objOtisciDinosaura_1 = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Otisci dinosaura u uvali Žukova")}</span>}</Translation>
        </>
    ),
    path: otisci_dinosaura_1,
};
const objOtisciDinosaura_2 = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Otisci dinosaura u uvali Žukova")}</span>}</Translation>
        </>
    ),
    path: otisci_dinosaura_2,
};
const objOtisciDinosauraShema = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Shematski prikaz otisaka dinosaura u uvali Žukova")}</span>}</Translation>
        </>
    ),
    path: otisci_shema,
};

// komorča i crv
export const zukova_kic_velikiMorskiCrv = [objVelikiMorskiCrv, objVelikiMorskiCrv_1, objTrapula];
export const zukova_kic_komarca = [objKomarca, objKomarca_1];
// otisci dinosaura
export const zukova_od_otisciDinosaura = [
    objOtisciDinosaura,
    objOtisciDinosaura_1,
    objOtisciDinosaura_2,
    objOtisciDinosauraShema,
];

/* UVALA VELA TRAVNA */
// Travna, supralitoralne stijene
const objSupralitoral = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Supralitoralna zona ističe se maslinasto")}</span>}</Translation> <br />
            <Translation>{(t) => <span>{t("sivom obojenošću kamena")}</span>}</Translation>
        </>
    ),
    path: supralitoral,
};
const objSupralitoral_1 = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Supralitoralna zona ističe se maslinasto")}</span>}</Translation> <br />
            <Translation>{(t) => <span>{t("sivom obojenošću kamena")}</span>}</Translation>
        </>
    ),
    path: supralitoral_1,
};
const objSupralitoral_2 = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Supralitoralna zona ističe se maslinasto")}</span>}</Translation> <br />
            <Translation>{(t) => <span>{t("sivom obojenošću kamena")}</span>}</Translation>
        </>
    ),
    path: supralitoral_2,
};
const objSupralitoral_3 = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Supralitoralna zona ističe se maslinasto")}</span>}</Translation> <br />
            <Translation>{(t) => <span>{t("sivom obojenošću kamena")}</span>}</Translation>
        </>
    ),
    path: supralitoral_3,
};
const objObalniPuzic = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Mali obalni pužić")}</span>}</Translation> <i>(Melarhaphe neritoides)</i>
        </>
    ),
    path: obalni_puzic,
};
const objObalniPuzic_1 = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Mali obalni pužić")}</span>}</Translation> <i>(Melarhaphe neritoides)</i>
        </>
    ),
    path: obalni_puzic_1,
};
const objObalniPuzic_2 = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Mali obalni pužić")}</span>}</Translation> <i>(Melarhaphe neritoides)</i>
        </>
    ),
    path: obalni_puzic_2,
};
const objRakViticar = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Rak vitičar")}</span>}</Translation> <i>(Euraphia depressa)</i>
        </>
    ),
    path: viticari,
};
const objRakViticar_1 = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Rak vitičar")}</span>}</Translation> <i>(Euraphia depressa)</i>
        </>
    ),
    path: viticari_1,
};
const objRakViticar_2 = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Rak vitičar")}</span>}</Translation> <i>(Euraphia depressa)</i>
        </>
    ),
    path: viticari_2,
};
const objRacicBabura = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Izopodni račić babura")}</span>}</Translation> <i>(Ligia italica)</i>
        </>
    ),
    path: babura,
};
const objRacicBabura_1 = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Izopodni račić babura")}</span>}</Translation> <i>(Ligia italica)</i>
        </>
    ),
    path: babura_1,
};
const objRacicBabura_2 = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Izopodni račić babura")}</span>}</Translation> <i>(Ligia italica)</i>
        </>
    ),
    path: babura_2,
};

// Travna, žalo i morske spuzve
const objZalo = {
    title: (
        <>
            <Translation>
                {(t) => <span>{t("Valutice žala s kanalima i rupicama koje su napravile kamotočne spužve")}</span>}
            </Translation>
        </>
    ),
    path: zalo,
};
const objZalo_1 = {
    title: (
        <>
            <Translation>
                {(t) => <span>{t("Valutice žala s kanalima i rupicama koje su napravile kamotočne spužve")}</span>}
            </Translation>
        </>
    ),
    path: zalo_1,
};
const objKamotocneSpuzve = {
    title: (
        <>
            <Translation>
                {(t) => <span>{t("Kamotočne spužve proviruju iz kamena kojeg su naselile")}</span>}
            </Translation>
        </>
    ),
    path: kamotocne_spuzve,
};
const objKamotocneSpuzve_1 = {
    title: (
        <>
            <Translation>
                {(t) => <span>{t("Kamotočne spužve proviruju iz kamena kojeg su naselile")}</span>}
            </Translation>
        </>
    ),
    path: kamotocne_spuzve_1,
};
const objKamotocneSpuzve_2 = {
    title: (
        <>
            <Translation>
                {(t) => <span>{t("Kamotočne spužve proviruju iz kamena kojeg su naselile")}</span>}
            </Translation>
        </>
    ),
    path: kamotocne_spuzve_2,
};

// supralitoralne stijene
export const travna_ss_supralitoral = [objSupralitoral, objSupralitoral_1, objSupralitoral_2, objSupralitoral_3];
export const travna_ss_obalniPuzic = [objObalniPuzic, objObalniPuzic_1, objObalniPuzic_2];
export const travna_ss_rakViticar = [objRakViticar, objRakViticar_1, objRakViticar_2];
export const travna_ss_racicBabura = [objRacicBabura, objRacicBabura_1, objRacicBabura_2];
// žalo i morske spuzve
export const travna_zims_zaloSpuzve = [
    objZalo,
    objZalo_1,
    objKamotocneSpuzve,
    objKamotocneSpuzve_1,
    objKamotocneSpuzve_2,
];

const objTravna = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Uvala Travna")}</span>}</Translation>
        </>
    ),
    path: travna,
};
const objTravna1 = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Uvala Travna")}</span>}</Translation>
        </>
    ),
    path: travna_1,
};
const objTravna2 = {
    title: (
        <>
            <Translation>{(t) => <span>{t("Uvala Travna")}</span>}</Translation>
        </>
    ),
    path: travna_2,
};
export const travna_uvala_uvala = [objTravna, objTravna1, objTravna2];
