import React from "react";

const Logo = () => {
    return (
        <svg
            viewBox="0 0 74 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="w-[74px] h-[30px] md:w-[70px] md:h-[26px]"
        >
            <g clipPath="url(#clip0_14_3)">
                <path
                    d="M17.4054 4.63947L16.2019 6.74993L17.4054 4.63947C17.4054 4.65691 17.4054 4.65691 17.4054 4.63947Z"
                    fill="#00AEEF"
                />
                <path
                    d="M17.4228 4.65701L16.2019 6.75003L17.4228 4.65701Z"
                    fill="#00AEEF"
                />
                <path
                    d="M17.4054 4.63947L16.2019 6.74993L17.4054 4.63947Z"
                    fill="#00AEEF"
                />
                <path
                    d="M18.1736 4.04648L18.662 3.19183L18.1736 4.02904V4.04648Z"
                    fill="#ED1C24"
                />
                <path
                    d="M14.0054 1.62203L14.6158 0.54063L14.0054 1.62203Z"
                    fill="#ED1C24"
                />
                <path
                    d="M19.6215 3.80229C19.4471 3.59299 19.2553 3.40113 19.0634 3.20927L18.4355 4.29066C18.4529 4.30811 18.4529 4.30811 18.4704 4.32555C18.5576 4.41276 18.6448 4.49997 18.7146 4.60462C20.3367 6.43601 21.2262 8.77322 21.2262 11.2151C21.2262 16.4128 18.4355 20.843 16.0983 23.6512C14.075 26.0581 12.0343 27.7151 11.2494 28.3256C10.4646 27.7151 8.42386 26.0581 6.41804 23.6512C4.06339 20.843 1.25525 16.3953 1.25525 11.2325C1.25525 5.7209 5.73781 1.23834 11.2494 1.23834C11.9297 1.23834 12.6099 1.3081 13.2727 1.44764C13.3948 1.46508 13.5169 1.49997 13.6215 1.53485C13.639 1.53485 13.639 1.53485 13.6564 1.55229L14.2843 0.470896C14.0227 0.401128 13.7785 0.331363 13.5169 0.279037C12.7843 0.12206 12.0169 0.0522919 11.2494 0.0522919C5.07502 0.0522919 0.0517578 5.07555 0.0517578 11.25C0.0517578 13.7093 0.627338 16.2035 1.76106 18.6628C2.66804 20.6163 3.92386 22.5698 5.49362 24.4535C8.16223 27.6453 10.7959 29.5116 10.9006 29.5988L11.2494 29.843L11.5983 29.5988C11.7029 29.5291 14.3366 27.6628 17.0053 24.4884C18.575 22.6221 19.8309 20.6686 20.7378 18.6977C21.8715 16.2209 22.4471 13.7267 22.4471 11.25C22.4297 8.49415 21.4355 5.86043 19.6215 3.80229Z"
                    fill="#00AEEF"
                />
                <path
                    d="M13.2559 1.43027C13.378 1.44771 13.5 1.48259 13.6047 1.51748C13.4826 1.48259 13.378 1.46515 13.2559 1.43027Z"
                    fill="#ED1C24"
                />
                <path
                    d="M18.4692 4.34309C18.5564 4.4303 18.6437 4.51751 18.7134 4.62216C18.6437 4.51751 18.5564 4.4303 18.4692 4.34309Z"
                    fill="#ED1C24"
                />
                <path
                    d="M14.0054 1.62206C14.0577 1.6395 14.11 1.65694 14.1449 1.65694C14.0926 1.65694 14.0577 1.6395 14.0054 1.62206Z"
                    fill="#666666"
                />
                <path
                    d="M14.0053 1.62206C13.8832 1.58717 13.7786 1.55229 13.6565 1.53485C13.7786 1.55229 13.8832 1.58717 14.0053 1.62206Z"
                    fill="#00A651"
                />
                <path
                    d="M18.4352 4.29063C18.348 4.20342 18.2608 4.11621 18.1736 4.029C18.2608 4.11621 18.348 4.20342 18.4352 4.29063Z"
                    fill="#00A651"
                />
                <path
                    d="M18.5043 2.70342C18.3822 2.59877 18.2427 2.49412 18.1206 2.38947L17.5799 1.95342L14.702 6.94179L15.0509 7.27319C16.8823 9.01737 17.2834 11.7906 16.0102 13.9883C14.4927 16.6046 11.1265 17.5116 8.51017 15.9941C5.8939 14.4767 4.98692 11.1104 6.50436 8.49412C7.77762 6.29644 10.359 5.24993 12.7834 5.96505L13.2369 6.10458L16.1148 1.11621L15.4695 0.854582C15.2951 0.784815 15.1381 0.715049 14.9637 0.662723C14.8416 0.62784 14.7369 0.575514 14.6148 0.54063L14.0044 1.62203C14.0567 1.63947 14.109 1.65691 14.1439 1.65691C14.2137 1.67435 14.2834 1.69179 14.3532 1.72668L12.6439 4.69179C9.81832 4.08133 6.90552 5.35458 5.44041 7.88365C3.59157 11.0755 4.69041 15.1918 7.89971 17.0232C8.94622 17.6337 10.0974 17.9128 11.2311 17.9128C13.5509 17.9128 15.8009 16.7092 17.0392 14.5639C18.5043 12.0174 18.1555 8.8604 16.202 6.71505L17.4055 4.60458C17.4055 4.60458 17.4055 4.60458 17.423 4.60458L17.9113 3.74993C17.9985 3.83714 18.0857 3.90691 18.1729 3.99412L18.6613 3.13947L18.7834 2.91272C18.6962 2.87784 18.609 2.79063 18.5043 2.70342Z"
                    fill="#666666"
                />
                <path
                    d="M17.9113 3.78492L17.4229 4.65701L17.9113 3.78492Z"
                    fill="#666666"
                />
                <path
                    d="M17.9119 3.78492C17.9991 3.87213 18.0863 3.94189 18.1735 4.0291C18.0863 3.95934 17.9991 3.87213 17.9119 3.78492Z"
                    fill="#666666"
                />
                <path
                    d="M17.4065 4.63953L18.5053 2.70349L17.4065 4.63953Z"
                    fill="#666666"
                />
                <path
                    d="M18.8024 2.96512L18.6628 3.19186L18.8024 2.96512Z"
                    fill="#666666"
                />
                <path
                    d="M28.412 2.18023H26.8945V14.5291H28.412V2.18023Z"
                    fill="#808285"
                />
                <path
                    d="M31.9869 2.18023H30.3474V3.75H31.9869V2.18023Z"
                    fill="#808285"
                />
                <path
                    d="M31.9181 5.35469H30.4006V14.5291H31.9181V5.35469Z"
                    fill="#808285"
                />
                <path
                    d="M38.2145 9.27907L35.8599 8.93023C35.5459 8.87791 35.3192 8.77326 35.1796 8.61628C35.0401 8.4593 34.9703 8.23256 34.9703 7.93605C34.9703 7.36047 35.1273 6.99419 35.4238 6.80233C35.7203 6.61047 36.2785 6.52326 37.0633 6.52326C37.5517 6.52326 38.0226 6.5407 38.4587 6.57558C38.8947 6.61047 39.261 6.64535 39.5924 6.69768V5.33721C39.1389 5.26744 38.7203 5.21512 38.3192 5.18023C37.918 5.16279 37.4645 5.14535 36.9761 5.14535C35.7726 5.14535 34.8831 5.37209 34.3075 5.80814C33.732 6.26163 33.4529 6.9593 33.4529 7.91861C33.4703 8.61628 33.6099 9.15698 33.9064 9.5407C34.2029 9.90698 34.6389 10.1337 35.232 10.2209L37.5866 10.5698C38.0052 10.6395 38.3017 10.7442 38.4587 10.9186C38.6157 11.093 38.7029 11.3547 38.7029 11.7035C38.7029 12.2791 38.5633 12.6628 38.2843 12.8895C38.0052 13.1163 37.482 13.2209 36.7145 13.2209C36.104 13.2209 35.511 13.186 34.9354 13.0988C34.3599 13.0116 33.8715 12.9244 33.4703 12.7849V14.2151C33.8715 14.3372 34.3599 14.4419 34.9703 14.5116C35.5808 14.5814 36.2261 14.6163 36.9413 14.6163C38.1099 14.6163 38.9645 14.3721 39.4703 13.9012C39.9761 13.4302 40.2378 12.6628 40.2378 11.5814C40.2378 10.8314 40.0808 10.2733 39.7843 9.92442C39.4878 9.62791 38.9645 9.40116 38.2145 9.27907Z"
                    fill="#808285"
                />
                <path
                    d="M45.4183 13.2559C44.7207 13.2559 44.2148 13.1163 43.9358 12.8547C43.6567 12.5757 43.5172 12.0698 43.5172 11.3198V6.66286H46.7788V5.35472H43.5172V3.12216H42.2614L42.0172 5.35472H40.6218V6.66286H41.9997V11.3722C41.9997 12.5931 42.2439 13.4477 42.7148 13.9361C43.1858 14.4245 43.9881 14.6861 45.1044 14.6861C45.4183 14.6861 45.7323 14.6512 46.0811 14.5989C46.4125 14.5466 46.6916 14.4768 46.8835 14.3896V13.1163C46.709 13.1512 46.4823 13.1861 46.1858 13.221C45.9067 13.2384 45.6451 13.2559 45.4183 13.2559Z"
                    fill="#808285"
                />
                <path
                    d="M48.8542 2.18023H48.1914V14.5291H48.8542V2.18023Z"
                    fill="#808285"
                />
                <path
                    d="M53.9636 5.1629C53.3706 5.1629 52.8648 5.18034 52.4287 5.23266C52.0101 5.28499 51.5218 5.3722 50.9811 5.49429V6.10476C51.4345 5.98266 51.8706 5.89545 52.3066 5.84313C52.7252 5.7908 53.2136 5.77336 53.7543 5.77336C54.6787 5.77336 55.359 5.98266 55.7776 6.38383C56.1962 6.78499 56.4055 7.48266 56.4055 8.45941V9.5408C55.8822 9.45359 55.3939 9.38383 54.9404 9.34894C54.4869 9.31406 54.0334 9.29662 53.5625 9.29662C52.2892 9.29662 51.3822 9.48848 50.859 9.88964C50.3183 10.2908 50.0566 10.9536 50.0566 11.8955C50.0566 12.8548 50.2834 13.5524 50.7194 13.9885C51.1555 14.4245 51.8531 14.6513 52.795 14.6513C53.4927 14.6513 54.1904 14.5466 54.8532 14.3373C55.5159 14.128 56.0392 13.8664 56.4229 13.535H56.4404L56.4927 14.5117H57.0683V8.40708C57.0683 7.27336 56.8241 6.43615 56.3183 5.93034C55.8299 5.42452 55.045 5.1629 53.9636 5.1629ZM56.388 12.9768C56.0218 13.2734 55.5334 13.5175 54.888 13.7268C54.2427 13.9187 53.5973 14.0234 52.952 14.0234C52.1497 14.0234 51.5741 13.8489 51.2078 13.5175C50.8415 13.1687 50.6671 12.628 50.6671 11.8606C50.6671 11.128 50.8764 10.6222 51.3125 10.3257C51.7485 10.0292 52.4985 9.8722 53.5799 9.8722C54.0857 9.8722 54.5741 9.88964 55.045 9.94197C55.5159 9.99429 55.952 10.0466 56.388 10.1164V12.9768Z"
                    fill="#808285"
                />
                <path
                    d="M62.7723 5.16286C62.1444 5.16286 61.5688 5.25007 61.0107 5.40705C60.4525 5.56402 59.9467 5.80821 59.4758 6.10472V2.16286H58.813V14.1803C59.2665 14.3373 59.7548 14.4419 60.2955 14.5291C60.8362 14.6163 61.3944 14.6512 61.9874 14.6512C63.5921 14.6512 64.7083 14.285 65.3711 13.5524C66.0339 12.8198 66.3653 11.5989 66.3653 9.88961C66.3653 8.23263 66.0862 7.02914 65.5281 6.27914C64.9874 5.52914 64.063 5.16286 62.7723 5.16286ZM64.9002 13.0989C64.3595 13.7268 63.3828 14.0233 61.9525 14.0233C61.4816 14.0233 61.0281 13.9884 60.6095 13.9361C60.1909 13.8838 59.8072 13.7966 59.4932 13.6919V6.75007C59.8944 6.43612 60.3653 6.20937 60.8886 6.03495C61.4293 5.86053 61.9874 5.79077 62.563 5.79077C63.7316 5.79077 64.5339 6.10472 65.0048 6.73263C65.4758 7.36053 65.7025 8.42449 65.7025 9.92449C65.72 11.407 65.4409 12.471 64.9002 13.0989Z"
                    fill="#808285"
                />
                <path
                    d="M71.7374 9.61057L69.2781 9.22685C68.8421 9.15708 68.5281 9.0001 68.3363 8.7908C68.1444 8.5815 68.0572 8.2501 68.0572 7.8315C68.0572 7.04662 68.2665 6.50592 68.6677 6.20941C69.0688 5.9129 69.7491 5.77336 70.7084 5.77336C71.1095 5.77336 71.5107 5.7908 71.9119 5.82569C72.313 5.86057 72.7316 5.89545 73.1677 5.96522V5.35476C72.4351 5.2501 71.9293 5.19778 71.6328 5.18034C71.3363 5.1629 71.0223 5.1629 70.6735 5.1629C69.5398 5.1629 68.7026 5.3722 68.1793 5.7908C67.656 6.20941 67.3944 6.88964 67.3944 7.86638C67.3944 8.45941 67.534 8.9129 67.7956 9.22685C68.0572 9.5408 68.4933 9.7501 69.0688 9.85476L71.4933 10.2385C72.1212 10.3431 72.5572 10.5175 72.784 10.7617C73.0107 10.9885 73.1328 11.3722 73.1328 11.9129C73.1328 12.6629 72.9409 13.2036 72.5747 13.535C72.1909 13.8664 71.563 14.0234 70.656 14.0234C69.9758 14.0234 69.3305 13.9885 68.72 13.9013C68.1095 13.8141 67.6212 13.692 67.2549 13.5524V14.1978C67.6212 14.3373 68.127 14.442 68.7374 14.5292C69.3479 14.6164 70.0107 14.6513 70.7258 14.6513C71.8072 14.6513 72.5921 14.4245 73.0805 13.9885C73.5688 13.5524 73.813 12.8548 73.813 11.878C73.813 11.1455 73.656 10.6222 73.3595 10.2908C73.0456 9.97685 72.5049 9.7501 71.7374 9.61057Z"
                    fill="#808285"
                />
                <path
                    d="M27.2962 19.1686V23.1802H26.9299V19.1686H27.2962Z"
                    fill="#808285"
                />
                <path
                    d="M30.5926 21.6802C30.5926 22.2384 30.488 22.6395 30.2961 22.8663C30.0868 23.093 29.7554 23.2151 29.2496 23.2151C28.7264 23.2151 28.3775 23.1105 28.1857 22.8837C27.9938 22.657 27.8892 22.2558 27.8892 21.6802C27.8892 21.1047 27.9938 20.7209 28.1857 20.4767C28.395 20.25 28.7438 20.1279 29.2322 20.1279C29.738 20.1279 30.1043 20.2326 30.2961 20.4593C30.488 20.686 30.5926 21.0872 30.5926 21.6802ZM28.2554 21.6802C28.2554 22.1686 28.3252 22.4826 28.4473 22.6395C28.5694 22.7965 28.8485 22.8837 29.2322 22.8837C29.6159 22.8837 29.8601 22.7965 29.9996 22.6221C30.1392 22.4477 30.2089 22.1337 30.2089 21.6802C30.2089 21.1919 30.1392 20.8779 30.0171 20.7209C29.895 20.564 29.6333 20.4767 29.2322 20.4767C28.8485 20.4767 28.5868 20.564 28.4473 20.7209C28.3252 20.8953 28.2554 21.2093 28.2554 21.6802Z"
                    fill="#808285"
                />
                <path
                    d="M33.2439 22.6744V22.9884C33.1393 23.0581 32.9823 23.1105 32.7904 23.1453C32.5986 23.1802 32.3893 23.2151 32.18 23.2151C31.7614 23.2151 31.4649 23.093 31.2904 22.8663C31.116 22.6395 31.0288 22.2384 31.0288 21.6628C31.0288 21.1047 31.1335 20.7209 31.3602 20.4767C31.5869 20.2326 31.9532 20.1279 32.4765 20.1279C32.5986 20.1279 32.7032 20.1279 32.7904 20.1453C32.8776 20.1453 32.9997 20.1628 33.1567 20.1802V20.5116C32.9649 20.4942 32.8253 20.4767 32.7381 20.4767C32.6683 20.4767 32.5811 20.4767 32.4765 20.4767C32.0753 20.4767 31.7963 20.564 31.6393 20.7384C31.4823 20.9128 31.4125 21.2267 31.4125 21.6802C31.4125 22.1337 31.4649 22.4477 31.587 22.6221C31.709 22.7965 31.9358 22.8837 32.2497 22.8837C32.4242 22.8837 32.5986 22.8663 32.773 22.8314C32.9823 22.7791 33.1218 22.7267 33.2439 22.6744Z"
                    fill="#808285"
                />
                <path
                    d="M34.3944 23.2153C34.0805 23.2153 33.8712 23.1455 33.7142 23.006C33.5747 22.8665 33.5049 22.6397 33.5049 22.3258C33.5049 22.0118 33.5921 21.7851 33.7665 21.663C33.9409 21.5234 34.2374 21.4711 34.6386 21.4711C34.7956 21.4711 34.9351 21.4711 35.0747 21.4885C35.2142 21.506 35.3537 21.5234 35.4758 21.5409V21.2269C35.4758 20.9653 35.4235 20.7734 35.3014 20.6688C35.1793 20.5641 34.9874 20.4944 34.7084 20.4944C34.5165 20.4944 34.3595 20.4944 34.22 20.5118C34.0805 20.5292 33.9409 20.5467 33.8014 20.599V20.2676C33.9758 20.2327 34.1328 20.1978 34.2898 20.1804C34.4467 20.163 34.6212 20.163 34.8305 20.163C35.1793 20.163 35.4409 20.2502 35.6153 20.4072C35.7898 20.5816 35.8595 20.8432 35.8595 21.192V23.1978H35.5281L35.5107 22.9013H35.4933C35.3712 23.006 35.2142 23.0758 35.0049 23.1455C34.813 23.1978 34.6037 23.2153 34.3944 23.2153ZM34.6909 21.7502C34.377 21.7502 34.1677 21.7851 34.0456 21.8723C33.9235 21.9595 33.8537 22.099 33.8537 22.2909C33.8537 22.5002 33.906 22.6572 34.0107 22.7444C34.1153 22.8316 34.2723 22.8839 34.4991 22.8839C34.6735 22.8839 34.8653 22.849 35.0398 22.7967C35.2142 22.7444 35.3712 22.6746 35.4758 22.5874V21.8199C35.3363 21.8025 35.2142 21.7851 35.0747 21.7676C34.97 21.7676 34.8305 21.7502 34.6909 21.7502Z"
                    fill="#808285"
                />
                <path
                    d="M38.2495 22.814V23.1279C38.1797 23.1453 38.0925 23.1802 37.9878 23.1802C37.8832 23.1977 37.7785 23.1977 37.6913 23.1977C37.3425 23.1977 37.0809 23.1105 36.9413 22.9535C36.7844 22.7965 36.7146 22.5174 36.7146 22.1163V20.4942H36.2437V20.1628H36.7146L36.7844 19.4302H37.0809V20.1628H38.2146V20.4942H37.0809V22.1163C37.0809 22.3953 37.1332 22.5872 37.2378 22.6919C37.3425 22.7965 37.5169 22.8488 37.7611 22.8488C37.8483 22.8488 37.9355 22.8488 38.0227 22.8314C38.1099 22.8488 38.1797 22.8314 38.2495 22.814Z"
                    fill="#808285"
                />
                <path
                    d="M39.035 19.5872H38.6338V19.1686H39.035V19.5872ZM39.0175 23.1802H38.6512V20.1977H39.0175V23.1802Z"
                    fill="#808285"
                />
                <path
                    d="M42.3129 21.6802C42.3129 22.2384 42.2082 22.6395 42.0164 22.8663C41.807 23.093 41.4757 23.2151 40.9698 23.2151C40.4466 23.2151 40.0977 23.1105 39.9059 22.8837C39.714 22.657 39.6094 22.2558 39.6094 21.6802C39.6094 21.1047 39.714 20.7209 39.9059 20.4767C40.1152 20.25 40.464 20.1279 40.9524 20.1279C41.4582 20.1279 41.8245 20.2326 42.0164 20.4593C42.2082 20.686 42.3129 21.0872 42.3129 21.6802ZM39.9757 21.6802C39.9757 22.1686 40.0454 22.4826 40.1675 22.6395C40.2896 22.7965 40.5687 22.8837 40.9524 22.8837C41.3361 22.8837 41.5803 22.7965 41.7198 22.6221C41.8594 22.4477 41.9291 22.1337 41.9291 21.6802C41.9291 21.1919 41.8594 20.8779 41.7373 20.7209C41.6152 20.564 41.3536 20.4767 40.9524 20.4767C40.5687 20.4767 40.3071 20.564 40.1675 20.7209C40.0454 20.8953 39.9757 21.2093 39.9757 21.6802Z"
                    fill="#808285"
                />
                <path
                    d="M43.2907 23.1802H42.907V20.1977H43.2209L43.2384 20.4942H43.2558C43.4302 20.3721 43.6047 20.2849 43.7965 20.2326C43.9709 20.1802 44.1802 20.1453 44.3895 20.1453C44.7209 20.1453 44.9651 20.2326 45.1395 20.3895C45.314 20.5465 45.4012 20.7907 45.4012 21.1221V23.1802H45.0349V21.157C45.0349 20.9302 44.9651 20.7558 44.843 20.6512C44.7209 20.5465 44.5291 20.4767 44.2674 20.4767C44.1105 20.4767 43.9535 20.4942 43.7791 20.5465C43.6221 20.5988 43.4477 20.6686 43.2733 20.7733V23.1802H43.2907Z"
                    fill="#808285"
                />
                <path
                    d="M47.7913 19.5872H47.3901V19.1686H47.7913V19.5872ZM47.7739 23.1802H47.4076V20.1977H47.7739V23.1802Z"
                    fill="#808285"
                />
                <path
                    d="M48.89 23.1802H48.5237V20.1977H48.8376L48.8551 20.4942H48.8725C49.0469 20.3721 49.2214 20.2849 49.4132 20.2326C49.5876 20.1802 49.7969 20.1453 50.0062 20.1453C50.3376 20.1453 50.5818 20.2326 50.7562 20.3895C50.9307 20.5465 51.0179 20.7907 51.0179 21.1221V23.1802H50.6516V21.157C50.6516 20.9302 50.5818 20.7558 50.4597 20.6512C50.3376 20.5465 50.1458 20.4767 49.8841 20.4767C49.7272 20.4767 49.5702 20.4942 49.3958 20.5465C49.2388 20.5988 49.0644 20.6686 48.89 20.7733V23.1802Z"
                    fill="#808285"
                />
                <path
                    d="M53.372 22.814V23.1279C53.3023 23.1453 53.215 23.1802 53.1104 23.1802C53.0057 23.1977 52.9011 23.1977 52.8139 23.1977C52.465 23.1977 52.2034 23.1105 52.0639 22.9535C51.9069 22.7965 51.8371 22.5174 51.8371 22.1163V20.4942H51.3662V20.1628H51.8371L51.9069 19.4302H52.2034V20.1628H53.3371V20.4942H52.2034V22.1163C52.2034 22.3953 52.2557 22.5872 52.3604 22.6919C52.4651 22.7965 52.6395 22.8488 52.8837 22.8488C52.9709 22.8488 53.0581 22.8488 53.1453 22.8314C53.2325 22.8488 53.3197 22.8314 53.372 22.814Z"
                    fill="#808285"
                />
                <path
                    d="M55.9002 22.692V23.006C55.7955 23.0583 55.6386 23.1106 55.4467 23.1455C55.2549 23.1804 55.063 23.1979 54.8537 23.1979C54.4002 23.1979 54.0688 23.0758 53.8595 22.8316C53.6676 22.5874 53.563 22.1862 53.563 21.6455C53.563 21.1048 53.6676 20.7211 53.8595 20.4769C54.0688 20.2327 54.3828 20.1106 54.8537 20.1106C55.2723 20.1106 55.5688 20.2153 55.7432 20.4072C55.9176 20.599 56.0223 20.913 56.0223 21.349C56.0223 21.4188 56.0223 21.4885 56.0223 21.5409C56.0223 21.6106 56.0048 21.6804 56.0048 21.7676H53.9642C53.9642 22.1513 54.0339 22.4304 54.1909 22.5874C54.3304 22.7618 54.5746 22.8316 54.9409 22.8316C55.1153 22.8316 55.2897 22.8141 55.4642 22.7792C55.6211 22.7967 55.7781 22.7444 55.9002 22.692ZM55.6909 21.3316C55.6909 21.0351 55.6211 20.8083 55.499 20.6862C55.3769 20.5467 55.1676 20.4944 54.8711 20.4944C54.5397 20.4944 54.313 20.5641 54.1735 20.7211C54.0339 20.8781 53.9642 21.1397 53.9642 21.506H55.6909C55.6909 21.4885 55.6909 21.4537 55.7083 21.4362C55.6909 21.3839 55.6909 21.349 55.6909 21.3316Z"
                    fill="#808285"
                />
                <path
                    d="M56.9815 19.1686V23.1802H56.6152V19.1686H56.9815Z"
                    fill="#808285"
                />
                <path
                    d="M58.0811 19.1686V23.1802H57.7148V19.1686H58.0811Z"
                    fill="#808285"
                />
                <path
                    d="M59.2156 19.5872H58.8145V19.1686H59.2156V19.5872ZM59.1982 23.1802H58.8319V20.1977H59.1982V23.1802Z"
                    fill="#808285"
                />
                <path
                    d="M61.1167 24.157C60.9248 24.157 60.733 24.1395 60.5586 24.1221C60.3841 24.1047 60.2446 24.0523 60.14 24.0174V23.686C60.2272 23.7209 60.3667 23.7558 60.5411 23.7733C60.7155 23.8081 60.89 23.8081 61.0644 23.8081C61.3958 23.8081 61.6225 23.7384 61.7446 23.6163C61.8667 23.4767 61.9365 23.25 61.9365 22.9012V22.6919C61.7795 22.7965 61.6225 22.8663 61.4481 22.9012C61.2737 22.9535 61.0993 22.9709 60.9074 22.9709C60.5062 22.9709 60.2097 22.8663 60.0353 22.6395C59.8609 22.4128 59.7737 22.064 59.7737 21.5581C59.7737 21.0523 59.8783 20.686 60.1051 20.4593C60.3144 20.25 60.6981 20.1279 61.2214 20.1279C61.3958 20.1279 61.5876 20.1453 61.7969 20.1628C61.9888 20.1802 62.1632 20.2151 62.3028 20.25V22.7965C62.3028 23.3023 62.2155 23.6512 62.0411 23.8605C61.8667 24.0523 61.5528 24.157 61.1167 24.157ZM61.2388 20.4767C60.8202 20.4767 60.5411 20.5465 60.3841 20.7035C60.2446 20.8605 60.1574 21.1395 60.1574 21.5581C60.1574 21.9593 60.2272 22.2209 60.3493 22.3953C60.4714 22.5523 60.6981 22.6395 61.0295 22.6395C61.1865 22.6395 61.3434 22.6221 61.483 22.5698C61.64 22.5349 61.7795 22.4651 61.919 22.3779V20.564C61.8144 20.5465 61.7097 20.5291 61.5876 20.5116C61.483 20.4767 61.3609 20.4767 61.2388 20.4767Z"
                    fill="#808285"
                />
                <path
                    d="M65.2315 22.692V23.006C65.1268 23.0583 64.9699 23.1106 64.778 23.1455C64.5862 23.1804 64.3943 23.1979 64.185 23.1979C63.7315 23.1979 63.4001 23.0758 63.1908 22.8316C62.9989 22.5874 62.8943 22.1862 62.8943 21.6455C62.8943 21.1048 62.9989 20.7211 63.1908 20.4769C63.4001 20.2327 63.7141 20.1106 64.185 20.1106C64.6036 20.1106 64.9001 20.2153 65.0745 20.4072C65.2489 20.599 65.3536 20.913 65.3536 21.349C65.3536 21.4188 65.3536 21.4885 65.3536 21.5409C65.3536 21.6106 65.3361 21.6804 65.3361 21.7676H63.2955C63.2955 22.1513 63.3827 22.4304 63.5222 22.5874C63.6617 22.7618 63.9059 22.8316 64.2722 22.8316C64.4466 22.8316 64.621 22.8141 64.7954 22.7792C64.9524 22.7967 65.1094 22.7444 65.2315 22.692ZM65.0048 21.3316C65.0048 21.0351 64.935 20.8083 64.8129 20.6862C64.6908 20.5467 64.4815 20.4944 64.185 20.4944C63.8536 20.4944 63.6268 20.5641 63.4873 20.7211C63.3478 20.8781 63.278 21.1397 63.278 21.506H65.0048C65.0048 21.4885 65.0048 21.4537 65.0222 21.4362C65.0048 21.3839 65.0048 21.349 65.0048 21.3316Z"
                    fill="#808285"
                />
                <path
                    d="M66.2965 23.1802H65.9302V20.1977H66.2441L66.2616 20.4942H66.279C66.4534 20.3721 66.6279 20.2849 66.8197 20.2326C66.9941 20.1802 67.2034 20.1453 67.4127 20.1453C67.7441 20.1453 67.9883 20.2326 68.1627 20.3895C68.3372 20.5465 68.4244 20.7907 68.4244 21.1221V23.1802H68.0581V21.157C68.0581 20.9302 67.9883 20.7558 67.8662 20.6512C67.7441 20.5465 67.5523 20.4767 67.2906 20.4767C67.1337 20.4767 66.9767 20.4942 66.8023 20.5465C66.6453 20.5988 66.4709 20.6686 66.2965 20.7733V23.1802Z"
                    fill="#808285"
                />
                <path
                    d="M71.18 22.6744V22.9884C71.0753 23.0581 70.9183 23.1105 70.7265 23.1453C70.5346 23.1802 70.3253 23.2151 70.116 23.2151C69.6974 23.2151 69.4009 23.093 69.2265 22.8663C69.0521 22.6395 68.9648 22.2384 68.9648 21.6628C68.9648 21.1047 69.0695 20.7209 69.2962 20.4767C69.523 20.2326 69.8893 20.1279 70.4125 20.1279C70.5346 20.1279 70.6393 20.1279 70.7265 20.1453C70.8137 20.1453 70.9358 20.1628 71.0927 20.1802V20.5116C70.9009 20.4942 70.7614 20.4767 70.6741 20.4767C70.6044 20.4767 70.4997 20.4767 70.4125 20.4767C70.0114 20.4767 69.7323 20.564 69.5753 20.7384C69.4183 20.9128 69.3486 21.2267 69.3486 21.6802C69.3486 22.1337 69.4009 22.4477 69.523 22.6221C69.6451 22.7965 69.8718 22.8837 70.1858 22.8837C70.3602 22.8837 70.5346 22.8663 70.709 22.8314C70.9183 22.7791 71.0753 22.7267 71.18 22.6744Z"
                    fill="#808285"
                />
                <path
                    d="M73.8311 22.692V23.006C73.7265 23.0583 73.5695 23.1106 73.3776 23.1455C73.1858 23.1804 72.9939 23.1979 72.7846 23.1979C72.3311 23.1979 71.9997 23.0758 71.7904 22.8316C71.5985 22.5874 71.4939 22.1862 71.4939 21.6455C71.4939 21.1048 71.5985 20.7211 71.7904 20.4769C71.9997 20.2327 72.3137 20.1106 72.7846 20.1106C73.2032 20.1106 73.4997 20.2153 73.6741 20.4072C73.8485 20.599 73.9532 20.913 73.9532 21.349C73.9532 21.4188 73.9532 21.4885 73.9532 21.5409C73.9532 21.6106 73.9358 21.6804 73.9358 21.7676H71.8951C71.8951 22.1513 71.9823 22.4304 72.1218 22.5874C72.2613 22.7618 72.5055 22.8316 72.8718 22.8316C73.0462 22.8316 73.2206 22.8141 73.3951 22.7792C73.552 22.7967 73.709 22.7444 73.8311 22.692ZM73.6218 21.3316C73.6218 21.0351 73.552 20.8083 73.4299 20.6862C73.3078 20.5467 73.0985 20.4944 72.802 20.4944C72.4706 20.4944 72.2439 20.5641 72.1044 20.7211C71.9648 20.8781 71.8951 21.1397 71.8951 21.506H73.6218C73.6218 21.4885 73.6218 21.4537 73.6392 21.4362C73.6218 21.3839 73.6218 21.349 73.6218 21.3316Z"
                    fill="#808285"
                />
                <path
                    d="M38.1453 28.3779V28.6919C38.0406 28.7616 37.8837 28.814 37.6918 28.8488C37.4999 28.8837 37.2906 28.9186 37.0813 28.9186C36.6627 28.9186 36.3662 28.7965 36.1918 28.5698C36.0174 28.343 35.9302 27.9419 35.9302 27.3663C35.9302 26.8081 36.0348 26.4244 36.2616 26.1802C36.4883 25.936 36.8546 25.8314 37.3779 25.8314C37.4999 25.8314 37.6046 25.8314 37.6918 25.8488C37.779 25.8488 37.9011 25.8663 38.0581 25.8837V26.2151C37.8662 26.1977 37.7267 26.1802 37.6395 26.1802C37.5697 26.1802 37.4651 26.1802 37.3779 26.1802C36.9767 26.1802 36.6976 26.2674 36.5406 26.4419C36.3837 26.6163 36.3139 26.9302 36.3139 27.3837C36.3139 27.8372 36.3662 28.1512 36.4883 28.3256C36.6104 28.5 36.8372 28.5872 37.1511 28.5872C37.3255 28.5872 37.4999 28.5698 37.6744 28.5349C37.8837 28.4826 38.0406 28.4302 38.1453 28.3779Z"
                    fill="#808285"
                />
                <path
                    d="M40.7964 28.3953V28.7093C40.6918 28.7616 40.5348 28.814 40.343 28.8488C40.1511 28.8837 39.9592 28.9012 39.7499 28.9012C39.2964 28.9012 38.965 28.7791 38.7557 28.5349C38.5639 28.2907 38.4592 27.8895 38.4592 27.3488C38.4592 26.8081 38.5639 26.4244 38.7557 26.1802C38.965 25.936 39.279 25.814 39.7499 25.814C40.1685 25.814 40.465 25.9186 40.6395 26.1105C40.8139 26.3023 40.9185 26.6163 40.9185 27.0523C40.9185 27.1221 40.9185 27.1919 40.9185 27.2442C40.9185 27.314 40.9011 27.3837 40.9011 27.4709H38.8604C38.8604 27.8547 38.9476 28.1337 39.0871 28.2907C39.2267 28.4651 39.4709 28.5349 39.8371 28.5349C40.0116 28.5349 40.186 28.5174 40.3604 28.4826C40.5174 28.5 40.6743 28.4477 40.7964 28.3953ZM40.5871 27.0349C40.5871 26.7384 40.5174 26.5116 40.3953 26.3895C40.2732 26.25 40.0639 26.1977 39.7674 26.1977C39.436 26.1977 39.2092 26.2674 39.0697 26.4244C38.9302 26.5814 38.8604 26.843 38.8604 27.2093H40.5871C40.5871 27.1919 40.5871 27.157 40.6046 27.1395C40.5871 27.0872 40.5871 27.0523 40.5871 27.0349Z"
                    fill="#808285"
                />
                <path
                    d="M27.8884 26.1804C27.6094 26.1804 27.4175 26.2153 27.2954 26.2851C27.1908 26.3548 27.121 26.5118 27.121 26.7211C27.121 26.8432 27.1559 26.9304 27.1908 26.9827C27.2431 27.0351 27.3303 27.0874 27.4524 27.1048L28.2373 27.2269C28.4815 27.2618 28.6559 27.3316 28.7431 27.4537C28.8477 27.5583 28.8826 27.7327 28.8826 27.9769C28.8826 28.3083 28.7954 28.5525 28.6384 28.692C28.4815 28.849 28.2198 28.9188 27.8361 28.9188C27.6094 28.9188 27.4001 28.9013 27.1908 28.8839C26.9815 28.8665 26.8245 28.8316 26.7024 28.7792V28.4304C26.8245 28.4827 26.9815 28.5176 27.1733 28.5351C27.3652 28.5699 27.5745 28.5699 27.7838 28.5699C28.0454 28.5699 28.2373 28.5351 28.3419 28.4478C28.4466 28.3606 28.4989 28.2037 28.4989 27.9944C28.4989 27.8548 28.464 27.7502 28.4117 27.6804C28.3594 27.6106 28.2373 27.5758 28.0629 27.5409L27.2954 27.4188C27.1036 27.3839 26.964 27.3141 26.8594 27.2095C26.7722 27.1048 26.7198 26.9304 26.7198 26.7211C26.7198 26.4072 26.807 26.1804 26.9815 26.0409C27.1559 25.9013 27.435 25.8316 27.8187 25.8316C27.9756 25.8316 28.1152 25.8316 28.2547 25.849C28.3768 25.849 28.5163 25.8665 28.6559 25.9013V26.2327C28.5512 26.2153 28.4117 26.1978 28.2722 26.1804C28.2024 26.1804 28.0454 26.1804 27.8884 26.1804Z"
                    fill="#808285"
                />
                <path
                    d="M29.9466 29.7907H29.5803V25.9012H29.8943L29.9117 26.1802H29.9292C30.0687 26.0756 30.2257 25.9884 30.4001 25.936C30.5745 25.8837 30.7664 25.8488 30.9582 25.8488C31.3768 25.8488 31.6559 25.9709 31.8478 26.2151C32.0222 26.4593 32.1094 26.843 32.1094 27.4012C32.1094 27.9419 32.0047 28.343 31.7954 28.5872C31.5861 28.8314 31.2199 28.9535 30.714 28.9535C30.5571 28.9535 30.4175 28.936 30.278 28.9186C30.1559 28.9012 30.0338 28.8663 29.9292 28.814V29.7907H29.9466ZM31.7431 27.3837C31.7431 26.9477 31.6733 26.6337 31.5513 26.4419C31.4292 26.25 31.185 26.1628 30.8361 26.1628C30.6792 26.1628 30.5047 26.1802 30.3652 26.2326C30.2082 26.2849 30.0687 26.3547 29.9466 26.4419V28.4302C30.0513 28.4826 30.1559 28.5174 30.278 28.5349C30.4001 28.5523 30.5396 28.5698 30.6792 28.5698C31.0978 28.5698 31.3768 28.4826 31.5164 28.3081C31.6733 28.1337 31.7431 27.8198 31.7431 27.3837Z"
                    fill="#808285"
                />
                <path
                    d="M33.5221 28.9188C33.2082 28.9188 32.9988 28.849 32.8419 28.7095C32.7023 28.5699 32.6326 28.3432 32.6326 28.0292C32.6326 27.7153 32.7198 27.4885 32.8942 27.3665C33.0686 27.2269 33.3651 27.1746 33.7663 27.1746C33.9233 27.1746 34.0628 27.1746 34.2023 27.192C34.3419 27.2095 34.4814 27.2269 34.6035 27.2444V26.9304C34.6035 26.6688 34.5512 26.4769 34.4291 26.3723C34.307 26.2676 34.1151 26.1979 33.8361 26.1979C33.6442 26.1979 33.4872 26.1979 33.3477 26.2153C33.2082 26.2327 33.0686 26.2502 32.9291 26.3025V25.9711C33.1035 25.9362 33.2605 25.9013 33.4175 25.8839C33.5744 25.8665 33.7488 25.8665 33.9582 25.8665C34.307 25.8665 34.5686 25.9537 34.743 26.1106C34.9175 26.2851 34.9872 26.5467 34.9872 26.8955V28.9013H34.6558L34.6384 28.6048H34.6209C34.4988 28.7095 34.3419 28.7792 34.1326 28.849C33.9233 28.8839 33.7314 28.9188 33.5221 28.9188ZM33.8186 27.4537C33.5047 27.4537 33.2954 27.4885 33.1733 27.5758C33.0512 27.663 32.9814 27.8025 32.9814 27.9944C32.9814 28.2037 33.0337 28.3606 33.1384 28.4479C33.243 28.5351 33.4 28.5874 33.6268 28.5874C33.8012 28.5874 33.993 28.5525 34.1675 28.5002C34.3419 28.4479 34.4988 28.3781 34.6035 28.2909V27.5234C34.464 27.506 34.3419 27.4886 34.2023 27.4711C34.0802 27.4537 33.9582 27.4537 33.8186 27.4537Z"
                    fill="#808285"
                />
                <path
                    d="M44.7548 28.5176V28.8316C44.6851 28.849 44.5979 28.8839 44.4932 28.8839C44.3886 28.9013 44.2839 28.9013 44.1967 28.9013C43.8479 28.9013 43.5862 28.8141 43.4467 28.6572C43.2897 28.5002 43.22 28.2211 43.22 27.8199V26.1979H42.749V25.8665H43.22L43.2897 25.1339H43.5862V25.8665H44.72V26.1979H43.5862V27.8199C43.5862 28.099 43.6386 28.2909 43.7432 28.3955C43.8479 28.5002 44.0223 28.5525 44.2665 28.5525C44.3537 28.5525 44.4409 28.5525 44.5281 28.5351C44.6153 28.5351 44.7025 28.5351 44.7548 28.5176Z"
                    fill="#808285"
                />
                <path
                    d="M47.4244 28.3953V28.7093C47.3197 28.7616 47.1627 28.814 46.9709 28.8488C46.779 28.8837 46.5872 28.9012 46.3779 28.9012C45.9244 28.9012 45.593 28.7791 45.3837 28.5349C45.1918 28.2907 45.0872 27.8895 45.0872 27.3488C45.0872 26.8081 45.1918 26.4244 45.3837 26.1802C45.593 25.936 45.9069 25.814 46.3779 25.814C46.7965 25.814 47.093 25.9186 47.2674 26.1105C47.4418 26.3023 47.5465 26.6163 47.5465 27.0523C47.5465 27.1221 47.5465 27.1919 47.5465 27.2442C47.5465 27.314 47.529 27.3837 47.529 27.4709H45.4883C45.4883 27.8547 45.5581 28.1337 45.7151 28.2907C45.8546 28.4651 46.0988 28.5349 46.4651 28.5349C46.6395 28.5349 46.8139 28.5174 46.9883 28.4826C47.1453 28.4826 47.3023 28.4477 47.4244 28.3953ZM47.1976 27.0174C47.1976 26.7209 47.1279 26.4942 47.0058 26.3721C46.8837 26.2326 46.6744 26.1802 46.3779 26.1802C46.0465 26.1802 45.8197 26.25 45.6802 26.407C45.5406 26.564 45.4709 26.8256 45.4709 27.1919H47.1976C47.1976 27.1744 47.1976 27.1395 47.2151 27.1221C47.1976 27.0698 47.1976 27.0523 47.1976 27.0174Z"
                    fill="#808285"
                />
                <path
                    d="M48.6285 28.8663H48.2622V24.8547H48.6285V26.1453C48.8378 26.0407 49.0296 25.9535 49.2041 25.9012C49.3785 25.8488 49.5703 25.8314 49.7448 25.8314C50.0762 25.8314 50.3203 25.9186 50.4948 26.0756C50.6692 26.2326 50.7564 26.4767 50.7564 26.8081V28.8663H50.3901V26.8605C50.3901 26.6337 50.3378 26.4593 50.2157 26.3372C50.0936 26.2326 49.9017 26.1628 49.6576 26.1628C49.5006 26.1628 49.3262 26.1802 49.1517 26.2326C48.9773 26.2849 48.8203 26.3547 48.6459 26.4419V28.8663H48.6285Z"
                    fill="#808285"
                />
                <path
                    d="M51.9588 28.8665H51.5925V25.8839H51.9065L51.9239 26.1804H51.9414C52.1158 26.0583 52.2902 25.9711 52.4821 25.9188C52.6565 25.8665 52.8658 25.8316 53.0751 25.8316C53.4065 25.8316 53.6507 25.9188 53.8251 26.0758C53.9995 26.2327 54.0867 26.4769 54.0867 26.8083V28.8665H53.7204V26.8606C53.7204 26.6339 53.6507 26.4595 53.5286 26.3548C53.4065 26.2502 53.2146 26.1804 52.953 26.1804C52.796 26.1804 52.639 26.1979 52.4646 26.2502C52.2902 26.3025 52.1332 26.3723 51.9588 26.4769V28.8665Z"
                    fill="#808285"
                />
                <path
                    d="M57.4874 27.3839C57.4874 27.942 57.3828 28.3432 57.1909 28.5699C56.9816 28.7967 56.6502 28.9188 56.1444 28.9188C55.6211 28.9188 55.2723 28.8141 55.0804 28.5874C54.8886 28.3606 54.7839 27.9595 54.7839 27.3839C54.7839 26.8083 54.8886 26.4246 55.0804 26.1804C55.2898 25.9537 55.6386 25.8316 56.127 25.8316C56.6328 25.8316 56.9991 25.9362 57.1909 26.163C57.3828 26.3897 57.4874 26.7909 57.4874 27.3839ZM55.1502 27.3665C55.1502 27.8548 55.22 28.1688 55.3421 28.3258C55.4642 28.4827 55.7432 28.5699 56.127 28.5699C56.5107 28.5699 56.7549 28.4827 56.8944 28.3083C57.0339 28.1339 57.1037 27.8199 57.1037 27.3665C57.1037 26.8781 57.0339 26.5641 56.9118 26.4072C56.7898 26.2502 56.5281 26.163 56.127 26.163C55.7432 26.163 55.4816 26.2502 55.3421 26.4072C55.22 26.5816 55.1502 26.913 55.1502 27.3665Z"
                    fill="#808285"
                />
                <path
                    d="M58.5867 24.8723V28.8839H58.2205V24.8723H58.5867Z"
                    fill="#808285"
                />
                <path
                    d="M62.0221 27.3839C62.0221 27.942 61.9174 28.3432 61.7256 28.5699C61.5163 28.7967 61.1849 28.9188 60.6791 28.9188C60.1558 28.9188 59.807 28.8141 59.6151 28.5874C59.4233 28.3606 59.3186 27.9595 59.3186 27.3839C59.3186 26.8083 59.4233 26.4246 59.6151 26.1804C59.8244 25.9537 60.1733 25.8316 60.6616 25.8316C61.1674 25.8316 61.5337 25.9362 61.7256 26.163C61.9174 26.3897 62.0221 26.7909 62.0221 27.3839ZM59.7023 27.3665C59.7023 27.8548 59.7721 28.1688 59.8942 28.3258C60.0163 28.4827 60.2954 28.5699 60.6791 28.5699C61.0628 28.5699 61.307 28.4827 61.4465 28.3083C61.586 28.1339 61.6558 27.8199 61.6558 27.3665C61.6558 26.8781 61.586 26.5641 61.464 26.4072C61.3419 26.2502 61.0802 26.163 60.6791 26.163C60.2954 26.163 60.0337 26.2502 59.8942 26.4072C59.7721 26.5816 59.7023 26.913 59.7023 27.3665Z"
                    fill="#808285"
                />
                <path
                    d="M63.9414 29.843C63.7495 29.843 63.5577 29.8256 63.3833 29.8081C63.2089 29.7733 63.0693 29.7384 62.9647 29.7035V29.3721C63.0519 29.407 63.1914 29.4419 63.3658 29.4593C63.5402 29.4942 63.7147 29.4942 63.8891 29.4942C64.2205 29.4942 64.4472 29.4244 64.5693 29.3023C64.6914 29.1628 64.7612 28.936 64.7612 28.5872V28.3779C64.6042 28.4826 64.4472 28.5523 64.2728 28.5872C64.0984 28.6395 63.924 28.657 63.7321 28.657C63.3309 28.657 63.0344 28.5523 62.86 28.3256C62.6856 28.0988 62.5984 27.75 62.5984 27.2442C62.5984 26.7384 62.703 26.3721 62.9298 26.1453C63.1391 25.936 63.5228 25.814 64.0461 25.814C64.2205 25.814 64.4123 25.8314 64.6216 25.8488C64.8135 25.8663 64.9879 25.9012 65.1275 25.936V28.4826C65.1275 28.9884 65.0402 29.3372 64.8658 29.5465C64.6914 29.7558 64.3775 29.843 63.9414 29.843ZM64.0635 26.1802C63.6449 26.1802 63.3658 26.25 63.2089 26.407C63.0693 26.564 62.9821 26.843 62.9821 27.2616C62.9821 27.6628 63.0519 27.9244 63.174 28.0988C63.2961 28.2558 63.5228 28.343 63.8542 28.343C64.0112 28.343 64.1682 28.3256 64.3077 28.2733C64.4647 28.2384 64.6042 28.1686 64.7437 28.0814V26.2674C64.6391 26.25 64.5344 26.2326 64.4123 26.2151C64.3077 26.1802 64.1856 26.1802 64.0635 26.1802Z"
                    fill="#808285"
                />
                <path
                    d="M66.4009 25.2733H65.9998V24.8547H66.4009V25.2733ZM66.3835 28.8663H66.0172V25.8837H66.3835V28.8663Z"
                    fill="#808285"
                />
                <path
                    d="M69.4366 28.3953V28.7093C69.3319 28.7616 69.1749 28.814 68.9831 28.8488C68.7912 28.8837 68.5994 28.9012 68.3901 28.9012C67.9366 28.9012 67.6052 28.7791 67.3959 28.5349C67.204 28.2907 67.0994 27.8895 67.0994 27.3488C67.0994 26.8081 67.204 26.4244 67.3959 26.1802C67.6052 25.936 67.9191 25.814 68.3901 25.814C68.8087 25.814 69.1052 25.9186 69.2796 26.1105C69.454 26.3023 69.5587 26.6163 69.5587 27.0523C69.5587 27.1221 69.5587 27.1919 69.5587 27.2442C69.5587 27.314 69.5412 27.3837 69.5412 27.4709H67.5005C67.5005 27.8547 67.5877 28.1337 67.7273 28.2907C67.8668 28.4651 68.111 28.5349 68.4773 28.5349C68.6517 28.5349 68.8261 28.5174 69.0005 28.4826C69.1575 28.4826 69.297 28.4477 69.4366 28.3953ZM69.2098 27.0174C69.2098 26.7209 69.1401 26.4942 69.018 26.3721C68.8959 26.2326 68.6866 26.1802 68.3901 26.1802C68.0587 26.1802 67.8319 26.25 67.6924 26.407C67.5529 26.564 67.4831 26.8256 67.4831 27.1919H69.2098C69.2098 27.1744 69.2098 27.1395 69.2273 27.1221C69.2098 27.0698 69.2098 27.0523 69.2098 27.0174Z"
                    fill="#808285"
                />
                <path
                    d="M71.2505 26.1804C70.9714 26.1804 70.7796 26.2153 70.6575 26.2851C70.5528 26.3548 70.4831 26.5118 70.4831 26.7211C70.4831 26.8432 70.5005 26.9304 70.5528 26.9827C70.6051 27.0351 70.6924 27.0874 70.8145 27.1048L71.5993 27.2269C71.8435 27.2618 72.0179 27.3316 72.1052 27.4537C72.2098 27.5583 72.2447 27.7327 72.2447 27.9769C72.2447 28.3083 72.1575 28.5525 72.0005 28.692C71.8435 28.849 71.5819 28.9188 71.1982 28.9188C70.9714 28.9188 70.7621 28.9013 70.5528 28.8839C70.3435 28.849 70.1865 28.8316 70.0645 28.7792V28.4304C70.1865 28.4827 70.3435 28.5176 70.5354 28.5351C70.7272 28.5699 70.9365 28.5699 71.1459 28.5699C71.4075 28.5699 71.5993 28.5351 71.704 28.4478C71.8086 28.3606 71.861 28.2037 71.861 27.9944C71.861 27.8548 71.8261 27.7502 71.7738 27.6804C71.7214 27.6106 71.5993 27.5758 71.4249 27.5409L70.6575 27.4188C70.4656 27.3839 70.3261 27.3141 70.2214 27.2095C70.1342 27.1048 70.0819 26.9304 70.0819 26.7211C70.0819 26.4072 70.1691 26.1804 70.3435 26.0409C70.5179 25.9013 70.797 25.8316 71.1807 25.8316C71.3377 25.8316 71.4772 25.8316 71.6168 25.849C71.7389 25.849 71.8784 25.8665 72.0179 25.9013V26.2327C71.9133 26.2153 71.7738 26.1978 71.6342 26.1804C71.547 26.1804 71.39 26.1804 71.2505 26.1804Z"
                    fill="#808285"
                />
            </g>
            <defs>
                <clipPath id="clip0_14_3">
                    <rect
                        width="73.9535"
                        height="30"
                        fill="white"
                    />
                </clipPath>
            </defs>
        </svg>
    );
};

export default Logo;
